import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class CallCenterServices extends APIService {
  createCallHistory(callId: string) {
    return this.request('POST', `call-center?callId=${callId}`);
  }
  missedCalls() {
    return this.request('GET', 'call-center/missed-call');
  }
  checkedMissedCalls(ids: number[]) {
    const url = getQueryString('call-center/missed-call/checked', { ids });
    return this.request('POST', url);
  }
  search(params: CallCenterSearchParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('call-center/search', params);
    return this.request('GET', url);
  }
  export(params: CallCenterSearchParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('call-center/export', params);
    return this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }
  countSaleActiveCall() {
    return this.request('POST', `common/count-sale-active-call`);
  }
  countSaleInActiveCall() {
    return this.request('POST', `common/count-sale-inactive-call`);
  }

  getPhoneCallCenter = () => {
    return this.request('GET', 'call-center/phone-number');
  };
}

const callCenterService = new CallCenterServices();
export default callCenterService;

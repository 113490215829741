import { Button, Drawer, Form, Input } from 'antd';
import React, { Ref, forwardRef, useImperativeHandle } from 'react';

export type CreateTeamsModalRef = {
  open: (data?: any) => void;
  close: () => void;
};

type Props = {
  createTeams: (params: { name: string }) => void;
  isEdit?: boolean;
  editTeams: (values: any, uuid: string, agentUuid: string) => void;
};

const ModalCreateTeams = (
  { createTeams, isEdit, editTeams }: Props,
  ref: Ref<CreateTeamsModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<any | undefined>();

  const handleFillData = (data: any | undefined) => {
    if (isEdit) {
      form.setFieldsValue({
        name: data?.name,
      });
    } else {
      form.resetFields();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data) => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
        close: onclose,
      };
    },
    [isEdit]
  );

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  const onclose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = (values: any) => {
    if (isEdit) {
      editTeams(values, dataDetails?.uuid, dataDetails?.agentUuid);
    } else {
      createTeams(values);
    }
  };

  return (
    <Drawer
      open={open}
      title={isEdit ? 'Chỉnh sửa Teams' : 'Thêm mới Teams'}
      destroyOnClose
      onClose={onclose}
      footer={
        <div className="flex justify-end gap-[10px]">
          <Button type="default" onClick={onclose}>
            Huỷ
          </Button>
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            Đồng ý
          </Button>
        </div>
      }
    >
      <Form form={form} id="myForm" onFinish={onSubmit} layout="vertical">
        <Form.Item
          label={'Teams'}
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên Teams' }]}
        >
          <Input placeholder="Nhập tên Teams" />
        </Form.Item>
        <Form.Item label={'Ghi chú'} name={'note'}>
          <Input.TextArea placeholder="Nhập ghi chú" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export const CreateTeamsModal = forwardRef(ModalCreateTeams);

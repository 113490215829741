import { DefaultOptionType } from 'antd/lib/select';

export enum RESPONSE_CODE {
  SUCCESS = 200,
  CREATED_SUCCESS = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,

  CODE_DEFAULT = -200,
  TIME_OUT = 45000,
  SERVER_ERROR = 502,
  CODE_TIME_OUT = 408,
  FORCE_UPDATE_APP = 423,
  CODE_TIMEOUT = 'ECONNABORTED',
}

export enum ERROR_API_CODE {
  NEED_UPDATE_CODE = 401401,
}

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  LOCKED: 'LOCKED',
  BANNED: 'BANNED',
  INACTIVE: 'INACTIVE',
};

export const ROUTERS = {
  Home: {
    key: 'home',
    path: '/',
  },
  Dashboard: {
    key: 'dashboard',
    path: '/dashboard',
  },
  Accounts: {
    key: 'accounts',
    path: '/accounts',
  },
  UserProfile: {
    key: 'user-profile',
    path: '/user-profile',
  },
  ChangePassword: {
    key: 'change-password',
    path: '/change-password',
  },
  Search: {
    key: 'search',
    path: '/search',
  },
  ConfigLevelSale: {
    key: 'config-level-sale',
    path: '/config-level-sale',
  },
  ConfigPermissions: {
    key: 'config-permissions',
    path: '/config-permissions',
  },
  DataManager: {
    key: 'data-manager',
    path: '/data-manager',
  },
  ProductManager: {
    key: 'product-manager',
    path: '/product-manager',
  },
  ListProduct: {
    key: 'list-product',
    path: '/list-product',
  },
  DataCreate: {
    key: 'data-create',
    path: '/data-create',
  },
  DataImport: {
    key: 'data-import',
    path: '/data-import',
  },
  DataDivided: {
    key: 'data-divided',
    path: '/data-divided',
  },
  DataDividedDetail: {
    key: 'data-divided-detail',
    path: '/data-divided-detail/:phone',
  },
  DataLx3: {
    key: 'data-lx3',
    path: '/data-lx3',
  },
  SplitDataHistory: {
    key: 'data-history',
    path: '/data-history',
  },
  TopUp: {
    key: 'top-up',
    path: '/top-up',
  },
  CallCenter: {
    key: 'call-center',
    path: '/call-center',
  },
  RegisterPackage: {
    key: 'register-package',
    path: '/register-package',
  },
  CreateRegisterPackage: {
    key: 'create-register-package',
    path: '/create-register-package',
  },
  RegisterPackageOutside: {
    key: 'register-package-outside',
    path: '/register-package-outside',
  },
  ExtendPackages: {
    key: 'extend-packages',
    path: '/extend-packages',
  },
  RevenueReportsDaily: {
    key: 'revenue-reports-daily',
    path: '/revenue-reports-daily',
  },
  RevenueReportsSaleman: {
    key: 'revenue-reports-saleman',
    path: '/revenue-reports-saleman',
  },
  RevenueReportsPhone: {
    key: 'revenue-reports-phone',
    path: '/revenue-reports-phone',
  },
  RevenueReportsDailyOld: {
    key: 'revenue-reports-daily-old',
    path: '/revenue-reports-daily-old',
  },
  RevenueReportsSalemanOld: {
    key: 'revenue-reports-saleman-old',
    path: '/revenue-reports-saleman-old',
  },
  ReportsRatioTotal: {
    key: 'reports-ratio-total',
    path: '/reports-ratio-total',
  },
  ReportsRatioByDay: {
    key: 'reports-ratio-by-day',
    path: '/reports-ratio-by-day',
  },
  TalkTimeMonitor: {
    key: 'talk-time-monitor',
    path: '/talk-time-monitor',
  },
  TakeCareByDay: {
    key: 'take-care-by-day',
    path: '/take-care-by-day',
  },
  Callout: {
    key: 'call-out',
    path: '/call-out',
  },
  Organizations: {
    key: 'organizations',
    path: '/organizations',
  },
  Collaborators: {
    key: 'collaborators',
    path: '/collaborators',
  },
  Tenant: {
    key: 'tenant',
    path: '/tenant',
  },
};

export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SALE_LEADER: 'ROLE_SALE_LEADER',
  ROLE_SALE_MAN: 'ROLE_SALE_MAN',
  ROLE_SALE_MANAGER: 'ROLE_SALE_MANAGER',
  ROLE_SALE_ADMIN: 'ROLE_SALE_ADMIN',
  ROLE_ACCOUNTANT: 'ROLE_ACCOUNTANT',
  ROLE_BI: 'ROLE_BI',
  ROLE_DA: 'ROLE_DA',
};

// ROLE_ADMIN
// ROLE_SALE_LEADER
// ROLE_SALE_MAN
// ROLE_SALE_MANAGER
// ROLE_SALE_ADMIN
// ROLE_ACCOUNTANT
export const REVENUE_LABEL = [
  {
    id: 1,
    label: 'Số đơn',
  },
  {
    id: 2,
    label: 'Doanh thu MBF',
  },
  {
    id: 3,
    label: 'Hoa hồng thực nhận',
  },
  {
    id: 4,
    label: 'Tỷ lệ chiết khấu',
  },
  {
    id: 5,
    label: 'ARPU',
  },
  {
    id: 6,
    label: 'Tỷ lệ contact đã xử lí',
  },
  {
    id: 7,
    label: 'Tỷ lệ chốt nóng',
  },
];

export const ROUTER_ROLE = [
  {
    scene: ROUTERS.Home.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_ADMIN',
      'ROLE_ACCOUNTANT',
      'ROLE_BI',
    ],
  },
  {
    scene: ROUTERS.Dashboard.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_MANAGER', 'ROLE_SALE_LEADER'],
  },
  {
    scene: ROUTERS.Accounts.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_ADMIN', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.ConfigLevelSale.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_ADMIN', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.DataManager.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_LEADER', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.ProductManager.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.ListProduct.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.DataLx3.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.DataCreate.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_ADMIN',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.DataImport.key,
    roles: ['ROLE_ADMIN', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.SplitDataHistory.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_ADMIN',
    ],
  },
  {
    scene: ROUTERS.Callout.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
    ],
  },
  {
    scene: ROUTERS.Search.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
    ],
  },
  {
    scene: ROUTERS.TopUp.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.CallCenter.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.RegisterPackage.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.CreateRegisterPackage.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.RegisterPackageOutside.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_ADMIN',
    ],
  },
  {
    scene: ROUTERS.ExtendPackages.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.RevenueReportsDaily.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
      'ROLE_BI',
    ],
  },
  {
    scene: ROUTERS.RevenueReportsPhone.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
      'ROLE_BI',
    ],
  },
  {
    scene: ROUTERS.RevenueReportsSaleman.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
    ],
  },
  {
    scene: ROUTERS.RevenueReportsDailyOld.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
      'ROLE_BI',
    ],
  },
  {
    scene: ROUTERS.RevenueReportsSalemanOld.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
    ],
  },
  {
    scene: ROUTERS.TakeCareByDay.key,
    roles: ['ROLE_SALE_LEADER', 'ROLE_ADMIN', 'ROLE_SALE_MANAGER'],
  },
  {
    scene: ROUTERS.ReportsRatioTotal.key,
    roles: ['ROLE_SALE_LEADER', 'ROLE_ADMIN', 'ROLE_SALE_MANAGER', 'ROLE_DA'],
  },
  {
    scene: ROUTERS.ReportsRatioByDay.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_ACCOUNTANT',
      'ROLE_DA',
      'ROLE_BI',
    ],
  },
  {
    scene: ROUTERS.DataDivided.key,
    roles: [
      'ROLE_ADMIN',
      'ROLE_SALE_MANAGER',
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MAN',
    ],
  },
  {
    scene: ROUTERS.TalkTimeMonitor.key,
    roles: ['ROLE_SALE_LEADER', 'ROLE_SALE_MAN'],
  },
];

export const GLOBAL_HEIGHT = 64 + 56 + 48;

export const ROLES_LIST = [
  {
    id: ROLES.ROLE_ADMIN,
    name: 'Admin',
  },
  {
    id: ROLES.ROLE_SALE_MANAGER,
    name: 'Sale Manager',
  },
  {
    id: ROLES.ROLE_SALE_LEADER,
    name: 'Sale Leader',
  },
  {
    id: ROLES.ROLE_SALE_MAN,
    name: 'Sale man',
  },
  {
    id: ROLES.ROLE_SALE_ADMIN,
    name: 'Sale Admin',
  },
  {
    id: ROLES.ROLE_ACCOUNTANT,
    name: 'Accountant',
  },
  {
    id: ROLES.ROLE_BI,
    name: 'Business Intelligence',
  },
  {
    id: ROLES.ROLE_DA,
    name: 'Data Analytics',
  },
];

export const ROLES_LIST_MANAGER = [
  {
    id: 'ROLE_SALE_LEADER',
    name: 'Sale Leader',
  },
  {
    id: 'ROLE_SALE_MAN',
    name: 'Sale man',
  },
];
export const SOURCES_SYS = [
  {
    value: 'PRE',
    label: 'Trả trước',
  },
  {
    value: 'POS',
    label: 'Trả sau',
  },
];

export const REGISTER_PACKAGE_STATUS = [
  {
    value: 'success',
    label: 'Thành công',
  },
  {
    value: 'failed',
    label: 'Thất bại',
  },
];

export const REGISTER_PACKAGE_PAYMENT_CHANNEL = [
  {
    id: '9084 DAG',
    value: '9084 DAG',
  },
  {
    id: '9084 CTV',
    value: '9084 OTP',
  },
  {
    id: '9084 GA12',
    value: '9084 GA12',
  },
  {
    id: '9084 GIAHAN',
    value: '9084 GIAHAN',
  },
  // {
  //   id: '9332 GH',
  //   value: '9332 GH',
  // },
  // {
  //   id: '9332 Upsale',
  //   value: '9332 Upsale',
  // },
  // {
  //   id: 'TKSL',
  //   value: 'TKSL',
  // },
];

export const REGISTER_PACKAGE_SYSTEM = [
  {
    value: 'CRM',
    label: 'CRM',
  },
  {
    value: 'Ngoài CRM',
    label: 'Ngoài CRM',
  },
];
export const TOP_UP_STATUS = [
  {
    value: 'success',
    label: 'Thành công',
  },
  {
    value: 'failed',
    label: 'Thất bại',
  },
  {
    value: 'pending',
    label: 'Đang xử lý',
  },
];

export const DATA_SOURCE = [
  {
    value: 'internal',
    label: 'Nội bộ',
  },
  {
    value: 'external',
    label: 'Ngoài',
  },
  {
    value: 'ref',
    label: 'Khác',
  },
];

export const DATA_SHARE = [
  {
    value: 'new',
    label: 'Chưa chia',
  },
  {
    value: 'success',
    label: 'Đã chia',
  },
  {
    value: 'pending',
    label: 'Chờ xử lý',
  },
];

export const DATA_STATUS_SEARCH = [
  {
    value: '-1',
    label: 'Tất cả',
  },
  {
    value: '1',
    label: 'Đã tra cứu',
  },
  {
    value: '0',
    label: 'Chưa tra cứu',
  },
];

export const SOURCES_SYS_LIST = [
  {
    value: '',
    label: 'Tất cả',
  },
  {
    value: 'MC',
    label: 'MC',
  },
  {
    value: 'MG',
    label: 'MG',
  },
];

export const PAGE_SIZE_LIST = [20, 50, 100, 200];

export const DEFAULT_PAGE_SIZE = 50;

export const REGEX_PASSWORD = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Za-z]).*/g;
export const REGEX_USERNAME = /^([a-zA-Z0-9_]+)$/g;

export const REGEX_PHONE_NUMBER = /^[1-9]([0-9]+){8}$/g;

export const SUGGESTED_PACKAGES = [
  {
    id: 'C120',
    name: 'C120',
  },
  {
    id: 'xGy',
    name: 'xGy',
  },
  {
    id: 'xHDy',
    name: 'xHDy',
  },
  {
    id: 'C90N',
    name: 'C90N',
  },
  {
    id: 'OTHER',
    name: 'Khác',
  },
];

export const STATE_TAKE_CARE_OF = {
  success: 'success',
  processing: 'processing',
  failed: 'failed',
};

export const INTERACTION_HISTORY_TYPE = {
  assignData: 'assign_data',
  outsideRegisterPackage: 'outside_register_package',
  outsideRegisterPackageUpdate: 'outside_register_package_update',
  updateCustomerStage: 'update_customer_stage',
  topUp: 'top_up',
  requestRegisterPackage: 'request_register_package',
  registerPackage: 'register_package',
  searching: 'searching',
  moveData: 'move_data',
  recallData: 'auto_recall_data',
  recallDataManual: 'recall_data',
  callDataHistory: 'call_data_history',
  topup_outside: 'topup_outside',
  customer_transfer: 'customer_transfer',
};

export const getRoleName = (role?: string) => {
  return ROLES_LIST.find((item) => item.id === role)?.name ?? '';
};

export const TYPE_REGISTER_PHONE_PACKAGE = {
  GA: 'GA',
  DAG: 'DAG',
};

export const DATA_REGISTER: ObjectData[] = [
  { id: TYPE_REGISTER_PHONE_PACKAGE.GA, name: '9084 GA' },
  { id: TYPE_REGISTER_PHONE_PACKAGE.DAG, name: '9084 DAG' },
];

export const ACCOUNT_STATUS: DefaultOptionType[] = [
  { value: 1, label: 'Đang hoạt động' },
  { value: 0, label: 'Ngừng hoạt động' },
];

export const DEPARTMENT: DefaultOptionType[] = [
  { value: 'SALE', label: 'SALE' },
  { value: 'ACCOUNTANT', label: 'ACCOUNTANT' },
  { value: 'BI', label: 'BI' },
];

export const DEFAULT_FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const SALE_INDEX = [
  {
    id: 1,
    label: 'Talktime trung bình ngày (phút)',
  },
  {
    id: 2,
    label: 'Số lần gọi lại/contact',
  },
  {
    id: 3,
    label: 'Tỷ lệ kết nối thành công',
  },
  {
    id: 4,
    label: 'Tổng số cuộc gọi đi',
  },
];
export const PACKAGE_STATUS: DefaultOptionType[] = [
  { value: 1, label: 'Đang kinh doanh' },
  { value: 0, label: 'Ngừng hoạt kinh doanh' },
];

export const DEFAULT_PERMISSIONS = {
  // ADD_ON_CALL_HISTORY_ADD: true,
  // ADD_ON_CALL_HISTORY_EXPORT: true,
  // ADD_ON_CALL_HISTORY_FILTER: true,
  // ADD_ON_CALL_HISTORY_LIST: true,
  // ADD_ON_CALL_HISTORY_MISSED_CALL: true,
  // ADD_ON_MENU: true,
  // ADD_ON_REGISTER_HISTORY_EDIT_HISTORY: true,
  // ADD_ON_REGISTER_HISTORY_EXPORT: true,
  // ADD_ON_REGISTER_HISTORY_FILTER: true,
  // ADD_ON_REGISTER_HISTORY_IMPORT: true,
  // ADD_ON_REGISTER_HISTORY_LIST: true,
  // ADD_ON_REQUEST_ADD: true,
  // ADD_ON_REQUEST_LIST: true,
  // ADD_ON_SEARCH_AVAILABLE: true,
  // ADD_ON_SEARCH_INFO: true,
  // ADD_ON_TOP_UP_HISTORY: true,
  // ADD_ON_TOP_UP_HISTORY_EXPORT: true,
  // ADD_ON_TOP_UP_HISTORY_FILTER: true,
  // ADMINISTRATION_ACCOUNT_ADD: true,
  // ADMINISTRATION_ACCOUNT_EDIT: true,
  // ADMINISTRATION_ACCOUNT_FILTER: true,
  // ADMINISTRATION_ACCOUNT_LIST: true,
  // ADMINISTRATION_ACCOUNT_RESET_PASSWORD: true,
  // ADMINISTRATION_LEVEL_SALE_ADD: true,
  // ADMINISTRATION_LEVEL_SALE_EDIT: true,
  // ADMINISTRATION_LEVEL_SALE_LIST: true,
  // ADMINISTRATION_MENU: true,
  // ADMINISTRATION_PERMISSION_CONFIG: true,
  // DASHBOARD_READ: true,
  // DATA_CARING_READ: true,
  // DATA_CATEGORY_1075: true,
  // DATA_CATEGORY_CONFIG_AUTO_SEARCH: true,
  // DATA_CATEGORY_PHONE_PACKAGE: true,
  // DATA_CATEGORY_REGISTER_PHONE_PACK_STAFF: true,
  // DATA_CATEGORY_STAFF: true,
  // DATA_DETAIL_ACCESS: true,
  // DATA_DETAIL_CALL: true,
  // DATA_DETAIL_CUSTOMER_TRANFER: true,
  // DATA_DETAIL_INTERACTION_HISTORY: true,
  // DATA_DETAIL_OUTSIDE: true,
  // DATA_DETAIL_PHONE_INFO: true,
  // DATA_DETAIL_REGISTER: true,
  // DATA_DETAIL_REGISTER_OUTSIDE: true,
  // DATA_DETAIL_SEARCH_BALANCE: true,
  // DATA_DETAIL_TOP_UP: true,
  // DATA_DETAIL_UPDATE_LEVEL_SALE: true,
  // DATA_DIVIDED_EXPORT: true,
  // DATA_DIVIDED_FILTER: true,
  // DATA_DIVIDED_LIST: true,
  // DATA_DIVIDED_MOVE: true,
  // DATA_DIVIDED_RECALL: true,
  // DATA_DIVIDED_SEARCH_BY_MSISDN: true,
  // DATA_EXTEND_1075: false,
  // DATA_IMPORTED_ERROR_DOWNLOAD: true,
  // DATA_IMPORTED_READ: true,
  // DATA_MENU: true,
  // DATA_NEW_ADDED_READ: true,
  // DATA_NEW_ASSIGN: true,
  // DATA_NEW_AUTO_SEARCH: true,
  // DATA_NEW_DIVIDE: true,
  // DATA_NEW_EXPORT: true,
  // DATA_NEW_FILTER: true,
  // DATA_NEW_IMPORT: true,
  // DATA_NEW_LIST: true,
  // DATA_NEW_MANUAL_ADD: true,
  // DATA_NEW_SEARCH_BY_MSISDN: true,
  // DATA_RECALL_ASSIGN: true,
  // DATA_RECALL_AUTO_SEARCH: true,
  // DATA_RECALL_DIVIDE_DATA: true,
  // DATA_RECALL_EXPORT: true,
  // DATA_RECALL_FILTER: true,
  // DATA_RECALL_LIST: true,
  // DATA_RECALL_SEARCH_BY_MSISDN: true,
  // DATA_SAMPLE_DOWNLOAD: true,
  // DATA_SEARCH_BY_MSISDN: true,
  // HOME_READ: true,
  // PRODUCT_MANAGER_ADD: true,
  // PRODUCT_MANAGER_FILTER_BY_STATUS: true,
  // PRODUCT_MANAGER_LIST: true,
  // PRODUCT_MANAGER_MENU: true,
  // PRODUCT_MANAGER_READ: true,
  // PRODUCT_MANAGER_SEARCH_BY_NAME: true,
  // PRODUCT_MANAGER_UPDATE: true,
  // REPORT_MENU: true,
  // REPORT_RATIO_DETAIL_EXPORT: true,
  // REPORT_RATIO_DETAIL_FILTER: true,
  // REPORT_RATIO_PROCESS_EXPORT: true,
  // REPORT_RATIO_PROCESS_FILTER: true,
  // REPORT_RATIO_SUM_EXPORT: true,
  // REPORT_RATIO_SUM_FILTER: true,
  // REPORT_RATIO_SUM_LIST: true,
  // REPORT_REVENUE_BY_DAY_EXPORT: true,
  // REPORT_REVENUE_BY_DAY_FILTER: true,
  // REPORT_REVENUE_BY_DAY_LIST: true,
  // REPORT_REVENUE_BY_PHONE_EXPORT: true,
  // REPORT_REVENUE_BY_PHONE_FILTER: true,
  // REPORT_REVENUE_BY_PHONE_LIST: true,
  // REPORT_REVENUE_BY_SALE_EXPORT: true,
  // REPORT_REVENUE_BY_SALE_FILTER: true,
  // REPORT_REVENUE_BY_SALE_LIST: true,

  DATA_CARING_READ: true,
  DASHBOARD_READ: true,
  DATA_IMPORT: true,
  DATA_ADD_NEW: true,
  DATA_NEW_AUTO_SEARCH: true,
  DATA_NEW_EXPORT: true,
  DATA_NEW_DIVIDE: true,
  DATA_NEW_ASSIGN: true,
  DATA_NEW: true,
  DATA_DIVIDED_RECALL: true,
  DATA_DIVIDED: true,
  DATA_DIVIDED_EXPORT: true,
  DATA_DIVIDED_MOVE: true,
  DATA_DETAIL: true,
  DATA_DETAIL_CUSTOMER_TRANSFER: true,
  DATA_DETAIL_OUTSIDE: true,
  DATA_DETAIL_REGISTER_OUTSIDE: true,
  DATA_RECALL: true,
  DATA_RECALL_DIVIDE_DATA: true,
  DATA_RECALL_AUTO_SEARCH: true,
  DATA_RECALL_EXPORT: true,
  DATA_RECALL_ASSIGN: true,
  PRODUCT_MANAGER: true,
  PRODUCT_MANAGER_ADD: true,
  PRODUCT_MANAGER_UPDATE: true,
  ADD_ON_SEARCH: true,
  ADD_ON_REQUEST: true,
  ADD_ON_CALL_HISTORY: true,
  ADD_ON_CALL_HISTORY_EXPORT: true,
  ADD_ON_TOP_UP_HISTORY: true,
  ADD_ON_TOP_UP_HISTORY_EXPORT: true,
  ADD_ON_REGISTER_HISTORY: true,
  ADD_ON_REGISTER_HISTORY_IMPORT: true,
  ADD_ON_REGISTER_HISTORY_EXPORT: true,
  ADD_ON_REGISTER_HISTORY_EDIT_HISTORY: true,
  REPORT_REVENUE_BY_DAY: true,
  REPORT_REVENUE_BY_SALE: true,
  REPORT_REVENUE_BY_PHONE: true,
  REPORT_RATIO_SUM: true,
  REPORT_RATIO_PROCESS: true,
  ADMINISTRATION_ACCOUNT: true,
  ADMINISTRATION_ACCOUNT_ADD: true,
  ADMINISTRATION_ACCOUNT_RESET_PASSWORD: true,
  ADMINISTRATION_ACCOUNT_EDIT: true,
  ADMINISTRATION_LEVEL_SALE: true,
  HOME_READ: true,
  DATA_MENU: true,
  PRODUCT_MANAGER_MENU: true,
  ADD_ON_MENU: true,
  REPORT_MENU: true,
  ADMINISTRATION_MENU: true,
  ADMINISTRATION_PERMISSION: true,
  DATA_MANAGER_VIEW_ALL: true,
  DATA_MANAGER_VIEW_ALL_TEAM: true,
  DATA_MANAGER_VIEW_TEAM: true,
  DATA_MANAGER_VIEW_OWNER: true,
  ADMINISTRATION_MANAGER_TEAM: true,
};

export const PERMISSIONS = {
  DATA_CARING_READ: 'DATA_CARING_READ',
  DASHBOARD_READ: 'DASHBOARD_READ',
  DATA_IMPORT: 'DATA_IMPORT',
  DATA_ADD_NEW: 'DATA_ADD_NEW',
  DATA_NEW_AUTO_SEARCH: 'DATA_NEW_AUTO_SEARCH',
  DATA_NEW_EXPORT: 'DATA_NEW_EXPORT',
  DATA_NEW_DIVIDE: 'DATA_NEW_DIVIDE',
  DATA_NEW_ASSIGN: 'DATA_NEW_ASSIGN',
  DATA_NEW: 'DATA_NEW',
  DATA_DIVIDED_RECALL: 'DATA_DIVIDED_RECALL',
  DATA_DIVIDED: 'DATA_DIVIDED',
  DATA_DIVIDED_EXPORT: 'DATA_DIVIDED_EXPORT',
  DATA_DIVIDED_MOVE: 'DATA_DIVIDED_MOVE',
  DATA_DETAIL: 'DATA_DETAIL',
  DATA_DETAIL_CUSTOMER_TRANSFER: 'DATA_DETAIL_CUSTOMER_TRANSFER',
  DATA_DETAIL_OUTSIDE: 'DATA_DETAIL_OUTSIDE',
  DATA_DETAIL_REGISTER_OUTSIDE: 'DATA_DETAIL_REGISTER_OUTSIDE',
  DATA_RECALL: 'DATA_RECALL',
  DATA_RECALL_DIVIDE_DATA: 'DATA_RECALL_DIVIDE_DATA',
  DATA_RECALL_AUTO_SEARCH: 'DATA_RECALL_AUTO_SEARCH',
  DATA_RECALL_EXPORT: 'DATA_RECALL_EXPORT',
  DATA_RECALL_ASSIGN: 'DATA_RECALL_ASSIGN',
  PRODUCT_MANAGER: 'PRODUCT_MANAGER',
  PRODUCT_MANAGER_ADD: 'PRODUCT_MANAGER_ADD',
  PRODUCT_MANAGER_UPDATE: 'PRODUCT_MANAGER_UPDATE',
  ADD_ON_SEARCH: 'ADD_ON_SEARCH',
  ADD_ON_REQUEST: 'ADD_ON_REQUEST',
  ADD_ON_CALL_HISTORY: 'ADD_ON_CALL_HISTORY',
  ADD_ON_CALL_HISTORY_EXPORT: 'ADD_ON_CALL_HISTORY_EXPORT',
  ADD_ON_TOP_UP_HISTORY: 'ADD_ON_TOP_UP_HISTORY',
  ADD_ON_TOP_UP_HISTORY_EXPORT: 'ADD_ON_TOP_UP_HISTORY_EXPORT',
  ADD_ON_REGISTER_HISTORY: 'ADD_ON_REGISTER_HISTORY',
  ADD_ON_REGISTER_HISTORY_IMPORT: 'ADD_ON_REGISTER_HISTORY_IMPORT',
  ADD_ON_REGISTER_HISTORY_EXPORT: 'ADD_ON_REGISTER_HISTORY_EXPORT',
  ADD_ON_REGISTER_HISTORY_EDIT_HISTORY: 'ADD_ON_REGISTER_HISTORY_EDIT_HISTORY',
  REPORT_REVENUE_BY_DAY: 'REPORT_REVENUE_BY_DAY',
  REPORT_REVENUE_BY_SALE: 'REPORT_REVENUE_BY_SALE',
  REPORT_REVENUE_BY_PHONE: 'REPORT_REVENUE_BY_PHONE',
  REPORT_RATIO_SUM: 'REPORT_RATIO_SUM',
  REPORT_RATIO_PROCESS: 'REPORT_RATIO_PROCESS',
  ADMINISTRATION_ACCOUNT: 'ADMINISTRATION_ACCOUNT',
  ADMINISTRATION_ACCOUNT_ADD: 'ADMINISTRATION_ACCOUNT_ADD',
  ADMINISTRATION_ACCOUNT_RESET_PASSWORD:
    'ADMINISTRATION_ACCOUNT_RESET_PASSWORD',
  ADMINISTRATION_ACCOUNT_EDIT: 'ADMINISTRATION_ACCOUNT_EDIT',
  ADMINISTRATION_LEVEL_SALE: 'ADMINISTRATION_LEVEL_SALE',
  HOME_READ: 'HOME_READ',
  DATA_MENU: 'DATA_MENU',
  PRODUCT_MANAGER_MENU: 'PRODUCT_MANAGER_MENU',
  ADD_ON_MENU: 'ADD_ON_MENU',
  ADMINISTRATION_MENU: 'ADMINISTRATION_MENU',
  ADMINISTRATION_PERMISSION: 'ADMINISTRATION_PERMISSION',
  DATA_MANAGER_VIEW_ALL: 'DATA_MANAGER_VIEW_ALL',
  DATA_MANAGER_VIEW_ALL_TEAM: 'DATA_MANAGER_VIEW_ALL_TEAM',
  DATA_MANAGER_VIEW_TEAM: 'DATA_MANAGER_VIEW_TEAM',
  DATA_MANAGER_VIEW_OWNER: 'DATA_MANAGER_VIEW_OWNER',
  ADMINISTRATION_MANAGER_TEAM: 'ADMINISTRATION_MANAGER_TEAM',
  REPORT_MENU: 'REPORT_MENU',
  DATA_NEW_DEVIDE_SALE_LEADER: 'DATA_NEW_DEVIDE_SALE_LEADER',
  // HOME_READ: 'HOME_READ',

  // // Manager data
  // DATA_MENU: 'DATA_MENU', // Hiển thị Menu Quản lý data
  // DATA_NEW_LIST: 'DATA_NEW_LIST', // Quyền xem Data chưa chia
  // DATA_DIVIDED_LIST: 'DATA_DIVIDED_LIST', // Quyền xem Data đã chia
  // DATA_DETAIL_ACCESS: 'DATA_DETAIL_ACCESS', // Quyền xem Data detail
  // DATA_RECALL_LIST: 'DATA_RECALL_LIST', // Quyền xem Data thu hồi
  // DATA_NEW_FILTER: 'DATA_NEW_FILTER', // Quyền lọc Data chưa chia
  // DATA_SEARCH_BY_MSISDN: 'DATA_SEARCH_BY_MSISDN', // Quyền tìm kiếm Data chưa chia theo số điện thoại
  // DATA_DIVIDED_SEARCH_BY_MSISDN: 'DATA_DIVIDED_SEARCH_BY_MSISDN', // Quyền tìm kiếm Data đã chia theo số điẹn thoại
  // DATA_DIVIDED_FILTER: 'DATA_DIVIDED_FILTER', // Quyền lọc Data đã chia
  // DATA_DIVIDED_EXPORT: 'DATA_DIVIDED_EXPORT', // Quyền Export Data đã chia
  // DATA_DIVIDED_RECALL: 'DATA_DIVIDED_RECALL', // Quyền thu hồi Data
  // DATA_DIVIDED_MOVE: 'DATA_DIVIDED_MOVE', // Quyền chuyển Data
  // DATA_DETAIL_CALL: 'DATA_DETAIL_CALL', // Quyền gọi điện Data đã chia chi tiết Data đã chia
  // DATA_DETAIL_SEARCH_BALANCE: 'DATA_DETAIL_SEARCH_BALANCE', // Quyền tra cứu TKC Data đã chia chi tiết Data đã chia
  // DATA_DETAIL_REGISTER: 'DATA_DETAIL_REGISTER', // Quyền đăng ký Data đã chia chi tiết Data đã chia
  // DATA_DETAIL_CUSTOMER_TRANFER: 'DATA_DETAIL_CUSTOMER_TRANFER', // Quyền truy cập khách hàng chuyển khoản chi tiết Data đã chia
  // DATA_DETAIL_OUTSIDE: 'DATA_DETAIL_OUTSIDE', // Quyền truy cập ghi nhận nạp ngoài CRM chi tiết Data đã chia
  // DATA_DETAIL_TOP_UP: 'DATA_DETAIL_TOP_UP', // Quyền nạp thẻ chi tiết Data đã chia
  // DATA_DETAIL_REGISTER_OUTSIDE: 'DATA_DETAIL_REGISTER_OUTSIDE', // Quyền đăng ký ngoài CRM chi tiết Data đã chia
  // DATA_DETAIL_UPDATE_LEVEL_SALE: 'DATA_DETAIL_UPDATE_LEVEL_SALE', // Quyền hiển thị cập nhật Level sale chi tiết Data đã chia
  // DATA_DETAIL_PHONE_INFO: 'DATA_DETAIL_PHONE_INFO', // Quyền hiển thị thông tin thuê bao chi tiết Data đã chia
  // DATA_DETAIL_INTERACTION_HISTORY: 'DATA_DETAIL_INTERACTION_HISTORY', // Quyền hiển thị lịch sử tương tác chi tiết Data đã chia
  // DATA_RECALL_SEARCH_BY_MSISDN: 'DATA_RECALL_SEARCH_BY_MSISDN', // Quyền tìm kiếm Data thu hồi theo số điện thoại
  // DATA_RECALL_AUTO_SEARCH: 'DATA_RECALL_AUTO_SEARCH', // Quyền tra cứu tự động Data thu hồi
  // DATA_RECALL_DEVIDE_DATA: 'DATA_RECALL_DEVIDE_DATA', // Quyền chia Data thu hồi
  // DATA_RECALL_EXPORT: 'DATA_RECALL_EXPORT', // Quyền export data thu hồi
  // DATA_RECALL_ASSIGN: 'DATA_RECALL_ASSIGN', // Quyền gán data thu hồi

  // // Utilities
  // ADD_ON_MENU: 'ADD_ON_MENU', // Hiển thị menu Tiện ích
  // ADD_ON_SEARCH_INFO: 'ADD_ON_SEARCH_INFO', // Quyền hiển thị danh sách gói cước đang sử dụng
  // ADD_ON_SEARCH_AVAILABLE: 'ADD_ON_SEARCH_AVAILABLE', // Quyền hiển thị danh sách gói cước có thể đăng ký
  // ADD_ON_REQUEST_ADD: 'ADD_ON_REQUEST_ADD', // Quyền tạo yêu cầu đăng ký

  // // Manager Product
  // PRODUCT_MANAGER_MENU: 'PRODUCT_MANAGER_MENU', // Quyền hiển thị menu Quản lý sản phẩm
  // PRODUCT_MANAGER_LIST: 'PRODUCT_MANAGER_LIST', // Quyền xem danh sách sản phẩm
  // ADD_ON_CALL_HISTORY_FILTER: 'ADD_ON_CALL_HISTORY_FILTER', // Quyền lọc lịch sử cuộc gọi
  // ADD_ON_CALL_HISTORY_EXPORT: 'ADD_ON_CALL_HISTORY_EXPORT', // Quyền export lịch sử cuộc gọi
  // ADD_ON_TOP_UP_HISTORY_FILTER: 'ADD_ON_TOP_UP_HISTORY_FILTER', // Quyền lọc lịch sử nạp thẻ
  // ADD_ON_TOP_UP_HISTORY_EXPORT: 'ADD_ON_TOP_UP_HISTORY_EXPORT', // Quyền export lịch sử nạp thẻ
  // ADD_ON_REGISTER_HISTORY_FILTER: 'ADD_ON_REGISTER_HISTORY_FILTER', // Quyền lọc lịch sử đăng ký
  // ADD_ON_REGISTER_HISTORY_IMPORT: 'ADD_ON_REGISTER_HISTORY_IMPORT', // Quyền import lịch sử đăng ký
  // ADD_ON_REGISTER_HISTORY_EXPORT: 'ADD_ON_REGISTER_HISTORY_EXPORT', // Quyền export lịch sử đăng ký
  // ADD_ON_REGISTER_HISTORY_EDIT_HISTORY: 'ADD_ON_REGISTER_HISTORY_EDIT_HISTORY', // Quyền chỉnh sửa lịch sử đăng ký

  // // Report
  // REPORT_MENU: 'REPORT_MENU', // Hiển thị menu Báo cáo
  // REPORT_REVENUE_BY_DAY_FILTER: 'REPORT_REVENUE_BY_DAY_FILTER', // Quyền lọc hoa hồng theo ngày
  // REPORT_REVENUE_BY_DAY_EXPORT: 'REPORT_REVENUE_BY_DAY_EXPORT', // Quyền export hoa hồng theo ngày
  // REPORT_REVENUE_BY_SALE_FILTER: 'REPORT_REVENUE_BY_SALE_FILTER', // Quyền lọc hoa hồng theo sale
  // REPORT_REVENUE_BY_SALE_EXPORT: 'REPORT_REVENUE_BY_SALE_EXPORT', // Quyền export hoa hồng theo sale
  // REPORT_REVENUE_BY_PHONE_FILTER: 'REPORT_REVENUE_BY_PHONE_FILTER', // Quyền lọc hoa hồng theo số điện thoại
  // REPORT_REVENUE_BY_PHONE_EXPORT: 'REPORT_REVENUE_BY_PHONE_EXPORT', // Quyền export hoa hồng theo số điện thoại
  // REPORT_RATIO_SUM_FILTER: 'REPORT_RATIO_SUM_FILTER', // Quyền bộ lọc tỉ lệ tổng
  // REPORT_RATIO_SUM_EXPORT: 'REPORT_RATIO_SUM_EXPORT', // Quyền export tỉ lệ tổng
  // REPORT_RATIO_PROCESS_FILTER: 'REPORT_RATIO_PROCESS_FILTER', // Quyền bộ lọc tỉ lệ luồng
  // REPORT_RATIO_PROCESS_EXPORT: 'REPORT_RATIO_PROCESS_EXPORT', // Quyền export tỉ lệ luồng

  // DATA_DEVIDED_FILTER: 'DATA_DEVIDED_FILTER',
  // PRODUCT_MANAGER_FILTER_BY_STATUS: 'PRODUCT_MANAGER_FILTER_BY_STATUS',
  // DATA_NEW_EXPORT: 'DATA_NEW_EXPORT',
  // DATA_NEW_DEVIDE: 'DATA_NEW_DEVIDE',
  // ADD_ON_REQUEST_LIST: 'ADD_ON_REQUEST_LIST',
  // DATA_IMPORTED_ERROR_DOWNLOAD: 'DATA_IMPORTED_ERROR_DOWNLOAD',
  // DATA_CARING_READ: 'DATA_CARING_READ',
  // DATA_DEVIDED_EXPORT: 'DATA_DEVIDED_EXPORT',
  // PRODUCT_MANAGER_SEARCH_BY_NAME: 'PRODUCT_MANAGER_SEARCH_BY_NAME',
  // ADD_ON_CALL_HISTORY_LIST: 'ADD_ON_CALL_HISTORY_LIST',
  // DATA_NEW_IMPORT: 'DATA_NEW_IMPORT',
  // DATA_NEW_MANUAL_ADD: 'DATA_NEW_MANUAL_ADD',
  // DATA_NEW_ASSIGN: 'DATA_NEW_ASSIGN',
  // DATA_NEW_AUTO_SEARCH: 'DATA_NEW_AUTO_SEARCH',
  // DATA_DEVIDED_RECALL: 'DATA_DEVIDED_RECALL',
  // DATA_RECALL_FILTER: 'DATA_RECALL_FILTER',
  // ADD_ON_TOP_UP_HISTORY: 'ADD_ON_TOP_UP_HISTORY',
  // ADD_ON_REGISTER_HISTORY_LIST: 'ADD_ON_REGISTER_HISTORY_LIST',
  // PRODUCT_MANAGER_UPDATE: 'PRODUCT_MANAGER_UPDATE',
  // PRODUCT_MANAGER_READ: 'PRODUCT_MANAGER_READ',
  // ADMINISTRATION_PERMISSION_CONFIG: 'ADMINISTRATION_PERMISSION_CONFIG',

  // // Import Data
  // DATA_IMPORTED_READ: 'DATA_IMPORTED_READ',
  // DATA_NEW_ADDED_READ: 'DATA_NEW_ADDED_READ',

  // PRODUCT_MANAGER_ADD: 'PRODUCT_MANAGER_ADD',
  // DATA_NEW_SEARCH_BY_MSISDN: 'DATA_NEW_SEARCH_BY_MSISDN',
  // DATA_DEVIDED_SEARCH_BY_MSISDN: 'DATA_DEVIDED_SEARCH_BY_MSISDN',
  // DATA_DEVIDED_MOVE: 'DATA_DEVIDED_MOVE',
  // DASHBOARD_READ: 'DASHBOARD_READ',
  // DATA_SAMPLE_DOWNLOAD: 'DATA_SAMPLE_DOWNLOAD',

  // // Administration
  // ADMINISTRATION_MENU: 'ADMINISTRATION_MENU', // Hiển thị menu Administration

  // // Manager account
  // ADMINISTRATION_ACCOUNT_FILTER: 'ADMINISTRATION_ACCOUNT_FILTER', // Hiển thị bộ lọc
  // ADMINISTRATION_ACCOUNT_ADD: 'ADMINISTRATION_ACCOUNT_ADD', // Hiển thị nút thêm nhân viên
  // ADMINISTRATION_ACCOUNT_LIST: 'ADMINISTRATION_ACCOUNT_LIST', //Hiển thị danh sách nhân viên
  // ADMINISTRATION_ACCOUNT_RESET_PASSWORD:
  //   'ADMINISTRATION_ACCOUNT_RESET_PASSWORD', // Hiển thị nút reset password
  // ADMINISTRATION_ACCOUNT_EDIT: 'ADMINISTRATION_ACCOUNT_EDIT', // Hiển thị nút edit account

  // // Config level sale
  // ADMINISTRATION_LEVEL_SALE_LIST: 'ADMINISTRATION_LEVEL_SALE_LIST', // Quyền hiển thị Cấu hình level sale
  // ADMINISTRATION_LEVEL_SALE_ADD: 'ADMINISTRATION_LEVEL_SALE_ADD', // Hiển thị nút thêm cấu hình
  // ADMINISTRATION_LEVEL_SALE_EDIT: 'ADMINISTRATION_LEVEL_SALE_EDIT', // Hiển thị thao tác edit cấu hình level sale

  // // Report total
  // REPORT_RATIO_SUM_LIST: 'REPORT_RATIO_SUM_LIST',

  // // Revenue by sale
  // REPORT_REVENUE_BY_SALE_LIST: 'REPORT_REVENUE_BY_SALE_LIST',

  // // Revenue by phone
  // REPORT_REVENUE_BY_PHONE_LIST: 'REPORT_REVENUE_BY_PHONE_LIST',

  // // Revenue by day
  // REPORT_REVENUE_BY_DAY_LIST: 'REPORT_REVENUE_BY_DAY_LIST',
};

export const AMOUNT_VALUES = [
  { label: '10,000 VNĐ', value: 10000 },
  { label: '20,000 VNĐ', value: 20000 },
  { label: '30,000 VNĐ', value: 30000 },
  { label: '50,000 VNĐ', value: 50000 },
  { label: '100,000 VNĐ', value: 100000 },
  { label: '200,000 VNĐ', value: 200000 },
  { label: '300,000 VNĐ', value: 300000 },
  { label: '500,000 VNĐ', value: 500000 },
];

import React, { FC, useEffect, useState } from 'react';
import {
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
  RESPONSE_CODE,
  ROLES,
  ROUTERS,
} from 'src/utils/constants';
import { Spin, Table, notification } from 'antd';
import reportsServices from 'src/services/reports.service';
import {
  convertListSaleMan,
  isHavePermission,
  isNullOrEmpty,
  modifyArrTreeSelect,
} from 'src/utils/stringUtils';
import callCenterService from 'src/services/callCenter.service';
import { useWindowSize } from 'src/utils/customHooks';

import CallCenterFilters from './CallCenterFilters';
import saveAs from 'file-saver';
import AudioPlayer from 'src/components/AudioPlayer';

import { ReactComponent as IconCallIn } from 'src/assets/callCenter/callIn.svg';
import { ReactComponent as IconCallOut } from 'src/assets/callCenter/callOut.svg';
import { ReactComponent as IconMissedCall } from 'src/assets/callCenter/missedCall.svg';

import { generatePath } from 'react-router-dom';
import { Moment } from 'moment';
import moment from 'moment';
import './style.scss';
import { Helmet } from 'react-helmet';
import { projectId, projectName } from 'src/utils';

type State = {
  loading: boolean;
  isLoadingExport: boolean;
  isLoadingReset: boolean;
  data: CallCenterData[];
  filters: CallCenterSearchParams;
  metadata?: MetadataInterface;
  staffs: any;
};

const CallCenter: FC = () => {
  const defaultFilters = {
    msisdn: undefined,
    callingType: undefined,
    staffs: [],
    callingStatus: undefined,
    callingTimeStart: Number(moment().startOf('month').format('X')) * 1000,
    callingTimeEnd:
      Number(moment().subtract(0, 'days').endOf('day').format('X')) * 1000,
    endingStatus: undefined,
    callingNumber: undefined,
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  };

  const [UIState, setUIState] = useState<State>({
    loading: true,
    isLoadingExport: false,
    isLoadingReset: false,
    data: [],
    staffs: [],
    filters: defaultFilters,
    metadata: undefined,
  });
  const [currentPlayingRecord, setCurrentPlayingRecord] = useState(null);
  const [isExtended, setIsExtended] = useState(false);
  const isSaleMan = isHavePermission(ROLES.ROLE_SALE_MAN);
  const windowSize = useWindowSize();

  const handleExtendFilter = () => {
    setIsExtended(!isExtended);
  };

  const renderCallingType = (type: string) => {
    switch (type) {
      case 'Cuộc gọi nhỡ': {
        return (
          <div style={{ display: 'flex' }}>
            <IconMissedCall style={{ marginRight: 4 }} />
            Cuộc gọi nhỡ
          </div>
        );
      }
      case 'Cuộc gọi đi': {
        return (
          <div style={{ display: 'flex' }}>
            <IconCallOut style={{ marginRight: 4 }} />
            Cuộc gọi đi
          </div>
        );
      }
      case 'Cuộc gọi đến': {
        return (
          <div style={{ display: 'flex' }}>
            <IconCallIn style={{ marginRight: 4 }} />
            Cuộc gọi đến
          </div>
        );
      }
    }
  };

  const handlePlayClick = (recordId: any) => {
    setCurrentPlayingRecord(recordId);
  };

  const handlePauseClick = () => {
    setCurrentPlayingRecord(null);
  };

  const renderContentTable = (record: CallCenterData, cell: string) => (
    <div
      onClick={() => {
        return onRowClick(record);
      }}
      style={{ cursor: 'pointer' }}
    >
      {cell}
    </div>
  );

  const columns = [
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
      width: '6%',
      fixed: 'left',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Loại cuộc gọi',
      dataIndex: 'callingType',
      key: 'callingType',
      width: '8%',
      render: (cell: string, record: CallCenterData) => (
        <div
          onClick={() => {
            return onRowClick(record);
          }}
          style={{ cursor: 'pointer' }}
        >
          {renderCallingType(cell)}
        </div>
      ),
    },
    {
      title: 'Trạng thái thoại',
      dataIndex: 'callingStatus',
      key: 'callingStatus',
      width: '9%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Thời điểm bắt đầu',
      dataIndex: 'startTime',
      key: 'startTime',
      width: '11%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '---',
    },
    {
      title: 'Thời điểm kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      width: '11%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY HH:mm:ss') : '---',
    },
    {
      title: 'Thời gian chờ',
      dataIndex: 'waitingDuration',
      key: 'waitingDuration',
      width: '7%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Thời gian giữ',
      dataIndex: 'holdingDuration',
      key: 'holdingDuration',
      width: '7%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Thời gian đàm thoại',
      dataIndex: 'callingDuration',
      key: 'callingDuration',
      width: '10%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Saleman',
      dataIndex: 'saleMan',
      key: 'saleMan',
      width: '8%',
      hidden: isSaleMan,
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Trạng thái kết thúc',
      dataIndex: 'endingStatus',
      key: 'endingStatus',
      width: '9%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'Đầu số gọi',
      dataIndex: 'callingNumber',
      key: 'callingNumber',
      width: '6%',
      render: (cell: string, record: CallCenterData) =>
        renderContentTable(record, cell),
    },
    {
      title: 'File ghi âm',
      dataIndex: 'recordUrl',
      key: 'recordUrl',
      width: '17%',
      render: (cell: string, record: CallCenterData) => {
        if (!isNullOrEmpty(record.recordPath)) {
          return (
            <AudioPlayer
              url={record.recordPath}
              urlDownload={record.recordPathDownload}
              duration={record.callingDuration}
              key={record.id + 'audio-player'}
              isPlaying={record.id === currentPlayingRecord}
              onPlayClick={handlePlayClick}
              record={record}
              handlePauseClick={handlePauseClick}
            />
          );
        }
        return null;
      },
    },
  ].filter((item) => !item.hidden);

  const fetchData = async () => {
    const response = await callCenterService.search(UIState.filters);
    if (response?.status === 200) {
      const { data, headers } = response;
      setUIState((cur) => ({
        ...cur,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
        data,
        loading: false,
        isLoadingReset: false,
      }));
    } else {
      setUIState((cur) => ({
        ...cur,
        loading: false,
        isLoadingReset: false,
      }));
      // notification.error({
      //   message: response?.data,
      // });
    }
  };

  const fetchDataFilters = async () => {
    const response = await reportsServices.getListStaff();
    if (response?.status === RESPONSE_CODE?.SUCCESS) {
      setUIState((cur) => ({
        ...cur,
        staffs: modifyArrTreeSelect(response?.data),
      }));
    }
    // else {
    //   notification.error({
    //     message: response?.data,
    //   });
    // }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
      loading: true,
    }));
  };

  const onSearch = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        ...cur.filters,
      },
    }));
  };

  const onExport = async () => {
    const resp = await callCenterService.export(UIState.filters);
    const { data } = resp;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Lich_su_cuoc_goi_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      notification.error({
        message: resp?.data?.message,
      });
    }
  };

  const onChangePhone = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        msisdn: value ?? undefined,
      },
    }));
  };

  const onChangeCallType = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        callingType: value ?? undefined,
      },
    }));
  };

  const onChangeCallStatus = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        callingStatus: value ?? undefined,
      },
    }));
  };

  const onChangeDateCall = (values: string[]) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        callingTimeStart: values?.[0]
          ? Number(moment(values[0]).startOf('day').format('X')) * 1000
          : undefined,
        callingTimeEnd: values?.[1]
          ? Number(moment(values[1]).endOf('day').format('X')) * 1000
          : undefined,
      },
    }));
  };

  const onChangeCallEndType = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        endingStatus: value ?? undefined,
      },
    }));
  };

  const onChangeStaff = (value: string[]) => {
    setUIState((cur) => ({
      ...cur,
      staffSelected: value,
      filters: {
        ...cur.filters,
        staffs: convertListSaleMan(value, UIState.staffs),
      },
    }));
  };

  const onPressEnter = (e: any) => {
    if (e.keyCode === 13) {
      fetchData();
    }
  };

  const onRowClick = (record: any) => {
    const path = generatePath(ROUTERS.DataDividedDetail.path, {
      phone: record.msisdn,
    });
    window.open(path);
  };

  const onReset = () => {
    setUIState((cur) => ({
      ...cur,
      filters: defaultFilters,
      loading: true,
      isLoadingReset: true,
    }));
  };

  useEffect(() => {
    if (UIState.loading) {
      fetchData();
    }
  }, [UIState.loading]);

  useEffect(() => {
    fetchDataFilters();
  }, []);

  let tableHeight = windowSize?.height - (isExtended ? 300 : 150);
  let tableWidth = windowSize?.width * 1.1;

  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  return (
    <div id="cell-center">
      <Helmet>
        <title>Lịch sử cuộc gọi</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <Spin spinning={UIState.loading} tip={'Loading...'}>
        <CallCenterFilters
          size={size}
          staffs={UIState.staffs}
          isLoadingExport={UIState.isLoadingExport}
          defaultData={UIState?.filters}
          onSearch={onSearch}
          onExport={onExport}
          onKeyDown={onPressEnter}
          onChangePhone={onChangePhone}
          onChangeCallType={onChangeCallType}
          onChangeCallStatus={onChangeCallStatus}
          onChangeCallEndType={onChangeCallEndType}
          onChangeDateCall={onChangeDateCall}
          onChangeStaff={onChangeStaff}
          onReset={onReset}
          isExtended={isExtended}
          handleExtendFilter={handleExtendFilter}
          loadingReset={UIState.isLoadingReset}
        />
        <Table
          key={'id'}
          columns={columns as any}
          dataSource={UIState.data}
          pagination={{
            size: 'small',
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_LIST,
            defaultPageSize: UIState.filters.size,
            style: { marginRight: 12 },
            onChange: onPageChange,
            total: UIState.metadata?.total,
            showTotal: (total) => (
              <div>Tổng số bản ghi {total.toLocaleString()}</div>
            ),
          }}
          scroll={{ y: tableHeight, x: tableWidth }}
        />
      </Spin>
    </div>
  );
};

export default CallCenter;

import { Modal, message } from 'antd';
import React from 'react';
import { CreateMembersModalRef } from 'src/containers/Organizations/ModalCreateMembers';
import { CreateTeamsModalRef } from 'src/containers/Organizations/ModalCreateTeam';
import organizationsServices from 'src/services/organizations.service';
import { RESPONSE_CODE } from 'src/utils/constants';

type StateProps = {
  lisOrganizations: OrganizationsInterface[];
  lisMember: any;
  topupCreateOrganization: boolean;
  loading: boolean;
  reload: boolean;
  isEdit: boolean;
};

export const useOrganizations = () => {
  const [state, setState] = React.useState<StateProps>({
    lisOrganizations: [],
    lisMember: [],
    topupCreateOrganization: false,
    loading: true,
    reload: false,
    isEdit: false,
  });
  const createTeamsModal = React.useRef<CreateTeamsModalRef>(null);
  const createMembersModal = React.useRef<CreateMembersModalRef>(null);
  const [modal, contextHolder] = Modal.useModal();

  React.useEffect(() => {
    getOrganizations();
    getMemberAvailable();
  }, [state?.reload]);

  const getOrganizations = async () => {
    const res = await organizationsServices.getOrganizationsData();
    const { data } = res;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        lisOrganizations: data,
      }));
    }
  };

  const getMemberAvailable = async () => {
    const res = await organizationsServices.getMemberAvailable();
    const { data } = res;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        lisMember: data,
      }));
    }
  };

  const openTopup = () => {
    setState((prev) => ({
      ...prev,
      topupCreateOrganization: true,
    }));
  };

  const createTeams = async (params: { name: string }) => {
    const res = await organizationsServices.createTeams(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        reload: !state?.reload,
      }));
      createTeamsModal?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const editTeams = async (values: any, uuid: string, agentUuid: string) => {
    const params = {
      agentUuid: agentUuid,
      name: values.name,
    };
    const res = await organizationsServices.editTeam(params, uuid);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        reload: !state?.reload,
      }));
      createTeamsModal?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const handleOpenModalTeam = () => {
    createTeamsModal?.current?.open();
    setState((prev) => ({
      ...prev,
      topupCreateOrganization: false,
      isEdit: false,
    }));
  };

  const handleOpenModalMembers = () => {
    createMembersModal?.current?.open();
    setState((prev) => ({
      ...prev,
      topupCreateOrganization: false,
      isEdit: false,
    }));
  };

  const editMembers = () => {};

  const createMembers = async (value: any) => {
    const res = await organizationsServices.addMember(
      value?.teamUuid,
      value?.userId
    );
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        reload: !state?.reload,
      }));
      createMembersModal?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const detailTeams = (data: any) => {
    createTeamsModal?.current?.open(data);
    setState((prev) => ({
      ...prev,
      isEdit: true,
    }));
  };

  const detailMembers = (data: any) => {
    createMembersModal?.current?.open(data);
    setState((prev) => ({
      ...prev,
      isEdit: true,
    }));
  };

  const deleteMember = (uuid?: string, userId?: number, username?: string) => {
    console.log(userId, uuid);
    modal.confirm({
      title: 'Xoá member',
      content: `Bạn có muốn xoá ${username} khỏi team ?`,
      okText: 'Xác nhận',
      width: 350,
      onOk: async () => {
        const res = await organizationsServices.deleteMember(userId, uuid);
        if (res?.status === RESPONSE_CODE.SUCCESS) {
          setState((prev) => ({
            ...prev,
            reload: !state?.reload,
          }));
          message.success(`Đã xoá ${username} khỏi team`);
        } else {
          message.error(res?.data?.message ?? 'Có lỗi xảy ra');
        }
      },
    });
  };

  return {
    state,
    createTeamsModal,
    createMembersModal,
    createTeams,
    editTeams,
    openTopup,
    handleOpenModalTeam,
    handleOpenModalMembers,
    editMembers,
    createMembers,
    detailTeams,
    detailMembers,
    deleteMember,
    contextHolder,
  };
};

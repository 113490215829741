import { generatePath, useNavigate } from 'react-router-dom';
import { ReactComponent as IconMissedCall } from 'src/assets/dataDivided/missedCall.svg';
import callCenterService from 'src/services/callCenter.service';
import { RESPONSE_CODE, ROUTERS } from 'src/utils/constants';

import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
import React, { useContext } from 'react';
import AppContext from 'src/context/AppContext';
import { CALL_OUT_ACTION } from 'src/hooks/useStringee';

type CallInfo = {
  msisdn: number;
  callId: number;
  missedTime: string;
};

type ItemProps = {
  item: CallInfo;
  index: number;
};

export const NotiMissedCall = () => {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { calloutState, calloutDispatch }: ContextType = useContext(AppContext);

  const totalMissedCall = calloutState.missedCalls.length;

  const onSeeMore = () => {
    notification.close('missedCalls');
    navigate(ROUTERS.CallCenter.path);
  };

  React.useEffect(() => {
    if (calloutState.isShowMissedModal) {
      openNotification('bottomRight');
    }
  }, [calloutState.isShowMissedModal]);

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: (
        <p>
          Bạn có <span className={'text-bold'}>{totalMissedCall}</span> cuộc gọi
          nhỡ
        </p>
      ),
      description: renderListMissedCall(),
      placement,
      duration: 0,
      key: 'missedCalls',
      onClose: () => closeNotification(),
    });
  };

  const closeNotification = async () => {
    const ids = calloutState.missedCalls.map((item: any) => item.callId);
    await callCenterService.checkedMissedCalls(ids);
    notification.close('missedCalls');
    calloutDispatch({
      type: CALL_OUT_ACTION.showMissedModal,
      isShowMissedModal: false,
    });
  };

  const renderListMissedCall = () => {
    return (
      <div>
        {calloutState.missedCalls
          .slice(0, 5)
          .map((item: CallInfo, index: number) => (
            <ItemMissedCall
              item={item}
              key={index + item.msisdn}
              index={index}
            />
          ))}
        {totalMissedCall > 5 && (
          <a
            className={'absolute right-5 bottom-3 underline decoration-solid'}
            onClick={onSeeMore}
          >
            Xem thêm
          </a>
        )}
      </div>
    );
  };

  return <>{contextHolder}</>;
};

const ItemMissedCall = ({ item, index }: ItemProps) => {
  const navigate = useNavigate();
  const onClick = async () => {
    await callCenterService.checkedMissedCalls([item.callId]);
    const path = generatePath(ROUTERS.DataDividedDetail.path, {
      phone: item.msisdn,
    });
    navigate(path);
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderTopWidth: index !== 0 ? 1 : 0,
        borderTopStyle: 'solid',
        borderColor: '#a8a8a8',
        paddingBottom: 5,
        paddingTop: 5,
      }}
      onClick={onClick}
    >
      <IconMissedCall fill={'#EE3E3E'} className={'w-[24px] h-[24px]'} />
      <div className="ml-2">
        <div className={'text-[#00508e]'}>{item.msisdn}</div>
        <div>{item.missedTime}</div>
      </div>
    </div>
  );
};

import moment, { MomentInput } from 'moment';
import { generatePath } from 'react-router-dom';
import LevelSaleCode from 'src/components/LevelSale/LevelSaleCode';
import { ROLES, ROUTERS } from 'src/utils/constants';
import './styles.scss';
import { formatPrice } from 'src/utils/formatter';
import { isHavePermission } from 'src/utils/stringUtils';

const GetColumnsConfig: (
  handleShowDetail?: (id: number) => void,
  actionRef?: any
) => any[] = () => {
  const columns: any = [
    {
      title: 'SĐT',
      dataIndex: 'msisdn',
      key: 'msisdn',
      render: (value: string) => {
        const path = generatePath(ROUTERS.DataDividedDetail.path, {
          phone: value,
        });

        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(path);
            }}
          >
            {value}
          </div>
        );
      },
      align: 'center',
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'sourceSys',
      key: 'sourceSys',
      align: 'center',
    },
    {
      title: 'Gói đang dùng',
      dataIndex: 'packActive',
      key: 'packActive',
      align: 'center',
    },
    {
      title: 'Trạng thái chăm sóc',
      key: 'customerStage',
      dataIndex: 'customerStage',
      align: 'center',
      width: 100,
      render: (cell: string, record: DataLx3) => (
        <LevelSaleCode
          code={record.customerStage}
          color={record.customerStageDisplayColor}
          style={{ marginLeft: '25px' }}
        />
      ),
    },
    {
      title: 'Lý do',
      key: 'reason',
      dataIndex: 'reason',
      align: 'center',
      width: 130,
    },
    {
      title: 'Thời gian hẹn gọi lại',
      key: 'appointmentTime',
      dataIndex: 'appointmentTime',
      align: 'center',
      render: (_: any, record: { appointmentTime: MomentInput }) => {
        return record?.appointmentTime
          ? moment(record?.appointmentTime).format('DD/MM/YYYY HH:mm')
          : '-';
      },
    },
    {
      title: 'Ghi chú',
      key: 'note',
      dataIndex: 'note',
      align: 'center',
      width: 150,
    },
    {
      title: 'Giá gói khách hàng',
      key: 'chargePrice',
      dataIndex: 'chargePrice',
      align: 'center',
      render: (_: any, record: { chargePrice: number }) => {
        return record?.chargePrice
          ? formatPrice(record?.chargePrice) + ' VNĐ'
          : '-';
      },
    },
    {
      title: 'Lần chăm sóc cuối',
      key: 'latestCaringDate',
      dataIndex: 'latestCaringDate',
      align: 'center',
      render: (_: any, record: { latestCaringDate: MomentInput }) => {
        return record?.latestCaringDate
          ? moment(record?.latestCaringDate).format('DD/MM/YYYY HH:mm:ss')
          : '-';
      },
    },
    {
      title: 'Sale phụ trách',
      key: 'assignedStaff',
      dataIndex: 'assignedStaff',
      align: 'center',
      hidden: !isHavePermission(ROLES.ROLE_SALE_LEADER),
    },
  ].filter((item) => !item.hidden);
  return columns;
};

export default GetColumnsConfig;

import { message, notification } from 'antd';
import moment from 'moment';
import React from 'react';
import reportsServices from 'src/services/reports.service';
import { DEFAULT_FORMAT_DATE, RESPONSE_CODE } from 'src/utils/constants';
import { modifyArrTreeSelect } from 'src/utils/stringUtils';
import { saveAs } from 'file-saver';

export const useRevenReportsDaily = () => {
  const [state, setState] = React.useState<{
    loading: boolean;
    loadingExport: boolean;
    listDaily: [];
    listStaff: any[];
    staffs: string[];
    metadata: MetadataReportInterface | undefined;
    filters: FilterListInterface;
  }>({
    loading: false,
    loadingExport: false,
    listDaily: [],
    listStaff: [],
    staffs: [],
    metadata: undefined,
    filters: {
      fromDate: moment().startOf('month').startOf('day'),
      toDate: moment().endOf('day'),
      size: 50,
      page: 0,
    },
  });

  React.useEffect(() => {
    getListStaff();
  }, []);

  React.useEffect(() => {
    getListRevenueReportsDaily();
  }, [state?.filters, state?.staffs]);

  const getListStaff = async () => {
    const resp = await reportsServices.getListStaff();
    const data = resp?.data;
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        listStaff: modifyArrTreeSelect(data),
      }));
    }
    // else {
    //   notification.error({
    //     message: data,
    //   });
    // }
  };

  const getListRevenueReportsDaily = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    let params = {
      fromDate: state?.filters?.fromDate.format(DEFAULT_FORMAT_DATE),
      toDate: state?.filters?.toDate.format(DEFAULT_FORMAT_DATE),
      staffs: state?.staffs,
      page: state?.filters?.page,
      size: state?.filters?.size,
    };
    const resp = await Promise.all([
      reportsServices.getListDataDaily(params),
      reportsServices.getTotal(params),
    ]);
    const { data, totalPages, page, size, total } = resp[0]?.data;
    const totalList = resp[1]?.data;
    if (resp?.[0]?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        listDaily:
          data.length > 0
            ? [{ ...totalList, isTotal: true }].concat(data)
            : data,
        loading: false,
        metadata: {
          totalPages: totalPages,
          page: page,
          size: size,
          total: total,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      notification.error({
        message: 'Có lỗi xảy ra !',
      });
    }
  };
  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const onChangeSelectDate = (values: any) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0],
        toDate: values?.[1],
        page: 0,
      },
    }));
  };

  const handleLoadingExport = async () => {
    setState((prevState) => ({
      ...prevState,
      loadingExport: true,
    }));
    let params = {
      fromDate: state?.filters?.fromDate.format(DEFAULT_FORMAT_DATE),
      toDate: state?.filters?.toDate.format(DEFAULT_FORMAT_DATE),
      staffs: state?.staffs,
      page: state?.filters?.page,
      size: state?.filters?.size,
    };
    const resp = await reportsServices.exportFileDaily(params);
    const data = resp?.data;
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      const fileName = `CRM_HH_theo_ngay_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      message.error(resp?.data?.message || 'Tải xuống thất bại');
    }
    setState((prevState) => ({
      ...prevState,
      loadingExport: false,
    }));
  };

  const handleOnChangeStaffs = (value: string[]) => {
    const extractedValues: string[] = [];
    state?.listStaff.forEach((team) => {
      if (value.includes(team.value)) {
        if (team.children) {
          team.children.forEach((child: any) => {
            extractedValues.push(child.value);
          });
        }
      } else {
        team.children.forEach((child: any) => {
          if (value.includes(child.value)) {
            extractedValues.push(child.value);
          }
        });
      }
    });
    setState((prevState) => ({
      ...prevState,
      staffs: extractedValues,
    }));
  };

  const handleSelectBox = (values: string[]) => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        fromDate: values?.[0],
        toDate: values?.[1],
        page: 0,
      },
    }));
  };

  return {
    state,
    onPageChange,
    onChangeSelectDate,
    handleLoadingExport,
    handleOnChangeStaffs,
    handleSelectBox,
  };
};

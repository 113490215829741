import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTERS } from 'src/utils/constants';
import soundRings from '../../assets/rings.mp3';
import Phone from './assets/phone.svg';
import ComingPhone from './assets/comingPhone.svg';
import CancelPhone from './assets/cancelPhone.svg';
import Bell from './assets/bell.svg';
import MuteBell from './assets/muteBell.svg';
import AppContext from 'src/context/AppContext';
import { CALL_OUT_ACTION } from 'src/hooks/useStringee';

const BubbleCallIn = () => {
  const { calloutState, calloutDispatch }: ContextType = useContext(AppContext);
  const [isMuteBell, setIsMuteBell] = useState(false);
  const audio = useMemo(() => new Audio(soundRings), []);
  audio.loop = true;
  audio.play();
  const navigate = useNavigate();

  const handleChangeBell = () => {
    setIsMuteBell(!isMuteBell);
  };

  const onCancel = () => {
    calloutDispatch({ type: CALL_OUT_ACTION.onRejectCall });
    audio.pause();
  };

  const onAcceptCall = () => {
    calloutDispatch({ type: CALL_OUT_ACTION.onAcceptCall });
    console.log('phoneCallRinging: ', calloutState?.phoneCallRinging);
    const path = generatePath(ROUTERS.DataDividedDetail.path, {
      phone: calloutState?.phoneCallRinging,
    });
    navigate(path);
    audio.pause();
  };

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  useEffect(() => {
    if (isMuteBell) {
      audio.pause();
    } else {
      audio.play();
    }
  }, [audio, isMuteBell]);

  return (
    <div
      id={'bubble'}
      className="absolute bottom-5 right-5 bg-white shadow-xl justify-center rounded-2xl z-[100]"
    >
      <div className="flex justify-between px-1 py-4 border-b shadow-lg">
        <div onClick={handleChangeBell} className="cursor-pointer">
          <img src={isMuteBell ? MuteBell : Bell} className="w-6 h-6 mr-2" />
        </div>
      </div>
      <div className="p-6">
        <div className="flex">
          <p className="text-xl mb-0">
            <img src={Phone} className="w-6 h-6 mr-2" /> Đang có cuộc gọi đến
          </p>
        </div>
        <p className="text-2xl font-bold text-[#277CBE] pl-2 mb-0 text-center">
          {calloutState?.phoneCallRinging}
        </p>

        <div style={boxBtn}>
          <div
            style={{
              ...boxPhone,
              background: '#f11e1e',
            }}
            onClick={onCancel}
          >
            <img src={CancelPhone} className="w-14 h-15" />
          </div>
          <div
            style={{
              ...boxPhone,
              background: 'rgb(88, 215, 88)',
            }}
            onClick={onAcceptCall}
          >
            <img src={ComingPhone} className="w-10 w-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BubbleCallIn);

const boxBtn: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '60px',
  marginTop: '30px',
};

const boxPhone: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  width: '70px',
  height: '70px',
  cursor: 'pointer',
};

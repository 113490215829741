import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import getColumnsConfig from './ColumnTable';
import { useConfigPermissions } from 'src/hooks/ConfigPermission';
import { ModalDetailsPermission } from './ModalDetail';
import { ModalCreatePermission } from './ModalCreate';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';

export const ConfigPermissions = () => {
  const {
    state,
    handleDetails,
    modalDetailsRef,
    modalCreatesRef,
    handleAddPermission,
    openEditPermission,
    handleUpdatePermissions,
    handleCreatePermissions,
    onPageChange,
  } = useConfigPermissions();
  const columns = getColumnsConfig(
    handleDetails,
    Number(state?.metadata?.page) + 1,
    Number(state?.metadata?.size)
  );
  const windowSize = useWindowSize();
  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;
  return (
    <div className="p-2">
      <Helmet>
        <title>Cấu hình phân quyền</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <div className="flex justify-end">
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleAddPermission}
        >
          Tạo nhóm quyền
        </Button>
      </div>
      <Table
        rowKey={'id'}
        className="mt-3"
        dataSource={state?.listPermission}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: Number(state?.metadata?.page) + 1,
          total: Number(state?.metadata?.total),
          defaultPageSize: Number(state?.metadata?.size),
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ x: 1000, y: tableHeight }}
      />
      <ModalDetailsPermission
        ref={modalDetailsRef}
        handleEdit={openEditPermission}
      />
      <ModalCreatePermission
        ref={modalCreatesRef}
        isEdit={state?.isEdit}
        handleUpdatePermissions={handleUpdatePermissions}
        handleCreatePermissions={handleCreatePermissions}
        subModules={state?.listSubmodules}
      />
    </div>
  );
};

import moment from 'moment';
import {
  isHavePermission,
  isNullOrEmpty,
  isUserHaveOneOfPermissions,
} from './stringUtils';
import { ROLES } from './constants';

/**
 * Logout function
 */
export const onLogout = () => {
  localStorage.clear();
  window.location.href = '/';
};

/**
 * This function convert color type hex to rbga
 * @param hex example "#FFFFFF"
 * @param opacity example rgba(0, 0, 0, opacity)
 * @returns {string}
 */
export function hexToRgbA(hex: string, opacity: number = 0.3): string {
  var c: any;
  try {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return (
        'rgba(' +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
        `, ${opacity}`
      );
    }
    return '#FFF';
  } catch (e) {
    return '#FFF';
  }
}

/**
 * This function convert date to timestamp
 * @param date date input
 * @param isStartOfDay is value check get start or end day
 * @returns {number}
 */
export const convertDateToTimeStamp = (
  date: any,
  isStartOfDay: boolean
): number => {
  if (isStartOfDay) {
    return Number(moment(date).startOf('day').format('X')) * 1000;
  } else {
    return Number(moment(date).endOf('day').format('X')) * 1000;
  }
};

/**
 * This function convert date to timestamp
 * @param phone is phone number
 * @returns {string}
 */
export const replacePhoneNumber = (phone: string): string => {
  return phone.replace(/^0/g, '');
};

/**
 * This function convert list parent level sale to list child level sale
 * @param data is list parent level
 * @returns {LevelSaleItem[]}
 */
export const convertToListChildLevelSale = (
  data: LevelSaleItem[]
): LevelSaleItem[] => {
  const levels: LevelSaleItem[] = [];
  for (let i = 0; i < data.length; i++) {
    levels.push(data[i]);
    if (!isNullOrEmpty(data[i]?.childLevelSales)) {
      for (let j = 0; j < (data[i]?.childLevelSales?.length as number); j++) {
        const child = data[i]?.childLevelSales?.[j];
        child && levels.push({ ...child, displayColor: data[i].displayColor });
      }
    }
  }
  return levels;
};

/**
 * This function get color level sale with code and list level sale
 * @param code is level sale need get color
 * @param levels is list parent level
 * @returns {string}
 */
export const getLevelSaleColor = (
  code: string,
  levels: LevelSaleItem[]
): string => {
  return levels.find((item) => item.code === code)?.displayColor ?? 'green';
};

/**
 *
 */
export const getLinkAvatar = (imgName: string | undefined) => {
  return process.env.REACT_APP_API_URL + '/account/image/' + imgName;
};

const isAdmin = isHavePermission(ROLES.ROLE_ADMIN);
const isSaleManager = isHavePermission(ROLES.ROLE_SALE_MANAGER);
const isSaleLeader = isHavePermission(ROLES.ROLE_SALE_LEADER);
const isSaleMan = isHavePermission(ROLES.ROLE_SALE_MAN);
const isAdminOrSaleManager = isUserHaveOneOfPermissions([
  ROLES.ROLE_ADMIN,
  ROLES.ROLE_SALE_MANAGER,
]);
const isAccountant = isHavePermission(ROLES.ROLE_ACCOUNTANT);

export {
  isSaleLeader,
  isAdmin,
  isAccountant,
  isSaleMan,
  isSaleManager,
  isAdminOrSaleManager,
};

export const projectId = process.env.REACT_APP_PROJECT;
export const projectName = process.env.REACT_APP_PROJECT_NAME;

export const convertDataChart = (data: any, name: string, key?: string) => {
  if (data) {
    const totalCallArray: DataChart[] = Object.entries(data).map(
      ([id, item]: any) => {
        return {
          name: name,
          value: (key ? item?.[key] : item) ?? 0,
        };
      }
    );

    if (totalCallArray?.every((item) => item?.value === 0)) {
      return null;
    }
    return totalCallArray;
  }
};

export const convertDataHotCloseContactChart = (data: any) => {
  if (data) {
    const totalCallArray = Object.entries(data).map(([id, item]: any) => {
      return {
        name: id,
        value: item ?? 0,
      };
    });

    if (totalCallArray?.every((item) => item?.value === 0)) {
      return null;
    }

    return totalCallArray;
  }
};

export const uniqueData = (data: any) => {  
  const hourMap = new Map();
  const filteredData = data.filter(
    (item: any) => item.hour > 7 && item.hour < 22
  );
  filteredData.forEach((item: any) => {
    const { hour, modifiedDate } = item;

    if (!hourMap.has(hour) || modifiedDate > hourMap.get(hour).modifiedDate) {
      hourMap.set(hour, { ...item });
    }
  });
  const uniqueArray = Array.from(hourMap.values());
  while (uniqueArray.length < 14) {
    const lastHour =
      uniqueArray.length > 0 ? uniqueArray[uniqueArray.length - 1].hour : 7;
    const nextHour = lastHour + 1;
    uniqueArray.push({ hour: nextHour });
  }
  return uniqueArray;
};


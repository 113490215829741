/* eslint-disable react/prop-types */
import { Button, Modal, Row, Col, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import service from 'src/services/dataManager.service';
import { InputNumber } from 'antd';
import { TitleColumn } from 'src/components/table/TitleColumn';
import AppContext from 'src/context/AppContext';

type Props = {
  filters: SearchListDataParams;
  setOpenProps: any;
  metadataProps: any;
  onSuccess: () => void;
};

function LeaderDividedDataModal(props: Props) {
  const { appState } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const setOpen = props.setOpenProps;
  const metadata = props.metadataProps;
  const [totalData, setTotalData] = useState(0);
  const [getData, setGetData] = useState();

  const saleMan = React.useRef<any[]>([]);

  const fetchData = async () => {
    const resp = await service.getSeparatedData();
    const data = resp?.data;
    if (data && resp.status === 200) {
      saleMan.current = data.filter(
        (item: any) => item.username !== appState?.profile?.username
      );
    }
    // else {
    //   notification.error({
    //     message: resp?.data,
    //   });
    // }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = () => {
    if (totalData <= metadata?.total && totalData > 0) {
      setLoading(true);
      postData();
    } else {
      notification.error({
        message: 'Chia data thất bại',
        description: `Số data nhập vào không hợp lệ`,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onChange = (number: any, id: number) => {
    saleMan.current = saleMan.current.map((item) => {
      if (item.id === id) {
        return { ...item, numberOfData: number };
      } else {
        return item;
      }
    });
  };

  const countTotal = () => {
    let total = 0;
    saleMan.current.forEach((item) => {
      if (item.numberOfData) {
        total += item.numberOfData * 1;
      }
    });
    setTotalData(total);
  };

  const onBlur = () => {
    countTotal();
  };

  const postData = async () => {
    let body: any[] = [];
    saleMan.current.forEach((item) => {
      if (item.numberOfData && item?.numberOfData * 1 > 0) {
        body.push({ userName: item.username, numberOfData: item.numberOfData });
      }
    });
    const res = await service.separatedDataSaleMan(body, props.filters);
    if (res?.status === 200) {
      notification.success({
        message: 'Chia data thành công',
        description: 'Thành công',
      });
      props.onSuccess?.();
    } else {
      notification.error({
        message: 'Chia data thất bại',
        description: 'Thất bại' || res?.data?.message,
      });
    }
    setLoading(true);
    setOpen(false);
  };

  const subData = (e: any) => {
    if (e >= 0) {
      setGetData(e);
      console.log('e', e);
    }
  };

  const onApplyData = () => {
    saleMan.current = saleMan.current.map((item) => ({
      ...item,
      key: item.id,
      userName: item.username,
      numberOfData: getData,
    }));
    console.log('shareData', saleMan.current);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      countTotal();
    }, 100);
  };

  const renderTeamSale = () => {
    return (
      <Row gutter={24} style={{ padding: 12, borderBottom: '1px solid #ccc' }}>
        <Col
          span={13}
          style={{ fontWeight: '700' }}
        >{`TeamSale ${appState?.profile?.active} (${appState?.profile?.username})`}</Col>
        <Col span={11}>
          <InputNumber
            min={0}
            type="number"
            placeholder="Số data/1 sale man"
            style={{ width: '65%' }}
            onBlur={(e) => subData(e.target.value)}
            onChange={subData}
            onPressEnter={onApplyData}
            controls={false}
          />
          <button type="submit" style={btnStyle} onClick={onApplyData}>
            Áp dụng
          </button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        open={true}
        title="CHIA DATA CHO SALE MAN"
        onOk={onSubmit}
        onCancel={handleCancel}
        wrapClassName="edit-level-sale-modal"
        style={modalStyles}
        footer={[
          <Button
            key="submit"
            type="primary"
            // loading={loading}
            onClick={onSubmit}
            style={{
              display: 'flex',
              margin: '0 auto',
            }}
          >
            Chia data
          </Button>,
        ]}
      >
        <div style={infoStyles}>
          <span>
            Số data cần chia:{' '}
            <span style={{ fontWeight: '700' }}>{metadata?.total}</span>
          </span>
          <span>
            Số data đã nhập:{' '}
            <span style={{ fontWeight: '700' }}>{totalData}</span>
          </span>
        </div>
        <table style={tableStyles}>
          <tbody>
            <Row style={headTableStyles}>
              <Col className="gutter-row" span={12}>
                <TitleColumn
                  title={'Saleman'}
                  textStyles={{
                    fontWeight: '600',
                    textAlign: 'left',
                    paddingLeft: '80px',
                  }}
                />
              </Col>
              <Col className="gutter-row" span={12}>
                <TitleColumn
                  title={'Số data phân bố'}
                  textStyles={{ fontWeight: '600', float: 'left' }}
                />
              </Col>
            </Row>
          </tbody>
          {renderTeamSale()}
          {!loading ? (
            saleMan.current.map((saleMan) => (
              <SaleManItem
                key={saleMan.id}
                saleMan={saleMan}
                onBlur={onBlur}
                onChange={onChange}
              />
            ))
          ) : (
            <div style={{ height: 500 }} />
          )}
        </table>
      </Modal>
    </>
  );
}

export default LeaderDividedDataModal;

const SaleManItem = ({ saleMan, onBlur, onChange }) => {
  const [text, setText] = useState(saleMan?.numberOfData);

  const onChangeText = (e: any) => {
    setText(e);
    onChange(e, saleMan?.id);
  };

  return (
    <Row gutter={24} style={{ padding: 12, borderBottom: '1px solid #ccc' }}>
      <Col span={13}>{`${saleMan?.fullName} (${saleMan?.username})`}</Col>
      <Col span={11}>
        <InputNumber
          min={0}
          type="number"
          placeholder="Nhập số lượng data"
          style={{ width: '65%' }}
          onChange={onChangeText}
          onBlur={onBlur}
          value={text ?? ''}
          controls={false}
        />
      </Col>
    </Row>
  );
};

const modalStyles: React.CSSProperties = {
  display: 'block',
  justifyContent: 'center',
  minWidth: '750px',
  maxHeight: '500px',
};
const tableStyles: React.CSSProperties = {
  width: '100%,',
  display: 'flex',
  maxHeight: '600px',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'scroll',
};
const headTableStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '36px',
  background: '#277CBE',
  color: '#fff',
};
const infoStyles = {
  width: '50%',
  height: '36px',
  display: 'flex',
  justifyContent: 'space-between',
};
const btnStyle = {
  border: '1px solid #277CBE',
  borderRadius: '5px',
  fontSize: '14px',
  background: 'none',
  color: '#277CBE',
  marginLeft: '30px',
};

import { isNullOrEmpty } from './stringUtils';

export const formatter = (value?: number) => {
  return `${value}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    .replace(/\.(?=\d{0,2}$)/g, ',');
};

export const parser = (value?: string) => {
  if (!value) return Number(undefined);
  return Number(
    Number.parseFloat(
      value.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')
    ).toFixed(2)
  );
};

export const formatMonetary = new Intl.NumberFormat('de-DE', {
  useGrouping: true,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  groupSeparator: '.',
});

export const formatPrice = (price: number | undefined) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPriceWithDecimal = (
  price: number | undefined,
  showDecimal?: boolean
) => {
  const temp = Number(price)?.toFixed(2);
  const arrTemp = temp?.split('.');

  if (isNullOrEmpty(price)) {
    return showDecimal ? '0.00' : 0;
  }
  if (showDecimal) {
    return (
      arrTemp[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
      '.' +
      arrTemp[1]
    );
  }

  return arrTemp[0]?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { datePickerStyle, filterBoxStyle } from 'src/styles';
import { DATE_FORMAT, PERMISSIONS } from 'src/utils/constants';
import { isHavePermissionNew } from 'src/utils/stringUtils';
import { State } from '../..';
type Props = {
  state: State;
  defaultSelectedDate: Moment;
  defaultSelectedPackage: any;
  handleChangePackage: (value: string[]) => void;
  handleChangeAssignedDateFrom: (value: Moment | null) => void;
  onChangeStaff: (values: string[]) => void;
  onSearch: () => void;
  onExport: () => void;
};

const Filters: React.FC<Props> = ({
  state,
  defaultSelectedDate,
  onChangeStaff,
  handleChangeAssignedDateFrom,
  handleChangePackage,
  onSearch,
  onExport,
  defaultSelectedPackage,
}) => {
  return (
    <div className="flex items-center">
      {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS) && (
        <div className="flex items-center">
          <Select
            allowClear
            showSearch
            mode="multiple"
            style={filterBoxStyle}
            placeholder={'Gói đang sử dụng'}
            onChange={handleChangePackage}
            defaultValue={defaultSelectedPackage}
          >
            {state?.listPackage?.map((item: any) => (
              <Select.Option key={item.id} value={item.code}>
                {item.code}
              </Select.Option>
            ))}
          </Select>

          <SelectSaleMan
            style={filterBoxStyle}
            treeData={state.staff}
            value={state.selectedStaff}
            placeholder={'Chọn sale man'}
            onChange={onChangeStaff}
          />

          <DatePicker
            allowClear
            style={datePickerStyle}
            placeholder={'Ngày chia data'}
            onChange={handleChangeAssignedDateFrom}
            format={DATE_FORMAT}
            defaultValue={moment(defaultSelectedDate)}
          />
        </div>
      )}

      <div className="ml-3">
        {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS) && (
          <Button
            onClick={onSearch}
            type="primary"
            className="mr-3"
            icon={<SearchOutlined />}
            loading={state.isLoading}
          >
            Tìm kiếm
          </Button>
        )}
        {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS) && (
          <Button
            className="mgl-10 btn-export"
            onClick={onExport}
            type="default"
            icon={<DownloadOutlined />}
            loading={state.isLoadingExport}
          >
            Export
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filters;

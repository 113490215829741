import { PlusOutlined } from '@ant-design/icons';
import { Button, Popover, Row } from 'antd';

type Props = {
  topupCreateOrganization?: boolean;
  handleOpenModalTeam?: () => void;
  handleOpenModalMembers?: () => void;
  openTopup?: (isOpen: boolean) => void;
};

export const Filters = ({
  topupCreateOrganization,
  handleOpenModalTeam,
  handleOpenModalMembers,
  openTopup,
}: Props) => {
  return (
    <div className="mr-3 mb-3">
      <Row gutter={[24, 12]}>
        <div className="flex flex-1 justify-end">
          <Popover
            open={topupCreateOrganization}
            onOpenChange={openTopup}
            placement="bottomRight"
            content={
              <div className="w-[200px]">
                <Button
                  icon={<PlusOutlined />}
                  className="w-full mb-3"
                  onClick={handleOpenModalTeam}
                >
                  Thêm mới Team
                </Button>
                <Button
                  icon={<PlusOutlined />}
                  className="w-full"
                  onClick={handleOpenModalMembers}
                >
                  Thêm mới Members
                </Button>
              </div>
            }
            trigger="click"
          >
            <Button key="button" type="primary" icon={<PlusOutlined />}>
              Thêm mới tổ chức
            </Button>
          </Popover>
        </div>
      </Row>
    </div>
  );
};

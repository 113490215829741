import APIService from 'src/utils/apiServices';

class PermissionServices extends APIService {
  async addPermission(data?: PermissionInterface) {
    return await this.request('POST', `permissions`, data);
  }
  async getPermissions(params: MetadataInterface) {
    return await this.request('GET', `permissions`, params);
  }
  async getPermissionDetails(id: number) {
    return await this.request('GET', `permissions/${id}`);
  }
  async editPermission(id?: number, params?: PermissionInterface) {
    return await this.request('PATCH', `permissions/${id}`, params);
  }
  async getListSubmodules() {
    return await this.request('GET', `permissions/sub-modules`);
  }
}

const permissionServices = new PermissionServices();
export default permissionServices;

import { Switch } from 'antd';
import React from 'react';

type Props = {
  isSwitchNumber: boolean;
  isSwitchRatio: boolean;
  onSwitchNumber: (value: boolean) => void;
  onSwitchRatio: (value: boolean) => void;
};

const SwitchNumberRatio: React.FC<Props> = (props) => {
  const { isSwitchNumber, isSwitchRatio, onSwitchNumber, onSwitchRatio } =
    props;
  return (
    <div>
      <div style={switchContainer}>
        <p
          style={{
            ...switchTitle,
            color: isSwitchNumber ? '#1890ff' : '',
          }}
        >
          Dạng số
        </p>
        <Switch
          defaultChecked
          onChange={onSwitchNumber}
          size="small"
          disabled={isSwitchNumber && !isSwitchRatio}
        />
      </div>
      <div style={switchContainer}>
        <p
          style={{
            ...switchTitle,
            color: isSwitchRatio ? '#1890ff' : '',
          }}
        >
          Dạng %
        </p>
        <Switch
          defaultChecked
          onChange={onSwitchRatio}
          size="small"
          disabled={isSwitchRatio && !isSwitchNumber}
        />
      </div>
    </div>
  );
};

export default SwitchNumberRatio;

const switchContainer: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
};

const switchTitle = {
  fontSize: '12px',
  marginBottom: 0,
  minWidth: 50,
};

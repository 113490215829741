import { message } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import dasboardServiecs from 'src/services/dashboard.service';
import { uniqueData } from 'src/utils';
import { DEFAULT_FORMAT_DATE, RESPONSE_CODE } from 'src/utils/constants';

export const filterCallConnect = (
  originalData: any,
  startHour: number,
  endHour: number
) => {
  const result: any = {};

  for (let i = startHour; i <= endHour; i++) {
    const key = i.toString();
    if (originalData[key]) {
      result[key] = originalData[key];
    } else {
      result[key] = 0;
    }
  }

  return result;
};

export const useSalesEffectiveness = () => {
  const [state, setState] = React.useState<any>({
    filter: {
      fromDate: '',
      staffs: '',
    },
    contactData: {},
    generalData: {},
    callConnect: {},
    accumulation: {},
    lastestAction: {},
    loading: false,
    isRefresh: false,
  });

  useEffect(() => {
    if (state?.isRefresh) {
      getData();
    }
  }, [state?.isRefresh]);

  // useEffect(() => {
  //   getData();
  // }, []);

  const onChangeStaff = (value: string) => {
    setState((prev: any) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        staffs: value,
      },
    }));
  };

  const onChangeDate = (value: string) => {
    setState((prev: any) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        fromDate: value,
      },
    }));
  };

  const getData = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await Promise.all([
      dasboardServiecs.getGeneralData(state?.filter),
      dasboardServiecs.getCallConnect(state?.filter),
      dasboardServiecs.getAccumulation(state?.filter),
      dasboardServiecs.getLatestAction(state?.filter?.staffs as string),
    ]);

    if (
      res[0]?.status === RESPONSE_CODE.SUCCESS &&
      res[1]?.status === RESPONSE_CODE.SUCCESS &&
      res[2]?.status === RESPONSE_CODE.SUCCESS &&
      res[3]?.status === RESPONSE_CODE.SUCCESS
    ) {
      setState((prev: any) => ({
        ...prev,
        generalData: res[0]?.data,
        callConnect: filterCallConnect(res[1]?.data, 8, 21),
        accumulation: filterCallConnect(res[2]?.data, 8, 21),
        lastestAction: res[3]?.data,
        loading: false,
        isRefresh: false,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        isRefresh: false,
        loading: false,
      }));
      message.error('Có lỗi xảy ra');
    }
  };

  return {
    state,
    getData,
    onChangeStaff,
    onChangeDate,
  };
};

export const useCommissionRevenue = () => {
  const [state, setState] = React.useState<any>({
    filter: {
      dateString: moment().startOf('day').format(DEFAULT_FORMAT_DATE),
      team: '',
      isAll: '',
      isTeam: undefined,
      staff: '',
      staffs: '',
    },
    generalData: {},
    chartData: [],
    bestSale: [],
    hotCloseContact: [],
    totalRevenueTeamSale: {},
    handledContact: [],
    loading: false,
    isRefresh: false,
    arpuAndArpeData: [],
  });

  useEffect(() => {
    if (state?.isRefresh) {
      getAll();
    }
  }, [state?.isRefresh]);

  const onChangeDate = (value: string) => {
    setState((prev: any) => ({
      ...prev,
      isRefresh: true,
      filter: {
        ...prev.filter,
        dateString: value,
      },
    }));
  };

  const onChangeStaff = (value: { teamLeader: string; staff: string }) => {
    if (value?.teamLeader?.length > 0) {
      setState((prev: any) => ({
        ...prev,
        isRefresh: true,
        filter: {
          dateString: state?.filter?.dateString,
          isAll: true,
          staff: value?.teamLeader,
          staffs: value?.staff,
          isTeam: true,
          team: value?.teamLeader,
        },
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        isRefresh: true,
        filter: {
          dateString: state?.filter?.dateString,
          isAll: false,
          isTeam: false,
          staff: value?.staff,
          staffs: value?.staff,
          team: '',
        },
      }));
    }
  };

  const getAll = async () => {
    await Promise.all([
      getData(),
      getDataOverview(),
      getDataTotalRevenueAll(),
      getBestSale(),
      getHotCloseContact(),
      getHandledConactData(),
      getARPUAndARPE(),
    ])?.then(() => {
      setState((prev: any) => ({
        ...prev,
        isRefresh: false,
      }));
    });
  };

  const getData = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res =
      state?.filter?.staff?.length > 0
        ? await dasboardServiecs.getComissionGeneralDataByUser({
            dateString: state?.filter?.dateString,
            isTeam: state?.filter?.isTeam,
            staff: state?.filter?.staff,
          })
        : await dasboardServiecs.getComissionGeneralData({
            dateString: state?.filter?.dateString,
          });

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        generalData: res?.data,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error('Có lỗi xảy ra');
    }
  };

  const getDataTotalRevenueAll = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res =
      state?.filter?.staff?.length > 0
        ? await dasboardServiecs.getTotalRevenueAllSaleByTeam({
            dateString: state?.filter?.dateString,
            isTeam: state?.filter?.isTeam,
            staff: state?.filter?.staff,
          })
        : await dasboardServiecs.getTotalRevenueAllSale({
            dateString: state?.filter?.dateString,
          });

    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        chartData: uniqueData(res?.data),
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error('Có lỗi xảy ra');
    }
  };

  const getDataOverview = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await dasboardServiecs.getGeneralData({
      fromDate: state?.filter?.dateString,
      staffs: state?.filter?.staffs,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        contactData: res?.data,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.message ?? 'Có lỗi xảy ra');
    }
  };

  const getBestSale = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await dasboardServiecs.get5SaleEarnMost({
      dateString: state?.filter?.dateString,
      top: 5,
      isAll: state?.filter?.isAll,
      team: state?.filter?.team,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        bestSale: res?.data,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.message ?? 'Có lỗi xảy ra');
    }
  };

  const getHotCloseContact = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await dasboardServiecs.getHotCloseContact({
      fromDate: state?.filter?.dateString,
      staffs: state?.filter?.staffs,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        hotCloseContact: filterCallConnect(res?.data, 8, 21),
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.message ?? 'Có lỗi xảy ra');
    }
  };

  const getHandledConactData = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await dasboardServiecs.getHandledContact({
      fromDate: state?.filter?.dateString,
      staffs: state?.filter?.staffs,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev: any) => ({
        ...prev,
        loading: false,
        handledContact: filterCallConnect(res?.data, 8, 21),
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.message ?? 'Có lỗi xảy ra');
    }
  };

  const getARPUAndARPE = async () => {
    setState((prev: any) => ({
      ...prev,
      loading: true,
    }));
    const res = await dasboardServiecs.getArpuAndArpe({
      dateString: state?.filter?.dateString,
      isTeam: state?.filter?.isTeam,
      staff: state?.filter?.staff,
    });
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      console.log('asdasd_______asdasd', uniqueData(res?.data));
      setState((prev: any) => ({
        ...prev,
        loading: false,
        arpuAndArpeData: uniqueData(res?.data),
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        loading: false,
      }));
      message.error(res?.data?.message ?? 'Có lỗi xảy ra asdasd');
    }
  };

  return {
    state,
    getDataOverview,
    onChangeDate,
    onChangeStaff,
    // getData,
  };
};

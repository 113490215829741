import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PERMISSIONS, ROUTERS } from './utils/constants';
import { LOCAL_STORAGE_VALUE } from './utils/enum';
import { isHavePermissionNew, isNullOrEmpty } from './utils/stringUtils';

import Dashboard from './containers/Dashboard';

import Accounts from './containers/Accounts';
import ConfigLevelSale from './containers/ConfigLevelSale';

import DataManager from './containers/DataManager';
import CreateData from './containers/DataManager/CreateData';
import ImportData from './containers/DataManager/ImportData';

import CreateRegisterPackages from './containers/RegisterPackages/CreateRegisterPackages';
import RegisterPackages from './containers/RegisterPackages/RegisterPackages';

import Search from './containers/Search';
import TopUp from './containers/TopUp';

import ReportsRatioTotal from './containers/ReportsRatioTotal';
import RevenueReports from './containers/RevenueReportsDaily';
import RevenueReportsSaleman from './containers/RevenueReportsSaleman';

import Page404 from './containers/Page404';

import Login from './containers/Login';
import ChangePassword from './containers/Login/ChangePassword';
import LocalStore from './utils/localStorage';

import MainLayout from './components/Layout';
import CallCenter from './containers/CallCenter';
import DataDivided from './containers/DataManager/DataDivided';
import DataDividedDetail from './containers/DataManager/DataDividedDetail';
import DataLx3 from './containers/DataManager/DataLx3';
import Home from './containers/Home';
import { ListProduct } from './containers/ListProduct';
import ReportsRatioByDay from './containers/ReportsRatioByDay';
import RevenueReportsPhoneNumber from './containers/RevenueReportsPhoneNumber';
import UserProfile from './containers/UserProfile';
import { ConfigPermissions } from './containers/ConfigPermissions';
import Callout from './containers/Callout';
import TalkTime from './containers/TalkTime';
import { Organizations } from './containers/Organizations';
import { TenantManager } from './containers/Tenant';

const MyRoutes = () => {
  const loginSession = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.loginSession
  );
  return (
    <BrowserRouter>
      <Routes>
        {!isNullOrEmpty(loginSession) ? (
          <Route>
            {isHavePermissionNew(PERMISSIONS.DASHBOARD_READ) && (
              <Route path={ROUTERS.Dashboard.path} element={<Dashboard />} />
            )}
            <Route path="/" element={<MainLayout />}>
              {isHavePermissionNew(PERMISSIONS.HOME_READ) && (
                <Route path="/" element={<Home />} />
              )}

              {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_ACCOUNT) && (
                <Route path={ROUTERS.Accounts.path} element={<Accounts />} />
              )}

              {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_LEVEL_SALE) &&
                isHavePermissionNew(PERMISSIONS.DATA_MANAGER_VIEW_ALL) && (
                  <Route
                    path={ROUTERS.ConfigLevelSale.path}
                    element={<ConfigLevelSale />}
                  />
                )}
              {isHavePermissionNew(PERMISSIONS.DATA_NEW) && (
                <Route
                  path={ROUTERS.DataManager.path}
                  element={<DataManager />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.DATA_IMPORT) && (
                <Route
                  path={ROUTERS.DataImport.path}
                  element={<ImportData />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.DATA_ADD_NEW) && (
                <Route
                  path={ROUTERS.DataCreate.path}
                  element={<CreateData />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.DATA_DIVIDED) && (
                <Route
                  path={ROUTERS.DataDivided.path}
                  element={<DataDivided />}
                />
              )}

              {isHavePermissionNew(PERMISSIONS.DATA_DETAIL) && (
                <Route
                  path={ROUTERS.DataDividedDetail.path}
                  element={<DataDividedDetail />}
                />
              )}

              {isHavePermissionNew(PERMISSIONS.DATA_RECALL) && (
                <Route path={ROUTERS.DataLx3.path} element={<DataLx3 />} />
              )}

              {isHavePermissionNew(PERMISSIONS.ADD_ON_CALL_HISTORY) && (
                <Route
                  path={ROUTERS.CallCenter.path}
                  element={<CallCenter />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY) && (
                <Route
                  path={ROUTERS.RegisterPackage.path}
                  element={<RegisterPackages />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY) && (
                <Route
                  path={ROUTERS.CreateRegisterPackage.path}
                  element={<CreateRegisterPackages />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.ADD_ON_SEARCH) && (
                <Route path={ROUTERS.Search.path} element={<Search />} />
              )}
              {isHavePermissionNew(PERMISSIONS.ADD_ON_TOP_UP_HISTORY) && (
                <Route path={ROUTERS.TopUp.path} element={<TopUp />} />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_DAY) && (
                <Route
                  path={ROUTERS.RevenueReportsDaily.path}
                  element={<RevenueReports />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_SALE) && (
                <Route
                  path={ROUTERS.RevenueReportsSaleman.path}
                  element={<RevenueReportsSaleman />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_PHONE) && (
                <Route
                  path={ROUTERS.RevenueReportsPhone.path}
                  element={<RevenueReportsPhoneNumber />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_SUM) && (
                <Route
                  path={ROUTERS.ReportsRatioTotal.path}
                  element={<ReportsRatioTotal />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS) && (
                <Route
                  path={ROUTERS.ReportsRatioByDay.path}
                  element={<ReportsRatioByDay />}
                />
              )}
              <Route
                path={ROUTERS.UserProfile.path}
                element={<UserProfile />}
              />

              {isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_SALE) && (
                <Route
                  path={ROUTERS.RevenueReportsSaleman.path}
                  element={<RevenueReportsSaleman />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_PHONE) && (
                <Route
                  path={ROUTERS.RevenueReportsPhone.path}
                  element={<RevenueReportsPhoneNumber />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_SUM) && (
                <Route
                  path={ROUTERS.ReportsRatioTotal.path}
                  element={<ReportsRatioTotal />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS) && (
                <Route
                  path={ROUTERS.ReportsRatioByDay.path}
                  element={<ReportsRatioByDay />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER) && (
                <Route
                  path={ROUTERS.ListProduct.path}
                  element={<ListProduct />}
                />
              )}
              {isHavePermissionNew(ROUTERS.TalkTimeMonitor.key) && (
                <Route
                  path={ROUTERS.TalkTimeMonitor.path}
                  element={<TalkTime />}
                />
              )}
              <Route path={ROUTERS.Callout.path} element={<Callout />} />
              <Route
                path={ROUTERS.UserProfile.path}
                element={<UserProfile />}
              />
              {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_PERMISSION) && (
                <Route
                  path={ROUTERS.ConfigPermissions.path}
                  element={<ConfigPermissions />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_PERMISSION) && (
                <Route
                  path={ROUTERS.Organizations.path}
                  element={<Organizations />}
                />
              )}
              {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_PERMISSION) && (
                <Route path={ROUTERS.Tenant.path} element={<TenantManager />} />
              )}
              <Route path="*" element={<Page404 />} />
            </Route>
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route
              path={ROUTERS.ChangePassword.path}
              element={<ChangePassword />}
            />
            <Route path="*" element={<Page404 />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MyRoutes;

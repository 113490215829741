import { Button, Col, Form, Modal, Row } from 'antd';
import Typography from 'antd/lib/typography/Typography';
import moment from 'moment';
import React, { Ref, useImperativeHandle } from 'react';
import { PERMISSIONS, ROLES } from 'src/utils/constants';
import { isHavePermission, isHavePermissionNew } from 'src/utils/stringUtils';

type Props = {
  hanleEdit: (data?: ListPackage | undefined) => void;
};
const DetailsPackage = ({ hanleEdit }: Props, ref: Ref<any>) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<ListPackage>();

  const handleOpen = (details: ListPackage) => {
    setDetails(details);
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: handleOpen,
        close: handleClose,
      };
    },
    []
  );

  const renderStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'Ngừng kinh doanh';
      case 1:
        return 'Đang kinh doanh';
      default:
        return '';
    }
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={handleClose}
      centered
      footer={
        <div className="mb-3">
          {isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER_UPDATE) && (
            <Button
              form="myForm"
              key="submit"
              htmlType="submit"
              type="primary"
              onClick={hanleEdit?.bind(null, details)}
            >
              Sửa
            </Button>
          )}
          <Button className="mr-3" onClick={handleClose}>
            Đóng
          </Button>
        </div>
      }
      width={800}
    >
      <Form>
        <div className="flex items-center justify-center">
          <Typography className="font-semibold" style={{ fontSize: 20 }}>
            THÔNG TIN GÓI CƯỚC {details?.code}
          </Typography>
        </div>
        <Row gutter={20} className="mt-5">
          <Col span={8}>
            <Typography className="font-semibold">* Tên gói cước</Typography>
            <Typography className="ml-3 mt-1 font-semibold">
              {details?.code}
            </Typography>
          </Col>
          <Col span={8}>
            <Typography className="font-semibold">* Trạng thái</Typography>
            <Typography className="ml-3 mt-1">
              {renderStatus(details?.status || 0)}
            </Typography>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={8}>
            <Typography className="font-semibold">* Doanh thu</Typography>
            <Typography className="ml-3 mt-1">
              {details?.price?.toLocaleString() || 0} VNĐ
            </Typography>
          </Col>
          <Col span={8}>
            <Typography className="font-semibold">* Hoa hồng</Typography>
            <Typography className="ml-3 mt-1">
              {details?.shaveValueMBF?.toLocaleString() || 0} VNĐ
            </Typography>
          </Col>
          <Col span={8}>
            <Typography className="font-semibold">* Chu kỳ</Typography>
            <Typography className="ml-3 mt-1">{details?.cycle}</Typography>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={24}>
            <Typography className="font-semibold">Thông tin ưu đãi</Typography>
            <Typography className="mt-1 whitespace-pre-line">
              {details?.promotionInformation}
            </Typography>
          </Col>
        </Row>{' '}
        <Row gutter={20} className="mt-5">
          <Col span={24}>
            <Typography className="font-semibold">Điều kiện ưu đãi</Typography>
            <Typography className="mt-1 whitespace-pre-line">
              {details?.registrationConditions}
            </Typography>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={24}>
            <Typography className="font-semibold">Thông tin khác</Typography>
            <Typography className="mt-1 whitespace-pre-line">
              Sau khi đăng ký thành công tắt toàn bộ ứng dụng Internet hoặc khởi
              động lại máy để được tính cước theo gói{' '}
              <strong>{details?.code}</strong>. {'\n'}Để kiểm tra lưu lượng còn
              lại của gói cước soạn tin theo cú pháp: <strong>KT ALL</strong>{' '}
              gửi <strong>999</strong> {'\n'}Để hủy gói cước soạn tin theo cú
              pháp: <strong>HUY {details?.code}</strong> gửi{' '}
              <strong>789</strong>
            </Typography>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={24}>
            <Typography className="italic text-[12px]">
              * Chỉnh sửa gần nhất bởi {details?.modifiedBy} ngày{' '}
              {moment(details?.modifiedDate).format('DD/MM/YYYY | HH:mm:ss')}
            </Typography>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const ModalDetailsPackage = React.forwardRef(DetailsPackage);

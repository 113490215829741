import { useOrganizations } from 'src/hooks/Organizations';
import { Filters } from './Filters';
import { Header } from './Header';
import { Collapse } from 'antd';
import { CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Columns } from './Columns';
import moment from 'moment';
import { CreateTeamsModal } from './ModalCreateTeam';
import { CreateMembersModal } from './ModalCreateMembers';

export const Organizations = () => {
  const {
    state,
    createTeamsModal,
    createMembersModal,
    createTeams,
    editTeams,
    openTopup,
    handleOpenModalTeam,
    handleOpenModalMembers,
    editMembers,
    createMembers,
    detailTeams,
    detailMembers,
    deleteMember,
    contextHolder,
  } = useOrganizations();
  return (
    <div className="m-3">
      <Filters
        topupCreateOrganization={state?.topupCreateOrganization}
        openTopup={openTopup}
        handleOpenModalTeam={handleOpenModalTeam}
        handleOpenModalMembers={handleOpenModalMembers}
      />
      <div className="w-full overflow-auto h-screen pb-[150px]">
        <Header />
        <div>
          {state?.lisOrganizations?.map(
            (item: OrganizationsInterface, index: number) => (
              <Collapse
                key={index}
                accordion
                expandIcon={({ isActive }) => (
                  <div className="">
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  </div>
                )}
                bordered={false}
                style={{
                  background: 'none',
                  boxShadow: 'none',
                  borderRadius: 0,
                  borderBottom: '1px solid #DDDDDD',
                  borderLeft: '1px solid #DDDDDD',
                  borderRight: '1px solid #DDDDDD',
                  borderTop: index === 0 ? '1px solid #DDDDDD' : '',
                }}
              >
                <Collapse.Panel
                  header={
                    <Columns
                      branch={item?.members?.length.toString()}
                      region={item?.name}
                      update={moment(item?.modifiedDate).format(
                        'DD/MM/YYYY |  HH:mm'
                      )}
                      isShowOption
                      onClick={detailTeams?.bind(null, item)}
                    />
                  }
                  key="1"
                >
                  {item?.members?.map((itemChildren, indexChildren) => (
                    <div key={indexChildren}>
                      <Columns
                        branch={itemChildren?.username}
                        update={itemChildren?.modifiedDate}
                        option={
                          <div
                            onClick={deleteMember?.bind(
                              null,
                              item?.uuid,
                              itemChildren?.userId,
                              itemChildren?.username
                            )}
                          >
                            <DeleteOutlined />
                          </div>
                        }
                        className={`${indexChildren !== 0 && 'mt-5'} ml-6`}
                        onClick={detailMembers.bind(null, itemChildren)}
                      />
                    </div>
                  ))}
                </Collapse.Panel>
              </Collapse>
            )
          )}
        </div>
      </div>
      <CreateTeamsModal
        ref={createTeamsModal}
        isEdit={state?.isEdit}
        createTeams={createTeams}
        editTeams={editTeams}
      />
      <CreateMembersModal
        ref={createMembersModal}
        isEdit={state?.isEdit}
        listMembers={state?.lisMember}
        listTeams={state?.lisOrganizations}
        createMembers={createMembers}
        editMembers={editMembers}
      />
      {contextHolder}
    </div>
  );
};

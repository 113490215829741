import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { DATE_FORMAT } from 'src/utils/constants';
import { formatPriceWithDecimal } from 'src/utils/formatter';

const getColumnsConfig = () => {
  const columns: ColumnType<any>[] = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
      render: (cell: string, record: any) => {
        if (record?.isTotal) {
          return <div className="bold">Tổng</div>;
        }
        return moment(cell).format(DATE_FORMAT);
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
      render: (cell, record) => {
        console.log('record', record);
        if (record?.isTotal) {
          return (
            <div className="bold">
              {cell ? formatPriceWithDecimal(cell) : cell}
            </div>
          );
        }
        return cell ? cell : '0';
      },
    },
    {
      title: 'Số lượng đơn hàng',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      render: (cell, record) => {
        if (record?.isTotal) {
          return (
            <div className="bold">
              {cell ? formatPriceWithDecimal(cell) : cell}
            </div>
          );
        }
        return cell ? cell.toLocaleString() : '0';
      },
    },
    {
      title: 'Doanh thu MBF',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Doanh thu chuyển khoản',
      dataIndex: 'totalRevenueTransfer',
      key: 'totalRevenueTransfer',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Hoa hồng thực nhận',
      dataIndex: 'totalNetShareValue',
      key: 'totalNetShareValue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Chiết khấu khách hàng',
      dataIndex: 'totalCustomerDiscountValue',
      key: 'totalCustomerDiscountValue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Chi phí nạp thẻ lên gói',
      dataIndex: 'totalTopup',
      key: 'totalTopup',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Hoa hồng sau chiết khấu',
      dataIndex: 'totalRevenueAfterDiscountValue',
      key: 'totalRevenueAfterDiscountValue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {formatPriceWithDecimal(cell || 0)}
          </div>
        );
      },
    },
    {
      title: 'Tỷ lệ chiết khấu (%)',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (cell, record) => {
        if (record?.isTotal) {
          return (
            <div className="bold">
              {cell || cell === 0
                ? `${formatPriceWithDecimal(cell, true)}%`
                : '--'}
            </div>
          );
        }
        return cell || cell === 0
          ? `${formatPriceWithDecimal(cell, true)}%`
          : '--';
      },
    },
  ];
  return columns;
};

export default getColumnsConfig;

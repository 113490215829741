import { TreeSelect, TreeSelectProps } from 'antd';
import React from 'react';
import './styles.scss';

const SelectSaleMan: React.FC<TreeSelectProps> = (props) => {
  const filterTreeNote = (string: string, node: any) => {
    if (!node?.title) return false;
    const strSearch = string.toLowerCase();
    return (node?.title as string)?.toLowerCase()?.indexOf(strSearch) !== -1;
  };
  const { placeholder = 'Saleman', style } = props;
  return (
    <TreeSelect
      allowClear
      showSearch
      showArrow
      style={{ width: '11vw', ...style }}
      maxTagCount="responsive"
      placeholder={placeholder}
      filterTreeNode={filterTreeNote}
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      {...props}
    />
  );
};

export default SelectSaleMan;

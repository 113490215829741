import { message, notification } from 'antd';
import React, { useRef, useState } from 'react';
import permissionServices from 'src/services/permisson.service';
import { RESPONSE_CODE } from 'src/utils/constants';

export const useConfigPermissions = () => {
  const [state, setState] = useState<{
    metadata: MetadataInterface;
    listPermission: PermissionInterface[];
    listSubmodules: PermissionInterface[];
    isEdit: boolean;
    refresh: boolean;
  }>({
    metadata: {
      page: 0,
      size: 20,
    },
    listPermission: [],
    listSubmodules: [],
    isEdit: false,
    refresh: false,
  });
  const modalDetailsRef = useRef<any>(null);
  const modalCreatesRef = useRef<any>(null);

  const getListPermission = async () => {
    const params = {
      page: state?.metadata?.page,
      size: state?.metadata?.size,
    };
    const res = await permissionServices.getPermissions(params);
    const { data, page, size, total, totalPages } = res.data;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        listPermission: data,
        metadata: {
          ...prev.metadata,
          page: page,
          size: size,
          total: total,
          pageCount: totalPages,
        },
      }));
    }
    // else {
    //   notification.error({
    //     message: res?.data,
    //   });
    // }
  };

  const handleDetails = async (id: number) => {
    const res = await permissionServices.getPermissionDetails(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      modalDetailsRef?.current?.open(res?.data);
    } else {
      notification.error({
        message: res?.data?.message,
      });
    }
  };

  const createPermission = () => {};

  const updatePermission = () => {};

  const handleAddPermission = () => {
    setState((prevState) => ({
      ...prevState,
      isEdit: false,
    }));
    modalCreatesRef?.current?.open();
  };

  const openEditPermission = (data?: ListPackage | undefined) => {
    modalDetailsRef?.current?.close();
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isEdit: true,
      }));
      modalCreatesRef?.current?.open(data);
    }, 100);
  };

  const handleUpdatePermissions = async (
    id?: number,
    params?: PermissionInterface
  ) => {
    const res = await permissionServices?.editPermission(id, params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        refresh: !state?.refresh,
      }));
      message?.success('Cập nhật quyền thành công !');
      modalCreatesRef?.current?.close();
    } else {
      message.error(res?.data?.message);
      modalCreatesRef?.current?.close();
    }
  };

  const getListSubmodules = async () => {
    const res = await permissionServices?.getListSubmodules();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        listSubmodules: res?.data,
      }));
    }
    // else {
    //   notification.error({
    //     message: res?.data,
    //   });
    // }
  };

  const handleCreatePermissions = async (params?: PermissionInterface) => {
    const res = await permissionServices?.addPermission(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        refresh: !state?.refresh,
      }));
      message?.success('Thêm quyền thành công !');
      modalCreatesRef?.current?.close();
    } else {
      message.error(res?.data?.message);
      modalCreatesRef?.current?.close();
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState) => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  React.useEffect(() => {
    if (!state?.isEdit) {
      getListSubmodules();
    }
  }, []);

  React.useEffect(() => {
    getListPermission();
  }, [state?.refresh, state?.metadata?.page, state?.metadata?.size]);

  return {
    state,
    handleDetails,
    modalDetailsRef,
    modalCreatesRef,
    createPermission,
    updatePermission,
    handleAddPermission,
    openEditPermission,
    handleUpdatePermissions,
    handleCreatePermissions,
    onPageChange,
  };
};

import { PlusOutlined } from '@ant-design/icons';
import { Button, Row } from 'antd';

type Props = {
  handleOpenModalCreate: () => void;
};

export const Filters = ({ handleOpenModalCreate }: Props) => {
  return (
    <div className="mr-3 mb-3">
      <Row gutter={[24, 12]}>
        <div className="flex flex-1 justify-end">
          <Button
            key="button"
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleOpenModalCreate}
          >
            Thêm mới tài khoản
          </Button>
        </div>
      </Row>
    </div>
  );
};

import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class OrganizationsServices extends APIService {
  async getOrganizationsData() {
    const url = getQueryString(`team/filter`);
    return await this.request('GET', url);
  }
  async createTeams(params: { name: string }) {
    deleteParamsNotUsing(params);
    return await this.request('POST', `team/create`, params);
  }
  async getMemberAvailable() {
    return await this.request('GET', `team/members-available`);
  }
  async addMember(teamUuid: string, userId: number) {
    return await this.request('POST', `team/${teamUuid}/add-member/${userId}`);
  }
  async editTeam(params: any, uuid: string) {
    return await this.request('PUT', `team/${uuid}/update`, params);
  }
  async deleteMember(userId?: number, uuid?: string) {
    return await this.request('POST', `team/${uuid}/remove-member/${userId}`);
  }
  async getAgent(params: any) {
    return await this.request('GET', `agent`, params);
  }
  async createAgent(params: any) {
    return await this.request('POST', `agent`, params);
  }
  async updateAgent(params: any, uuid: number) {
    return await this.request('PATCH', `agent/${uuid}`, params);
  }
}

const organizationsServices = new OrganizationsServices();
export default organizationsServices;

import LocalStore from './localStorage';
import { ROUTER_ROLE, STATE_TAKE_CARE_OF } from './constants';
import isArray from 'lodash/isArray';
import { LOCAL_STORAGE_VALUE } from './enum';

export type ObjectType = { [key: string]: any | any[] };

/**
 * convert object to request URL
 * @param requestUrl: string url
 * @param params: object params
 * @param queryString: object query string
 */
export function getRequestUrl(
  requestUrl: string,
  params?: GetRequestParams,
  queryString?: ObjectType
): string {
  if (typeof params === 'object' && params) {
    if (params?.parentId) {
      requestUrl += `/${params.parentId}`;
    }
    if (params?.partial) {
      requestUrl += `/${params.partial}`;
    }
    if (params?.subId) {
      requestUrl += `/${params.subId}`;
    }
    if (params?.action) {
      requestUrl += `/${params.action}`;
    }
  }
  if (queryString && !isNullOrEmpty(queryString)) {
    return getQueryString(requestUrl, queryString);
  }
  return requestUrl;
}

export function getQueryString(
  requestUrl: string,
  params?: ObjectType
): string {
  if (typeof params === 'object' && params) {
    const ret: string[] = [];
    for (const d of Object.keys(params)) {
      if (isArray(params[d])) {
        const arrayParams: string = `${d}=${params[d].join(',')}`;
        ret.push(arrayParams);
      } else {
        ret.push(d + '=' + params[d]);
      }
    }

    if (ret.length > 0) {
      const retString = ret.join('&');
      if (requestUrl.includes('?')) {
        requestUrl = requestUrl + '&' + retString;
      } else {
        requestUrl = requestUrl + '?' + retString;
      }
    }
  }
  return requestUrl;
}

/**
 * thêm mới hoặc sửa lại param của query string
 * @param uri
 * @param key
 * @param value
 * @returns {*}
 */
export function updateQueryStringParameter(uri, key, value) {
  if (uri === undefined || uri === null) return '';
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (value === null) {
    return uri.replace(re, '$1' + '$2');
  }
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + encodeURIComponent(value) + '$2');
  } else {
    return uri + separator + key + '=' + encodeURIComponent(value);
  }
}

export function arrStringToString(arr, char?: string) {
  if (Array.isArray(arr)) {
    let text = '';
    for (let i = 0; i < arr.length; i++) {
      text = text + arr[i];
      if (i < arr.length - 1) {
        text = text + `${char || ','} `;
      }
    }
    return text;
  }

  return '';
}

export function isOnlySaleMan() {
  const currentLoggedUser = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.currentLoggedUser
  );

  if (currentLoggedUser) {
    if (
      Array.isArray(currentLoggedUser.roles) &&
      currentLoggedUser.roles.length === 1
    ) {
      if (currentLoggedUser.roles.includes('ROLE_SALE_MAN')) {
        return true;
      }
    }
  }

  return false;
}

export function isHavePermissionNew(permission: string) {
  if (isNullOrEmpty(permission)) {
    return false;
  }
  const currentLoggedUser: UserProfileInterface = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.currentLoggedUser
  );

  return !!currentLoggedUser?.permissions?.[permission];
}

export function isHavePermission(permission: string) {
  const currentLoggedUser = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.currentLoggedUser
  );

  if (currentLoggedUser) {
    if (Array.isArray(currentLoggedUser.roles)) {
      if (currentLoggedUser.roles.includes(permission)) {
        return true;
      }
    }
  }

  return false;
}

export const isUserHaveOneOfPermissions = (permissions: string[]) => {
  const currentLoggedUser = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.currentLoggedUser
  );
  return currentLoggedUser?.roles?.some((item: string) =>
    permissions.includes(item)
  );
};

export function isHavePermissionScene(scene: string, cLoggedUser?: any) {
  const currentLoggedUser =
    cLoggedUser ||
    LocalStore.getInstance().read(LOCAL_STORAGE_VALUE.currentLoggedUser);

  if (currentLoggedUser) {
    const curScene = ROUTER_ROLE.find((x) => x.scene === scene) || {};
    if (Array.isArray(curScene?.roles)) {
      for (let i = 0; i < curScene?.roles?.length; i++) {
        if (currentLoggedUser?.roles?.includes?.(curScene.roles[i])) {
          return true;
        }
      }
    }
  }

  return false;
}

export function modifyArrTreeSelect(
  arr: any,
  level?: any,
  arrayOrigin?: any
): any[] {
  let lvl = level || 1;
  let arrOrigin = arrayOrigin || arr;
  if (!Array.isArray(arr) || arr.length < 1) {
    return [];
  }

  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (lvl > 1) {
      if (!arrOrigin.find((x: any) => x.userName === arr[i].userName)) {
        newArr.push({
          title: `${arr[i].fullName} (${arr[i].userName})`,
          value: arr[i].userName,
          key: arr[i].userName,
          id: arr[i].id,
          userName: arr[i].userName,
        });
      }
    } else {
      newArr.push({
        title: `Team Sale ${i + 1} (${arr[i].userName})`,
        value: `team_sale_${i + 1}`,
        key: `team_sale_${i + 1}`,
        id: arr[i].id,
        userName: arr[i].userName,
        children: [
          {
            title: `${arr[i].fullName} (${arr[i].userName})`,
            value: arr[i].userName,
            key: arr[i].userName,
            id: arr[i].id,
            userName: arr[i].userName,
          },
          ...modifyArrTreeSelect(arr[i].saleManList, lvl + 1, arrOrigin),
        ],
      });
    }
  }

  return newArr;
}

/**
 * This function sales selected to params push to api
 * @param arr sales selected
 * @param staff list sale after convert
 * @returns {string[]}
 */
export function convertListSaleMan(arr: string[], staff: any): string[] {
  let newArr: string[] = [];
  arr.forEach((item) => {
    if (item.includes('team_sale_')) {
      staff
        .find(
          (staff: SaleManInfo & { value: string; title: string }) =>
            staff.value === item
        )
        ?.children?.forEach((child: any) => {
          newArr.push(child.value);
        });
    } else {
      newArr.push(item);
    }
  });
  return newArr;
}

function convertStateTakeCareToString(state: string): string {
  switch (state) {
    case STATE_TAKE_CARE_OF.success:
      return 'Đơn hàng thành công';
    case STATE_TAKE_CARE_OF.processing:
      return 'Đang chăm sóc';
    case STATE_TAKE_CARE_OF.failed:
      return 'Đơn hàng thất bại';
    default:
      return 'Đơn hàng thất bại';
  }
}

// fake value vì id có thể bị trùng
export function convertDataTree(data: StateTakeCareOf) {
  return [{ key: 'processing' }, { key: 'success' }, { key: 'failed' }].map(
    ({ key }) => ({
      value: key,
      title: convertStateTakeCareToString(key),
      children: data[key as keyof StateTakeCareOf]?.map(
        (child: SaleDataItem) => ({
          ...child,
          value: `${key}-${child?.code?.trim()}`,
          title: child.code + '-' + child.definition,
          children: child?.childLevelSales?.map((item) => ({
            ...item,
            value: `${key}-${item.code.trim()}`,
            title: item.code + '-' + item.definition,
          })),
        })
      ),
    })
  );
}

export function isNullOrEmpty(data: any | any[]): boolean {
  if (!data) {
    return true;
  }
  if (data instanceof Array) {
    return data.length === 0;
  }
  if (typeof data === 'number') {
    return data === 0;
  }
  if (typeof data === 'undefined') {
    return true;
  }
  if (typeof data === 'object') {
    return Object.keys(data).length === 0;
  }
  let output = data;
  if (typeof output !== 'string') {
    output = output.toString();
  }
  output = output.trim();

  return output.length <= 0;
}

export function deleteParamsNotUsing(params: any) {
  for (const key in params) {
    if (isNullOrEmpty(params[key]) && params[key] !== 0) {
      delete params[key];
    }
  }
}

const updatePushChildItem = (
  result: string[],
  trees: any,
  compare?: string
) => {
  if (compare) {
    result.push(compare);
    trees
      ?.find((item: SaleDataItem) => item.code === compare)
      ?.childLevelSales?.forEach((child: SaleDataItem) => {
        result.push(child.code);
      });
  } else {
    trees?.forEach((item: SaleDataItem) => {
      result.push(item.code);
      item.childLevelSales?.forEach((child: SaleDataItem) => {
        result.push(child.code);
      });
    });
  }
};

export const updateTreeDataSelect = (
  value: string[],
  treeTakeCare: any
): { processing: any; success: any; failed: any } => {
  let processing: string[] = [];
  let success: string[] = [];
  let failed: string[] = [];

  value.forEach((element: string) => {
    const key = element.split('-');
    switch (key[0]) {
      case STATE_TAKE_CARE_OF.success: {
        updatePushChildItem(success, treeTakeCare?.success, key?.[1]);
        break;
      }
      case STATE_TAKE_CARE_OF.processing: {
        updatePushChildItem(processing, treeTakeCare?.processing, key?.[1]);
        break;
      }
      case STATE_TAKE_CARE_OF.failed: {
        updatePushChildItem(failed, treeTakeCare?.failed, key?.[1]);
        break;
      }
    }
  });

  return {
    success,
    processing,
    failed,
  };
};

export const mapCallErrorCode = (errorCode: string): string => {
  switch (errorCode) {
    case '-1':
      return 'Not Init';
    case '1':
      return 'Answer Url Empty';
    case '2':
      return 'Not Init';
    case '3':
      return 'To Type Is Not Internal Or External';
    case '4':
      return 'From Number Not Found';
    case '5':
      return 'From Number Not Belong Your Account';
    case '6':
      return 'Sip Trunk Not Found';
    case '7':
      return 'Sip Trunk Not Belong Your Account';
    case '8':
      return 'Not Enough Money';
    case '9':
      return 'Connect To Charging Server Error';
    case '10':
      return 'From Number Or To Number Invalid Format';
    case '11':
      return 'Call Not Allowed By Your Server';
    case '12':
      return 'Max Concurrent Call';
    case '13':
      return 'Wait Text To Speech';
    case '14':
      return 'To Number Invalid';
    case '15':
      return 'From Number Not Belong Your Project';
    case '16':
      return 'Not Allow Chat User';
    case '17':
      return 'Not Allow Callout';
    case '18':
      return 'Request Answer Url Error';
    case '19':
      return 'Account Locked';
    case '1001':
      return 'Create peer connection error';
    case '1000':
      return 'Don’t have permission access Camera /Mic or WebRTC not support';
    default:
      return 'Có lỗi xảy ra';
  }
};

export const convertSuggestedPackages = (suggestedPackages: {
  [key: string]: string[];
}) => {
  return Object?.keys(suggestedPackages || {})?.map((name) => {
    return {
      name,
      package: suggestedPackages[name]?.join(', '),
    };
  });
};
export const formatted = (value?: string) => {
  if (isNullOrEmpty(value)) {
    return 0;
  }
  return value?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPriceChart = (
  price: number | undefined
): string | undefined => {
  let result = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (price && (price > 999999 || price < -999999)) {
    const temp = result?.slice(0, result?.length - 6);
    result = temp + 'k';
  }
  return result;
};

export const validateNumberPrice = (_rule: any, value: any, callback: any) => {
  const numericValue = parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
  if (isNaN(numericValue) || numericValue <= 0) {
    callback('Vui lòng nhập số nguyên dương');
  } else {
    callback();
  }
};

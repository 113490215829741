import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, Input, Modal, Select } from 'antd';
import Typography from 'antd/lib/typography/Typography';
import React, { Ref, useImperativeHandle } from 'react';
import { useWindowSize } from 'src/utils/customHooks';
import { validateConfigPermission } from 'src/utils/helper';

type Props = {
  isEdit?: boolean;
  handleUpdatePermissions?: (id?: number, params?: PermissionInterface) => void;
  handleCreatePermissions?: (params?: PermissionInterface) => void;
  subModules?: any;
};

const OPTION = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const CreatePermission = (
  {
    isEdit,
    handleUpdatePermissions,
    handleCreatePermissions,
    subModules,
  }: Props,
  ref: Ref<any>
) => {
  const [form] = Form.useForm();
  const windowSize = useWindowSize();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    PermissionInterface | undefined
  >();
  const [collapseActiveKey, setCollapseActiveKey] = React.useState([]);

  const handleClose = () => {
    form.resetFields();
    setModalOpen(false);
    setCollapseActiveKey([]);
  };

  const handleFillData = (data?: PermissionInterface | undefined) => {
    if (isEdit) {
      form?.setFieldsValue({
        title: data?.title,
        description: data?.description,
        modules: data?.modules,
        subModules: data?.modules?.map((item) => ({
          subModules: item.subModules,
        })),
      });
    } else {
      form?.setFieldsValue({
        modules: subModules?.modules,
        subModules: subModules?.modules?.map((item: any) => ({
          subModules: item.subModules,
        })),
      });
    }
  };

  const getEnabledSubmoduleIds = (modules: any) => {
    const enabledIds: number[] = [];
    modules.forEach((subModule: any) => {
      if (subModule.enable) {
        enabledIds.push(subModule.id);
      }
    });
    return enabledIds;
  };

  const onFinish = (values: any) => {
    const outputData: any = {
      title: values.title.trim().replace(/\s+/g, ' '),
      description: values.description,
      subModuleIds: [],
    };
    values.modules.forEach((module: any) => {
      const moduleSubmoduleIds = getEnabledSubmoduleIds(module.subModules);
      outputData.subModuleIds.push(...moduleSubmoduleIds);
    });
    if (isEdit) {
      handleUpdatePermissions?.(dataDetails?.id, outputData);
    } else {
      handleCreatePermissions?.(outputData);
    }
  };

  const handleCollapseChange = (activeKey: any) => {
    setCollapseActiveKey(activeKey);
  };

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data?: PermissionInterface | undefined) => {
          setDataDetails(data);
          handleFillData(data);
          setModalOpen(true);
        },
        close: handleClose,
      };
    },
    [isEdit, subModules]
  );

  return (
    <Modal
      open={modalOpen}
      onCancel={handleClose}
      centered
      footer={
        <div className="mb-3">
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            {isEdit ? `Cập nhật` : 'Thêm mới'}
          </Button>
          <Button className="mr-3" onClick={handleClose}>
            Đóng
          </Button>
        </div>
      }
      width={800}
      bodyStyle={{ height: windowSize?.height - 70, overflowY: 'hidden' }}
    >
      <div className="flex items-center justify-center mb-5">
        <Typography className="font-semibold" style={{ fontSize: 20 }}>
          {isEdit ? `CHỈNH SỬA QUYỀN` : 'THÊM MỚI QUYỀN'}
        </Typography>
      </div>
      <Form form={form} layout="vertical" id="myForm" onFinish={onFinish}>
        <Form.Item
          label={'Tên nhóm quyền:'}
          name={'title'}
          rules={[
            {
              required: true,
              validator: validateConfigPermission,
            },
          ]}
        >
          <Input placeholder="Tên nhóm quyền" maxLength={255} />
        </Form.Item>
        <Form.Item
          label={'Mô tả:'}
          name={'description'}
          style={{ marginBottom: 10 }}
        >
          <Input.TextArea
            placeholder="Mô tả nhóm quyền"
            style={{ height: 120 }}
          />
        </Form.Item>
        <div className="mt-3" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <Form.List name="modules">
            {(modulesFields, {}) => (
              <>
                {modulesFields?.map(
                  ({ key: modulesKey, name: modulesName }) => (
                    <Collapse
                      key={modulesKey}
                      accordion
                      expandIcon={({ isActive }) => (
                        <div className="">
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        </div>
                      )}
                      bordered={false}
                      style={{
                        background: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        borderTop: modulesKey != 0 ? '1px solid #f2f2f2' : '',
                      }}
                      activeKey={collapseActiveKey}
                      onChange={handleCollapseChange}
                    >
                      <Collapse.Panel
                        header={
                          <Typography className="font-semibold">
                            {form.getFieldValue([
                              'modules',
                              modulesKey,
                              'name',
                            ])}
                          </Typography>
                        }
                        key={modulesKey}
                      >
                        <Form.List name={[modulesName, 'subModules']}>
                          {(subModulesFields, {}) => (
                            <>
                              {subModulesFields?.map(
                                ({
                                  key: subModulesKey,
                                  name: subModulesName,
                                }) => (
                                  <div
                                    key={subModulesKey}
                                    className="flex items-center justify-between ml-10 mr-10"
                                  >
                                    <Typography>
                                      {form.getFieldValue([
                                        'subModules',
                                        modulesName,
                                        'subModules',
                                        subModulesKey,
                                        'name',
                                      ])}
                                    </Typography>
                                    <Form.Item
                                      name={[subModulesName, 'enable']}
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Select
                                        options={OPTION}
                                        style={{
                                          width: 70,
                                          fontSize: 12,
                                        }}
                                      />
                                    </Form.Item>
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </Form.List>
                      </Collapse.Panel>
                    </Collapse>
                  )
                )}
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Modal>
  );
};

export const ModalCreatePermission = React.forwardRef(CreatePermission);

export const scriptCallEvent = `
let stringeeClient,
  call,
  authenticatedWithUserId = '';

function newStringeeClient(client) {
  stringeeClient = client
}

function connectStringee(accessToken) {
  settingClientEvents(stringeeClient);
  stringeeClient.connect(accessToken);
}

function makeACall(from, to, StringeeCall) {
  call = new StringeeCall(stringeeClient, from, to);
  settingCallEvents(call);
  call?.makeCall((res) => {
    console.log('make call callback: ' + JSON.stringify(res));
    window.dispatchEvent(new CustomEvent('callbackCall', { detail: { ...res } }));
  });
}

function hangup() {
  remoteVideo.srcObject = null;
  call?.hangup((res) => {
    console.log('hangup res', res);
    window.dispatchEvent(new CustomEvent('endcall', { detail: { ...res } }));
  });
}

function answerCall() {
  call?.answer((res) => {
    console.log('answer res', res);
  });
}

function rejectCall() {
  call?.reject((res) => {
    console.log('reject res', res);
    if (res?.r === 3) {

    }
  });
}

function hold() {
  call?.hold();
}

function unhold() {
  call?.unhold();
}

function mute(enabled) {
  call?.mute(enabled);
}

function settingClientEvents(client) {
  console.log('settingClientEvents');

  client.on('connect', function () {
    console.log('connected stringee');
    window.dispatchEvent(new CustomEvent('connected'));
  });

  client.on('authen', function (res) {
    console.log('authen', res);
    window.dispatchEvent(new CustomEvent('authen', { detail: { ...res } }));
  });

  client.on('disconnect', function () {
    console.log('disconnected');
    window.dispatchEvent(new CustomEvent('disconnected'));
  });

  client.on('requestnewtoken', function () {
    console.log(
      '++++++++++++++ requestnewtoken; please get new access_token from YourServer and call client.connect(new_access_token)+++++++++'
    );
    window.dispatchEvent(new CustomEvent('requestnewtoken'));
  });

  client.on('incomingcall', function (incomingcall) {
    call = incomingcall;
    settingCallEvents(incomingcall);

    console.log('incomingcall', incomingcall);
    window.dispatchEvent(
      new CustomEvent('incomingcall', { detail: { ...incomingcall } })
    );
  });

  client.on('otherdeviceauthen', (data) => {
    console.log('otherdeviceauthen: ', data);
  });

  client.on('otherdeviceauthen', (data) => {
    console.log('otherdeviceauthen: ', data);
  });
}

function settingCallEvents(call1) {
  console.log('settingCallEvents');

  call1.on('error', (info) => {
    console.log('on error: ' + JSON.stringify(info));
  });

  call1.on('addlocalstream', function (stream) {
    console.log(
      'addlocalstream, khong xu ly event nay, xu ly o event: addlocaltrack'
    );
  });

  call1.on('addremotestream', (stream) => {
    console.log('on addremotestream', stream);
    // reset srcObject to work around minor bugs in Chrome and Edge.
    remoteVideo.srcObject = null;
    remoteVideo.srcObject = stream;
  });

  call1.on('signalingstate', function (state) {
    console.log('signalingstate ', state);
    window.dispatchEvent(
      new CustomEvent('signalingstate', { detail: { ...state } })
    );
  });

  call1.on('mediastate', function (state) {
    console.log('mediastate ', state);
  });

  call1.on('info', function (info) {
    console.log('++++info ', info);
  });

  call1.on('otherdevice', function (msg) {
    console.log('otherdevice ', msg);
  });

  call1.on('addlocaltrack', function (localtrack1) {
    console.log('addlocaltrack', localtrack1);
  });

  call1.on('addremotetrack', function (track) {
    var element = track.attach();
    // document.getElementById("remote_videos").appendChild(element);
    // element.style.height = "150px";
  });

  call1.on('removeremotetrack', function (track) {
    track.detachAndRemove();
  });

  call1.on('removelocaltrack', function (track) {
    track.detachAndRemove();
  });
}
`;

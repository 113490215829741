import { useTenant } from 'src/hooks/Tenant';
import { Filters } from './Filters';
import { Table } from 'antd';
import { useWindowSize } from 'src/utils/customHooks';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'src/utils/constants';
import getColumnsConfig from './ColumnsTable';
import { CreateTenantModal } from './ModalCreate';

export const TenantManager = () => {
  const {
    state,
    onPageChange,
    createModal,
    createTenant,
    editTenant,
    handleOpenModalCreate,
    handleShowDetail,
  } = useTenant();
  const windowSize = useWindowSize();

  const columns = getColumnsConfig(
    handleShowDetail,
    Number(state?.filters.page),
    Number(state?.filters.size)
  );

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;

  return (
    <div className="m-3">
      <Filters handleOpenModalCreate={handleOpenModalCreate} />
      <Table
        rowKey={'id'}
        dataSource={state?.listAccountTenant}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          defaultCurrent: (state?.filters.page ?? 0) + 1,
          total: state?.metadata?.total,
          defaultPageSize: state?.filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
      <CreateTenantModal
        ref={createModal}
        isEdit={state?.isEdit}
        createTenant={createTenant}
        editTenant={editTenant}
      />
    </div>
  );
};

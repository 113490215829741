import { Col, Row } from 'antd';
import { FC, ReactElement } from 'react';
import { INTERACTION_HISTORY_TYPE } from 'src/utils/constants';

import { ReactComponent as IconCallIn } from 'src/assets/callCenter/callIn.svg';
import { ReactComponent as IconCallOut } from 'src/assets/callCenter/callOut.svg';
import { ReactComponent as IconMissedCall } from 'src/assets/callCenter/missedCall.svg';
import { formatted } from 'src/utils/stringUtils';

type Props = {
  item: InteractionHistory;
};

const InteractionItem: FC<Props> = ({ item }) => {
  const convertStatus = (status?: string): ReactElement => {
    switch (status) {
      case 'Thành công':
        return (
          <span style={{ color: '#277CBE', fontWeight: 600 }}>Thành công</span>
        );
      case 'Thất bại':
        return (
          <span style={{ color: '#E81A1A', fontWeight: 600 }}>Thất bại</span>
        );
      default:
        return <></>;
    }
  };

  // ex text: C90N=[C90N]
  // const renderPackage = (text: string, index: number) => {
  //   const newData = text.replace(']', '').split('=[');
  //   if (newData.length === 2) {
  //     return (
  //       <div key={index + 'package'}>
  //         <span style={{ fontWeight: 'bold' }}>{newData[0]} </span>
  //         {newData[1]}
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const convertInteraction = (
    type: InteractionType,
    content: InteractionContent
  ): ReactElement => {
    switch (type) {
      case INTERACTION_HISTORY_TYPE.assignData:
        return (
          <div>
            <span style={textBold}>{content?.executorStaff}</span> chia data cho{' '}
            <span style={textBold}>{content?.assignedStaff}</span>
          </div>
        );
      case INTERACTION_HISTORY_TYPE.recallDataManual:
        return (
          <div>
            <span style={textBold}>{content?.interactionTime}</span>
            <span style={textBold}>{content?.actionStaff}</span> đã thu hồi data
          </div>
        );
      case INTERACTION_HISTORY_TYPE.outsideRegisterPackage:
        return (
          <div>
            <span style={textBold}>{content?.createdBy}</span> thêm mới giao
            dịch đăng ký ngoài CRM
            <div>
              Gói cước <span style={textBold}>{content?.packageCode}</span>, giá
              gói{' '}
              <span style={textBold}>
                {content?.packagePrice?.replace(/(.)(?=(\d{3})+$)/g, '$1,')}đ
              </span>
            </div>
            <div>
              Saleman: <span style={textBold}>{content?.saleMan}</span>
            </div>
            <div>
              Kênh: <span style={textBold}>{content?.paymentChannel}</span>
            </div>
            <div>
              Ngày đăng ký:{' '}
              <span style={textBold}>{content?.registeredDate}</span>
            </div>
          </div>
        );
      case INTERACTION_HISTORY_TYPE.outsideRegisterPackageUpdate:
        return (
          <div>
            <span style={textBold}>{content?.modifiedBy}</span> chỉnh sửa giao
            dịch đăng ký ngoài CRM
            <div>
              Gói cước <span style={textBold}>{content?.packageCode}</span>, giá
              gói{' '}
              <span style={textBold}>
                {content?.packagePrice?.replace(/(.)(?=(\d{3})+$)/g, '$1,')}đ
              </span>
            </div>
            <div>
              Saleman: <span style={textBold}>{content?.saleMan}</span>
            </div>
            <div>
              Kênh: <span style={textBold}>{content?.paymentChannel}</span>
            </div>
            <div>
              Ngày đăng ký:{' '}
              <span style={textBold}>{content?.registeredDate}</span>
            </div>
          </div>
        );
      case INTERACTION_HISTORY_TYPE.updateCustomerStage:
        if (content?.additionalMessage) {
          return (
            <div>
              {content?.additionalMessage}
              {content?.oldStage !== content?.newStage && (
                <div>
                  <span style={textBold}>Chuyển trạng thái:</span> từ{' '}
                  <span style={textBold}>{content?.oldStage}</span>{' '}
                  {(content?.oldStage?.substring(1, 2) ?? 0) >
                  (content?.newStage?.substring(1, 2) ?? 0)
                    ? 'xuống '
                    : 'lên '}
                  <span style={textBold}>{content?.newStage}</span>
                </div>
              )}
              {content?.levelSaleDescription && (
                <div>
                  <span style={textBold}>Trạng thái chăm sóc:</span>{' '}
                  {content?.levelSaleDescription}
                </div>
              )}
            </div>
          );
        }
        return (
          <>
            <div>
              <span style={textBold}>{content?.actionStaff}</span>{' '}
              {`đã cập nhật ${
                content?.oldStage ? `trạng thái data` : `ghi chú`
              }`}
            </div>
            {content?.status && content?.oldStage !== content?.newStage && (
              <div>
                <span style={textBold}>Chuyển trạng thái:</span> từ{' '}
                <span style={textBold}>{content?.oldStage}</span>{' '}
                {(content?.oldStage?.substring(1, 2) ?? 0) >
                (content?.newStage?.substring(1, 2) ?? 0)
                  ? 'xuống '
                  : 'lên '}
                <span style={textBold}>{content?.newStage}</span>
                {convertStatus(content?.status)}
              </div>
            )}
            {content?.status && (
              <div>
                <span style={textBold}>Trạng thái chăm sóc:</span>{' '}
                {content?.status}
              </div>
            )}
            {content?.reason && (
              <div>
                <span style={textBold}>Lý do:</span> {content?.reason}
              </div>
            )}
            {content?.note && (
              <div>
                <span style={textBold}>Ghi chú:</span> {content?.note}
              </div>
            )}
          </>
        );
      case INTERACTION_HISTORY_TYPE.topUp:
        return (
          <>
            <div>
              <span style={textBold}>{content?.createdStaff}</span> thực hiện
              nạp thẻ{' '}
              <span style={textBold}>
                {content?.amount?.replace(/(.)(?=(\d{3})+$)/g, '$1,')}đ
              </span>
            </div>
            {content?.status && (
              <div>
                <span style={textBold}>Trạng thái:</span>{' '}
                {convertStatus(content?.status)}
              </div>
            )}
          </>
        );
      case INTERACTION_HISTORY_TYPE.requestRegisterPackage:
        return (
          <>
            <div>
              <span style={textBold}>{content?.createdStaff}</span> tạo yêu cầu
              đăng ký gói cước{' '}
              <span style={textBold}>{content?.phonePackage}</span>
            </div>
          </>
        );
      case INTERACTION_HISTORY_TYPE.registerPackage:
        return (
          <>
            <div>
              <span style={textBold}>{content?.createdStaff}</span> thực hiện
              đăng ký gói <span style={textBold}>{content?.phonePackage}</span>,
              giá gói{' '}
              <span style={textBold}>
                {content?.phonePackagePrice?.replace(
                  /(.)(?=(\d{3})+$)/g,
                  '$1,'
                )}
                đ
              </span>
            </div>
            <div>
              <span style={textBold}>Trạng thái:</span>{' '}
              {convertStatus(content?.status)}
            </div>
            {content?.reason && (
              <div>
                <span style={textBold}>Lý do:</span> {content?.reason}
              </div>
            )}
          </>
        );
      case INTERACTION_HISTORY_TYPE.searching:
        const { searchingType, success, errorMessage } = content;
        if (searchingType === 'search_base') {
          return success === '1' ? (
            <div>
              <span style={textBold}>{content?.actionStaff}</span> tra cứu thông
              tin gói cước
            </div>
          ) : (
            <>
              <span style={textBold}>{content?.actionStaff}</span> tra cứu thông
              tin gói cước đang sử dụng
              <div>
                <span style={{ ...textBold, color: '#fa541c' }}>
                  Tra cứu thất bại:{' '}
                </span>
                {errorMessage}
              </div>
            </>
          );
        }
        if (searchingType === 'search_main_account') {
          return success === '1' ? (
            <div>
              <span style={textBold}>{content?.actionStaff}</span> tra cứu thông
              tin TKC
              <div>
                <span style={textBold}>TKC: </span>
                <span style={{ ...textBold, color: '#277cbe' }}>
                  {Number(content?.currentAmount).toLocaleString('de-DE')}đ
                </span>
              </div>
            </div>
          ) : (
            <>
              <span style={textBold}>{content?.actionStaff}</span> tra cứu thông
              tin TKC
              <div>
                <span style={{ ...textBold, color: '#fa541c' }}>
                  Tra cứu thất bại:{' '}
                </span>
                {errorMessage}
              </div>
            </>
          );
        }
        if (searchingType === 'search_auto') {
          return (
            <div>
              <span style={textBold}>{content?.actionStaff}</span> tra cứu gói
              cước tự động
              {success !== '1' && (
                <div>
                  <span style={{ ...textBold, color: '#fa541c' }}>
                    Tra cứu thất bại:{' '}
                  </span>
                  {errorMessage}
                </div>
              )}
            </div>
          );
        }
        if (searchingType === 'search_b9') {
          return (
            <div>
              <span style={textBold}>{content?.actionStaff}</span> thực hiện tra
              cứu TB
              {success !== '1' && (
                <div>
                  <span style={{ ...textBold, color: '#fa541c' }}>
                    Tra cứu thất bại:{' '}
                  </span>
                  {errorMessage}
                </div>
              )}
            </div>
          );
        }
        return <div />;
      case INTERACTION_HISTORY_TYPE.moveData:
        return (
          <div>
            <span style={textBold}>{content?.actionStaff}</span> chuyển data từ{' '}
            <span style={textBold}>{content?.sourceStaff}</span> sang{' '}
            <span style={textBold}>{content?.destinationStaff}</span>
          </div>
        );
      case INTERACTION_HISTORY_TYPE.recallData:
        return (
          <div>
            <div>{content?.interactionTime}</div>
            <div>{content?.message}</div>
          </div>
        );
      case INTERACTION_HISTORY_TYPE.callDataHistory:
        let textType = '';
        let isShowCallingDuration = false;
        let callInfo = (
          <div>
            <IconMissedCall style={{ marginRight: 4 }} />
            <span style={textBold}>Cuộc gọi nhỡ</span>
          </div>
        );
        switch (content?.callingType) {
          case 'missed': {
            textType = 'đã bỏ lỡ cuộc gọi từ khách hàng';
            break;
          }
          case 'outgoing': {
            textType = 'thực hiện cuộc gọi';
            isShowCallingDuration = content?.callingStatus === 'answered';
            callInfo = (
              <div>
                <IconCallOut style={{ marginRight: 4 }} />
                <span style={textBold}>
                  Gọi đi /{' '}
                  {content?.callingStatus === 'answered'
                    ? 'Nghe máy'
                    : 'Không nghe máy'}
                </span>
              </div>
            );
            break;
          }
          case 'incoming': {
            textType = 'tiếp nhận cuộc gọi';
            isShowCallingDuration = true;
            callInfo = (
              <div>
                <IconCallIn style={{ marginRight: 4 }} />
                <span style={textBold}>Cuộc gọi đến / Nghe máy</span>
              </div>
            );
            break;
          }
          case '4': {
            textType = 'đã từ chối cuộc gọi';
            break;
          }
        }
        return (
          <div>
            <div>
              <span style={textBold}>{content?.saleMan}</span> {textType}
            </div>
            {callInfo}
            {isShowCallingDuration && (
              <div>
                Thời gian đàm thoại:{' '}
                <span style={textBold}>
                  {content?.callingDuration?.slice(3, 8)}s
                </span>
              </div>
            )}
          </div>
        );
      case INTERACTION_HISTORY_TYPE.topup_outside:
        return (
          <>
            <div>
              <span style={textBold}>{content?.actionStaff}</span> đã ghi nhận
              nạp ngoài CRM số tiền{' '}
              <span style={textBold}>
                {Number(content?.value).toLocaleString('en-US') || 0} VNĐ
              </span>
            </div>
          </>
        );
      case INTERACTION_HISTORY_TYPE.customer_transfer:
        return (
          <>
            <div>
              <span style={textBold}>{content?.actionStaff}</span> đã nhập KH
              chuyển khoản số tiền{' '}
              <span style={textBold}>
                {Number(content?.value).toLocaleString('en-US') || 0} VNĐ
              </span>
            </div>
          </>
        );
      default:
        return <div />;
    }
  };
  return (
    <Row
      style={{ paddingTop: 16 }}
      gutter={[
        { xl: 12, lg: 12, md: 12, sm: 12, xs: 12 },
        { xl: 16, lg: 16, md: 16, sm: 16, xs: 16 },
      ]}
    >
      <Col span={6} style={{ paddingLeft: 12 }}>
        {item.interactionTime}
      </Col>
      <Col span={16}>
        {convertInteraction(item.interactionType, item.interactionContent)}
      </Col>
    </Row>
  );
};

export default InteractionItem;

const textBold = {
  fontWeight: 600,
};

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { PACKAGE_STATUS, PERMISSIONS, ROLES } from 'src/utils/constants';
import { isHavePermission, isHavePermissionNew } from 'src/utils/stringUtils';

type Props = {
  onCreatePackage: () => void;
  onChangeStatus: (values: number) => void;
  handleChangePackage: (e: { target: { value: string } }) => void;
  packages: ListPackage[];
};

export const Filter = ({
  packages,
  onCreatePackage,
  onChangeStatus,
  handleChangePackage,
}: Props) => {
  return (
    <div className="flex p-3 justify-between items-center">
      <div className="flex w-full">
        {isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER) && (
          <div className="w-[calc(30%)]">
            <Input
              placeholder="Nhập gói cước tìm kiếm"
              className="w-full"
              allowClear
              style={{ borderRadius: 5 }}
              onChange={handleChangePackage}
            />
          </div>
        )}
        {isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER) && (
          <div className="w-[calc(20%)] ml-2">
            <Select
              placeholder={'Chọn trạng thái'}
              className="w-full"
              options={PACKAGE_STATUS}
              allowClear
              onChange={onChangeStatus}
            />
          </div>
        )}
      </div>
      {isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER_ADD) && (
        <Button
          type="primary"
          className="ml-2"
          icon={<PlusOutlined />}
          onClick={onCreatePackage}
        >
          Thêm gói cước
        </Button>
      )}
    </div>
  );
};

import React from 'react';
import { Collapse } from 'antd';
import { Col, Row } from 'antd';
import moment from 'moment';
import LevelSaleCode from 'src/components/LevelSale/LevelSaleCode';
import { isHavePermissionNew } from 'src/utils/stringUtils';
import { PERMISSIONS } from 'src/utils/constants';

type Props = {
  item: LevelSaleItem;
  onEdit: (item: LevelSaleItem, isParent: boolean) => void;
  onDelete: (item: LevelSaleItem) => void;
};

const LevelSaleItem = (props: Props) => {
  const { item, onEdit, onDelete } = props;
  const { Panel } = Collapse;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  const panelStyle =
    (item?.childLevelSales?.length ?? 0) > 0 ? undefined : { height: '48px' };

  return (
    <div style={listStyles}>
      <Collapse onChange={onChange} accordion>
        <Panel
          header={
            <LevelSaleInfo
              data={item}
              onEdit={onEdit}
              onDelete={onDelete}
              isParent={true}
            />
          }
          style={panelStyle}
          key="1"
        >
          {item?.childLevelSales?.map((sale, index) => (
            <LevelSaleInfo
              key={'level-sale-' + index}
              data={sale}
              onEdit={onEdit}
              onDelete={onDelete}
              style={levelSaleChildStyle}
              isParent={false}
            />
          ))}
        </Panel>
      </Collapse>
    </div>
  );
};

export { LevelSaleItem };

const listStyles: React.CSSProperties = {
  width: '100%',
};

type LevelSaleInfoProps = {
  data: LevelSaleItem;
  onEdit: (item: LevelSaleItem, isParent: boolean) => void;
  onDelete: (item: LevelSaleItem) => void;
  style?: React.CSSProperties;
  color?: string;
  isParent: boolean;
};

const LevelSaleInfo = (props: LevelSaleInfoProps) => {
  const { data, onEdit, style, isParent } = props;

  return (
    <Row gutter={16} style={style}>
      <Col className="gutter-row" span={2}>
        <div style={boxCode}>
          <LevelSaleCode
            code={data.code}
            color={data.displayColor}
            style={{ width: isParent ? '80px' : '60px' }}
          />
        </div>
      </Col>
      <Col className="gutter-row" span={7}>
        <div>{data.definition}</div>
      </Col>
      <Col className="gutter-row" span={5}>
        <div>{data.createdBy}</div>
      </Col>
      <Col className="gutter-row" span={6}>
        <div>{moment(data.createdDate).format('DD/MM/YYYY HH:mm:ss')}</div>
      </Col>
      {isHavePermissionNew(PERMISSIONS.ADMINISTRATION_LEVEL_SALE) && (
        <Col className="gutter-row" span={4}>
          <div className="dpl-flex justify-content-flex-start">
            <div
              className="cursor-pointer txt-blue"
              onClick={() => onEdit(data, isParent)}
            >
              Chỉnh sửa
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

const levelSaleChildStyle: React.CSSProperties = {
  padding: '10px 0 10px 24px',
};

const boxCode: React.CSSProperties = {
  justifyContent: 'flex-end',
  display: 'flex',
  width: '80px',
};

import React, { useEffect } from 'react';
import { notification, Pagination, Spin, Table } from 'antd';
import moment, { Moment } from 'moment';

import dataManagerServices from 'src/services/dataManager.service';
import reportsServices from 'src/services/reports.service';
import { convertDateToTimeStamp, projectId, projectName } from 'src/utils';
import {
  convertListSaleMan,
  isHavePermissionNew,
  isUserHaveOneOfPermissions,
  modifyArrTreeSelect,
  updateTreeDataSelect,
} from 'src/utils/stringUtils';
import {
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
  PERMISSIONS,
  RESPONSE_CODE,
  ROLES,
  ROUTERS,
  STATE_TAKE_CARE_OF,
} from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import LevelSaleCode from 'src/components/LevelSale/LevelSaleCode';
import { saveAs } from 'file-saver';
import { generatePath } from 'react-router-dom';
import { paginationStyles } from 'src/styles';
import { convertReasonsToTreeData } from 'src/utils/treeSelect';
import { DataDividedFilters as DataDividedFilter } from './DataDividedFilters';
import { Helmet } from 'react-helmet';
import { formatPrice } from 'src/utils/formatter';

const _ = require('lodash');

export type DataDividedState = {
  loading: boolean;
  loadingSearch: boolean;
  loadingGetAllPhones: boolean;
  isLoadingExport: boolean;
  loadingReset: boolean;
  staffs: any;
  treeTakeCare: any;
  treeReason: TREE_REASON[];
  takeCareSelected: any;
  staffSelected: any;
  divided: DataDividedItem[];
  total: number;
  filters: DataDividedFilters;
  extendSearch: boolean;
  defaultTreeReason: string[];
};

const MAX_RECORDS_SUPPORT = 300;

const defaultDividedFilters: DataDividedFilters = {
  assignedDate: undefined,
  assignedDateFrom: undefined,
  assignedDateTo: undefined,
  msisdn: null,
  packActive: undefined,
  expiredDate: undefined,
  expiredDateFrom: undefined,
  allocatedDateTo: undefined,
  assignedStaffs: undefined,
  customerStages: undefined,
  sourceSys: undefined,
  size: DEFAULT_PAGE_SIZE,
  allocatedDateFrom: undefined,
  page: 0,
};

const DataDivided: React.FC = () => {
  const COLUMNS = [
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
      width: '4%',
      render: (value: string) => {
        const path = generatePath(ROUTERS.DataDividedDetail.path, {
          phone: value,
        });

        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(path);
            }}
          >
            {value}
          </div>
        );
      },
      fixed: 'left',
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'sourceSys',
      key: 'sourceSys',
      width: '3%',
    },
    {
      title: 'Gói đang dùng',
      dataIndex: 'packActive',
      key: 'packActive',
      width: '3%',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      width: '7%',
      render: (cell: Moment) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Số ngày chăm sóc còn lại',
      dataIndex: 'remainingCaringDate',
      key: 'remainingCaringDate',
      width: '5%',
    },
    {
      title: 'Trạng thái chăm sóc',
      dataIndex: 'customerStage',
      key: 'customerStage',
      width: '4%',
      render: (cell: string, record: DataDividedItem) => (
        <LevelSaleCode
          code={record.customerStage}
          color={record.customerStageDisplayColor}
        />
      ),
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      width: '6%',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'customerStageNote',
      key: 'customerStageNote',
      width: '16%',
      render: (cell: string) => <div className={'two-line-text'}>{cell}</div>,
    },
    {
      title: 'Giá gói khách hàng',
      dataIndex: 'chargePrice',
      key: 'chargePrice',
      width: '8%',
      render: (cell: number) => {
        return cell ? `${formatPrice(cell)} VNĐ` : null;
      },
    },
    {
      title: 'Lần chăm sóc cuối',
      dataIndex: 'latestCaringDate',
      key: 'latestCaringDate',
      width: '7%',
      render: (cell: Moment) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    // {
    //   title: 'Ngày chia data',
    //   dataIndex: 'assignedDate',
    //   key: 'assignedDate',
    //   width: '7%',
    //   render: (cell: Moment) => {
    //     return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
    //   },
    // },
    {
      title: 'Ngày phân bố data',
      dataIndex: 'allocatedDate',
      key: 'allocatedDate',
      width: '7%',
      render: (cell: Moment) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Sale phụ trách',
      dataIndex: 'assignedStaff',
      key: 'assignedStaff',
      width: '5%',
      fixed: 'right',
    },
  ];

  const [UIState, setUIState] = React.useState<DataDividedState>({
    loading: false,
    loadingSearch: false,
    loadingGetAllPhones: false,
    isLoadingExport: false,
    loadingReset: false,
    treeTakeCare: [],
    staffs: [],
    divided: [],
    total: 0,
    takeCareSelected: [STATE_TAKE_CARE_OF.processing],
    staffSelected: undefined,
    filters: defaultDividedFilters,
    treeReason: [],
    extendSearch: false,
    defaultTreeReason: [],
  });
  const windowSize = useWindowSize();
  const [updateUI, setUpdateUI] = React.useState<boolean>(false);
  const [selectedMsisdns, setSelectedMsisdns] = React.useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = React.useState<boolean>(false);

  const fetchDataTable = async (body: SearchDataDividedBody) => {
    const { size, page } = UIState.filters;
    const response = await dataManagerServices.getListDataDivided(
      { size, page },
      body
    );
    const { data, headers } = response;
    if (response?.status === 200) {
      setUIState((cur: DataDividedState) => ({
        ...cur,
        divided: data,
        loading: false,
        loadingSearch: false,
        loadingReset: false,
        total: parseFloat(headers['x-total-count']),
      }));
    } else {
      setUIState((cur: DataDividedState) => ({ ...cur, loading: false }));
    }
  };

  const fetchListReason = async () => {
    const resp = await dataManagerServices.getListReason();
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      const transformDataToTree = convertReasonsToTreeData(
        resp?.data?.parentLevelSales
      );
      setUIState((cur) => ({ ...cur, treeReason: transformDataToTree }));
    }
  };

  const fetchDataFilters = async () => {
    const response = await Promise.all([
      reportsServices.getListStaff(),
      dataManagerServices.getFilterStateTakeCareOf(),
    ]);

    const listStaffResponse = response?.[0];
    const listTakeCareResponse = response?.[1];

    if (listStaffResponse?.status === RESPONSE_CODE.SUCCESS) {
      const customerStages = updateTreeDataSelect(
        [STATE_TAKE_CARE_OF.processing],
        listTakeCareResponse?.data
      );

      setUIState((cur: DataDividedState) => ({
        ...cur,
        treeTakeCare: listTakeCareResponse?.data,
        staffs: modifyArrTreeSelect(listStaffResponse?.data),
        filters: {
          ...cur.filters,
          customerStages: customerStages,
        },
        loading: false,
        loadingSearch: true,
      }));
    }
  };

  const exportFile = async (body: SearchDataDividedBody) => {
    const { size, page } = UIState.filters;
    const resp = await dataManagerServices.exportFileDivided(
      { size, page },
      body
    );
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Data_da_chia_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      notification.error({
        message: data?.message,
      });
    }
    setUIState((cur: DataDividedState) => ({
      ...cur,
      isLoadingExport: false,
    }));
  };

  const onSearch = () => {
    const customerStages = updateTreeDataSelect(
      UIState.takeCareSelected,
      UIState.treeTakeCare
    );
    setUIState((cur: DataDividedState) => ({
      ...cur,
      loadingSearch: true,
      filters: { ...cur.filters, customerStages, page: 0 },
    }));
  };

  const onRefresh = () => {
    const customerStages = updateTreeDataSelect(
      UIState.takeCareSelected,
      UIState.treeTakeCare
    );
    setUIState((cur: DataDividedState) => ({
      ...cur,
      loadingSearch: true,
      filters: { ...cur.filters, customerStages },
    }));
    // onClearSelected();
  };

  const onReset = () => {
    const customerStages = updateTreeDataSelect(
      [STATE_TAKE_CARE_OF.processing],
      UIState.treeTakeCare
    );

    setUIState((cur: DataDividedState) => ({
      ...cur,
      loadingSearch: true,
      loadingReset: true,
      filters: { ...defaultDividedFilters, customerStages },
      staffSelected: undefined,
    }));

    window.location.reload();
  };

  const getSelectedPhones = async () => {
    if (isSelectAll) {
      setUIState((cur: DataDividedState) => ({
        ...cur,
        loadingGetAllPhones: true,
      }));
      const response = await dataManagerServices.getAllPhoneNumber(
        UIState.filters
      );
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        setUIState((cur: DataDividedState) => ({
          ...cur,
          loadingGetAllPhones: false,
        }));
        return response?.data;
      } else {
        notification.error({
          message: response?.data?.message,
        });
      }
    } else {
      return selectedMsisdns;
    }
  };

  const onChangeDateDivided = (values: any, formatString: [string, string]) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        assignedDateFrom: values
          ? convertDateToTimeStamp(values[0], true)
          : undefined,
        assignedDateTo: values
          ? convertDateToTimeStamp(values[1], false)
          : undefined,
      },
    }));
  };

  const onChangePhone = (phone: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        msisdn: phone ? phone : undefined,
      },
    }));
  };

  const onChangePackage = (e: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        packActive: e.target.value ? e.target.value.trim() : undefined,
      },
    }));
  };

  const onChangeExpiredDate = (values: any) => {
    const formatStartDate = moment(values?.[0]).format();
    const formatEndDate = moment(values?.[1]).format();

    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        expiredDateFrom: values
          ? moment(formatStartDate).startOf('day').valueOf()
          : undefined,
        expiredDateTo: values
          ? moment(formatEndDate).endOf('day').valueOf()
          : undefined,
      },
    }));
  };

  const onChangeStaff = (value: string[]) => {
    setUIState((cur: DataDividedState) => ({
      ...cur,
      staffSelected: value,
      filters: {
        ...cur.filters,
        assignedStaffs: convertListSaleMan(value, UIState.staffs),
      },
    }));
  };

  const onChangeTakeCare = (value: string[]) => {
    setUIState((cur: DataDividedState) => ({
      ...cur,
      takeCareSelected: value,
    }));
  };

  const onChangeReason = (value: string[]) => {
    const convertData = value.map((item: string) => {
      return item.split('-')[1].split(',');
    });

    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        reasons: _.flatten(convertData),
      },
      defaultTreeReason: value,
    }));
  };

  const onChangeLastCare = (values: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        latestCaringDateFrom: values
          ? values[0].startOf('day').valueOf()
          : undefined,
        latestCaringDateTo: values
          ? values[1].endOf('day').valueOf()
          : undefined,
      },
    }));
  };

  const onChangeAllocated = (values: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        allocatedDateFrom: values
          ? values[0].startOf('day').valueOf()
          : undefined,
        allocatedDateTo: values ? values[1].endOf('day').valueOf() : undefined,
      },
    }));
  };

  const onClearTakeCare = () => {
    setUpdateUI(!updateUI);
  };

  const onExport = async () => {
    const customerStages = updateTreeDataSelect(
      UIState.takeCareSelected,
      UIState.treeTakeCare
    );
    setUIState((cur: DataDividedState) => ({
      ...cur,
      isLoadingExport: true,
      filters: { ...cur.filters, customerStages, page: 0 },
    }));
  };

  const onChangeSourceSys = async (value: string) => {
    setUIState((cur: DataDividedState) => ({
      ...cur,
      filters: {
        ...cur.filters,
        sourceSys: value ? value : undefined,
      },
    }));
  };

  const onExtend = () => {
    setUIState((cur) => ({ ...cur, extendSearch: !cur.extendSearch }));
  };

  const onSizeChange = (page: number, pageSize: number) => {
    const newPage = page - 1 > 0 ? page - 1 : 0;
    setUIState((cur: DataDividedState) => ({
      ...cur,
      loadingSearch: true,
      filters: {
        ...cur.filters,
        page: newPage,
        size: pageSize,
      },
    }));
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataDividedItem[]
  ) => {
    if (isSelectAll) {
      setIsSelectAll(false);
    }
    setSelectedMsisdns(selectedRows.map((item) => item.msisdn));
  };

  const onSelectAll = (isTickAll: boolean) => {
    setIsSelectAll(isTickAll);
    if (UIState.total > MAX_RECORDS_SUPPORT && isTickAll) {
      notification.warn({
        message: 'Hệ thống hỗ trợ tích chọn tối đa 3000 data',
        style: { marginTop: '20px' },
      });
    }
  };

  useEffect(() => {
    if (UIState.isLoadingExport) {
      exportFile(UIState.filters);
    }
  }, [UIState.isLoadingExport]);

  useEffect(() => {
    if (UIState.loadingSearch) {
      fetchDataTable(UIState.filters);
    }
  }, [UIState.loadingSearch]);

  useEffect(() => {
    Promise.all([fetchDataFilters(), fetchListReason()]);
  }, []);

  const rowSelection =
    isHavePermissionNew(PERMISSIONS.DATA_DIVIDED_RECALL) ||
    isHavePermissionNew(PERMISSIONS.DATA_DIVIDED_MOVE)
      ? {
          selectedMsisdns,
          columnWidth: '2%',
          preserveSelectedRowKeys: false,
          onChange: onSelectChange,
          onSelectAll,
          fixed: 'left',
        }
      : undefined;

  return (
    <Spin
      spinning={
        UIState.loading || UIState.loadingSearch || UIState.loadingGetAllPhones
      }
      tip={'Loading...'}
    >
      <Helmet>
        <title>Data đã chia</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <DataDividedFilter
        dataDividedState={UIState}
        extendSearch={UIState.extendSearch}
        isLoadingExport={UIState.isLoadingExport}
        loadingReset={UIState.loadingReset}
        selectedMsisdns={selectedMsisdns}
        onChangeDateDivided={onChangeDateDivided}
        onChangeExpiredDate={onChangeExpiredDate}
        getSelectedPhones={getSelectedPhones}
        onChangeSourceSys={onChangeSourceSys}
        onChangeTakeCare={onChangeTakeCare}
        onClearTakeCare={onClearTakeCare}
        onChangePackage={onChangePackage}
        onChangeStaff={onChangeStaff}
        onChangePhone={onChangePhone}
        onRefresh={onRefresh}
        onKeyDown={onKeyDown}
        onExtend={onExtend}
        onSearch={onSearch}
        onExport={onExport}
        onReset={onReset}
        onChangeReason={onChangeReason}
        onChangeLastCare={onChangeLastCare}
        onChangeAllocated={onChangeAllocated}
      />
      <Table
        rowKey={'id'}
        rowSelection={rowSelection as any}
        dataSource={UIState?.divided}
        columns={COLUMNS as any}
        pagination={false}
        scroll={{
          y: UIState.extendSearch
            ? windowSize?.width < 1700 && windowSize?.height < 1000
              ? '56vh'
              : '50vh'
            : windowSize?.width < 1700 && windowSize?.height < 1000
            ? '76vh'
            : '82vh',
          x: 1900,
        }}
      />
      <Pagination
        size={'small'}
        showSizeChanger
        style={paginationStyles}
        onChange={onSizeChange}
        onShowSizeChange={onSizeChange}
        total={UIState.total}
        defaultCurrent={UIState?.filters?.page + 1}
        defaultPageSize={UIState?.filters?.size}
        pageSizeOptions={PAGE_SIZE_LIST}
        showTotal={(total) => `Tổng số bản ghi ${total}`}
      />
    </Spin>
  );
};

export default DataDivided;

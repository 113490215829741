import React, { useEffect, useState, useCallback } from 'react';
import {
  CheckCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Button, Modal, notification, Table } from 'antd';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { saveAs } from 'file-saver';
import MBN_Template_Import from '../../../assets/MBN_Template_Import.xlsx';
import { useNavigate } from 'react-router-dom';
import { useQuery, useWindowSize } from 'src/utils/customHooks';
import {
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
  PERMISSIONS,
} from 'src/utils/constants';
import dataManagerServices from 'src/services/dataManager.service';
import {
  isHavePermissionNew,
  updateQueryStringParameter,
} from 'src/utils/stringUtils';
import { projectName } from 'src/utils';

import './style.scss';

function ImportData() {
  const navigate = useNavigate();
  const query = useQuery();
  const windowSize = useWindowSize();

  const page = query.get('page') ? parseFloat(query.get('page')) : 1;
  let pageSize = query.get('size')
    ? parseFloat(query.get('size'))
    : DEFAULT_PAGE_SIZE;

  const [dataList, setDataList] = useState([]);
  const [fileObj, setFileObj] = useState();
  // const [fileSource, setFileSource] = useState();
  const [fileUploading, setFileUploading] = useState(false);
  const [metadata, setMetadata] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      setFileObj(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'text/csv': ['.csv'],
    },
  });

  useEffect(() => {
    getListData();
  }, [page, pageSize]);

  const getListData = async () => {
    let query = buildQueryString();
    const resp = await dataManagerServices.getListImport(query);
    const data = resp?.data;
    const headers = resp?.headers;
    if (resp?.status === 200) {
      setDataList(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    }
    // else {
    //   notification.error({
    //     message: resp?.data,
    //   });
    // }
  };

  const getImportResult = async (batchId) => {
    const resp = await dataManagerServices.getImportResult(batchId);
    const data = resp?.data;
    if (resp?.status === 200) {
      setTimeout(() => {
        getListData();
        let config = {
          title: `Kết quả Import`,
          icon: <CheckCircleOutlined className="txt-green" />,
          content: (
            <div>
              <div className="">
                <span className="dpl-inline-block width-150">
                  Tổng số bản ghi:
                </span>
                <span className="">{data?.totalRecord.toLocaleString()}</span>
              </div>
              <div className="txt-blue">
                <span className="dpl-inline-block width-150">
                  Số bản ghi thành công:
                </span>
                <span className="">
                  {data?.totalSuccessRecord.toLocaleString()}
                </span>
              </div>
              <div className="txt-warning">
                <span className="dpl-inline-block width-150">
                  Số bản ghi lỗi:
                </span>
                <span className="">
                  {data?.totalErrorRecord.toLocaleString()}
                </span>
              </div>
            </div>
          ),
          cancelText: 'Đóng',
          okButtonProps: {
            className: 'dpl-none',
          },
        };

        if (data?.totalErrorRecord > 0) {
          config.okButtonProps = {
            className: '',
          };
          config.okText = 'Tải danh sách lỗi';
          config.onOk = () => {
            getDetailErrorFile(data?.batchImportId);
            console.log('data:', data?.batchImportId);
          };
        }
        Modal.confirm(config);
        setFileUploading(false);
        setFileObj(null);
        // setFileSource(null);
      }, 1000);
    } else {
      setFileUploading(false);
      notification.error({
        message: data?.message,
      });
    }
  };

  const onImportData = async () => {
    setFileUploading(true);
    if (fileObj) {
      const resp = await dataManagerServices.importData(fileObj);
      const data = resp?.data;
      if (resp?.status === 200) {
        setTimeout(() => {
          getImportResult(data);
        }, 5000);
      } else {
        notification.error({
          message: data?.message ?? 'Import Data thất bại',
        });
        setFileUploading(false);
      }
    } else {
      setFileUploading(false);
      notification.error({
        message: 'Cần chọn file để thao tác',
      });
    }
  };

  const getDetailErrorFile = async (item) => {
    const resp = await dataManagerServices.getListError(item);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `CRM_Callout_Data_Error_${moment(
        item.createdDate
      ).format('YYYYMMDD')}_${moment(item.createdDate).unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      notification.error({
        message: resp?.data,
      });
    }
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  const buildQueryString = (data = {}, isUrl) => {
    let queryString = '';
    const dataValue = {
      page,
      pageSize,
      ...data,
    };
    if (isUrl) {
      if (dataValue.page && dataValue.page > 1) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          dataValue.page
        );
      }

      if (dataValue.pageSize && dataValue.pageSize !== DEFAULT_PAGE_SIZE) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    } else {
      if (dataValue.page && dataValue.page > 0) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          parseFloat(dataValue.page) - 1
        );
      }

      if (dataValue.pageSize) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    }

    return queryString;
  };

  const onPageChange = (curPage, size) => {
    let query = buildQueryString({ page: curPage, pageSize: size }, true);
    navigate(`/data-import${query || ''}`);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return (page - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY HH:mm') : '---';
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Tổng số data ',
      dataIndex: 'totalRecord',
      key: 'totalRecord',
      render: (cell) => {
        return cell ? cell.toLocaleString() : cell;
      },
    },
    {
      title: ' Số data thành công',
      dataIndex: 'totalSuccessRecord',
      key: 'totalSuccessRecord',
      render: (cell) => {
        return cell ? cell.toLocaleString() : cell;
      },
    },
    {
      title: 'Số data lỗi',
      dataIndex: 'totalErrorRecord',
      key: 'totalErrorRecord',
      hidden: !isHavePermissionNew(PERMISSIONS.DATA_IMPORT),
      render: (cell, record) => {
        return cell ? (
          <div className="dpl-flex">
            <div style={{ width: 50 }}>
              {cell ? cell.toLocaleString() : cell}
            </div>

            <div
              onClick={() => {
                getDetailErrorFile(record?.id);
              }}
              className="txt-blue cursor-pointer pdl-20"
            >
              Tải danh sách lỗi
            </div>
          </div>
        ) : (
          '---'
        );
      },
    },
  ].filter((item) => !item.hidden);

  let tableHeight =
    windowSize?.height - GLOBAL_HEIGHT - (fileObj ? 104 : 20) + 10;
  return (
    <div id={'import-data'}>
      <Helmet>
        <title>Import Data</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <div className={'import-data-header'}>
        <div className="btn-import-container">
          <div className="btn-import-excel" {...getRootProps()}>
            <input {...getInputProps()} />
            {!fileObj && (
              <>
                {isHavePermissionNew(PERMISSIONS.DATA_IMPORT) && (
                  <div className={'btn-upload-file'}>
                    <UploadOutlined
                      className="pdr-5"
                      style={{ fontSize: 17 }}
                    />
                    {/* <FontAwesomeIcon className="pdr-5" icon={Icon.faPaperclip} /> */}
                    Tải lên file Excel
                  </div>
                )}
              </>
            )}
          </div>
          {fileObj && (
            <div className="file-container">
              <div className="file-info">
                <a {...getRootProps()}>{fileObj?.name}</a>
                <FontAwesomeIcon
                  onClick={() => setFileObj(null)}
                  className="btn-remove-excel-file"
                  icon={Icon.faCircleXmark}
                />
              </div>

              {/* <Select
                allowClear
                showSearch
                style={filterBoxStyle}
                optionFilterProp="children"
                placeholder={'Nguồn data'}
                value={fileSource}
                onChange={(value) => setFileSource(value)}
                options={DATA_SOURCE}
              /> */}

              <Button
                className="mgl-10"
                loading={fileUploading}
                icon={<UploadOutlined />}
                onClick={onImportData}
                type="primary"
              >
                Import
              </Button>
            </div>
          )}
        </div>
        {isHavePermissionNew(PERMISSIONS.DATA_IMPORT) && (
          <div className={'btn-download-file-example'}>
            <DownloadOutlined style={{ fontSize: '1vw', color: '#1677FF' }} />
            <a
              href={MBN_Template_Import}
              download="MBN_Template_Import.xlsx"
              className="txt-scale-medium"
              style={{ marginLeft: 4, color: '#1677FF' }}
            >
              Tải xuống file mẫu
            </a>
          </div>
        )}
      </div>

      <Table
        rowKey={'id'}
        dataSource={dataList}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: page,
          total: metadata.total,
          defaultPageSize: pageSize,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default ImportData;

import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import SelectSaleMan from 'src/components/SelectSaleMan';
import {
  boxFilterStyle,
  filterBoxStyle,
  inputStyle,
  itemStyle,
} from 'src/styles';
import { DATE_FORMAT, PERMISSIONS } from 'src/utils/constants';
import { isHavePermissionNew } from 'src/utils/stringUtils';
import '../style.scss';
import { contentBox, txtTitle } from 'src/styles';
import FiltersButton from 'src/components/FiltersButton';

interface DataFilter {
  assignedStaffs?: string[] | undefined;
  callingStatus?: string | undefined;
  callingTimeEnd?: number | undefined;
  callingTimeStart?: number | undefined;
  callingType?: string | undefined;
  endingStatus?: string | undefined;
  page: number;
  size: number;
  staffs?: string[];
  msisdn?: string | undefined;
}

type Props = {
  size: any;
  defaultData: DataFilter;
  staffs: any;
  isLoadingExport: boolean;
  onSearch: () => void;
  onExport: () => void;
  onKeyDown: (e: any) => void;
  onChangePhone: (phone: any) => void;
  onChangeCallType: (value: string) => void;
  onChangeCallStatus: (value: string) => void;
  onChangeCallEndType: (value: string) => void;
  onChangeDateCall: (values: any, formatString: [string, string]) => void;
  onChangeStaff: (value: string[]) => void;
  onReset: () => void;
  isExtended: boolean;
  handleExtendFilter: () => void;
  loadingReset: boolean;
};

const CALLING_TYPE = [
  { label: 'Cuộc gọi đến', value: 'incoming' },
  { label: 'Cuộc gọi đi', value: 'outgoing' },
  { label: 'Cuộc gọi nhỡ', value: 'missed' },
];

const CALLING_STATUS = [
  { label: 'Nghe máy', value: 'answered' },
  { label: 'Không nghe máy', value: 'unanswered' },
];

const CALL_END_STATUS = [
  { label: 'Khách hàng', value: 'customer' },
  { label: 'Sale', value: 'sale' },
  { label: 'Nhỡ do không bắt máy', value: 'missed' },
];

const CallCenterFilters: FC<Props> = ({
  size,
  staffs,
  isLoadingExport,
  onSearch,
  onExport,
  onKeyDown,
  onChangePhone,
  onChangeCallType,
  onChangeCallStatus,
  onChangeCallEndType,
  onChangeDateCall,
  onChangeStaff,
  onReset,
  defaultData,
  isExtended,
  handleExtendFilter,
  loadingReset,
}) => {
  if (loadingReset) return <div style={{ height: 200 }} />;
  return (
    <div className="p-2 px-4">
      <div className="flex justify-between">
        <div className="flex justify-between items-center">
          <InputNumber
            size={size}
            prefix={<SearchOutlined className="pr-2" />}
            placeholder={'Nhập Số điện thoại...'}
            controls={false}
            style={{
              width: '20vw',
              borderRadius: 0,
              ...boxFilterStyle,
            }}
            value={defaultData.msisdn ?? ''}
            onChange={onChangePhone}
            onKeyDown={onKeyDown}
          />
          {isHavePermissionNew(PERMISSIONS.ADD_ON_CALL_HISTORY) && (
            <>
              {!isExtended && (
                <div>
                  <Button
                    type="primary"
                    onClick={handleExtendFilter}
                    className="ml-4"
                  >
                    Bộ lọc
                  </Button>
                  <span
                    style={{
                      color: 'rgba(0, 0, 0, 0.5)',
                      marginLeft: 10,
                    }}
                  >
                    Lọc theo trường: Ngày gọi:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {moment().startOf('month').format(DATE_FORMAT)} -{' '}
                      {moment()
                        .subtract(0, 'days')
                        .endOf('day')
                        .format(DATE_FORMAT)}
                    </span>
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {isHavePermissionNew(PERMISSIONS.ADD_ON_CALL_HISTORY_EXPORT) && (
          <Button
            className="btn-export"
            onClick={onExport}
            type="default"
            size={size}
            icon={<DownloadOutlined />}
            loading={isLoadingExport}
          >
            Export
          </Button>
        )}
      </div>
      {isExtended && (
        <div className={isExtended ? 'mt-5' : 'set-height-0px'}>
          <Row gutter={24}>
            <Col span={6}>
              <div style={contentBox}>
                <p style={txtTitle}>Loại cuộc gọi</p>
                <Select
                  allowClear
                  showSearch
                  size={size}
                  style={filterBoxStyle}
                  placeholder="Loại cuộc gọi"
                  onChange={onChangeCallType}
                  options={CALLING_TYPE}
                  defaultValue={defaultData.callingType}
                />
              </div>
              <div style={contentBox}>
                <p style={txtTitle}>Saleman</p>
                <SelectSaleMan
                  style={itemStyle}
                  size={size}
                  treeData={staffs}
                  onChange={onChangeStaff}
                  placeholder={'Sale'}
                  defaultValue={defaultData.assignedStaffs}
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={contentBox}>
                <p style={{ ...txtTitle, width: '9vw' }}>Trạng thái thoại</p>
                <Select
                  allowClear
                  showSearch
                  size={size}
                  style={itemStyle}
                  placeholder="Trạng thái thoại"
                  onChange={onChangeCallStatus}
                  options={CALLING_STATUS}
                  defaultValue={defaultData.callingStatus}
                />
              </div>
              <div style={contentBox}>
                <p style={{ ...txtTitle, width: '10vw' }}>
                  Trạng thái kết thúc
                </p>
                <Select
                  allowClear
                  showSearch
                  placeholder="Trạng thái kết thúc"
                  style={itemStyle}
                  size={size}
                  options={CALL_END_STATUS}
                  onChange={onChangeCallEndType}
                  defaultValue={defaultData.endingStatus}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={contentBox}>
                <p style={{ ...txtTitle, width: '5vw' }}>Ngày gọi</p>
                <DatePicker.RangePicker
                  allowClear
                  size={size}
                  onChange={onChangeDateCall}
                  placeholder={['Ngày gọi', '']}
                  format={DATE_FORMAT}
                  defaultValue={[
                    moment().startOf('month').startOf('day'),
                    moment().endOf('day'),
                  ]}
                />
              </div>
            </Col>
          </Row>
          <FiltersButton
            style={{ marginTop: 10 }}
            size={size}
            onSearch={onSearch}
            onExtend={handleExtendFilter}
            onReset={onReset}
          />
        </div>
      )}
    </div>
  );
};

export default CallCenterFilters;

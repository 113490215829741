import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import userServices from '../../services/users.service';
import { REGEX_PASSWORD, RESPONSE_CODE } from '../../utils/constants';
import LocalStorage from '../../utils/localStorage';

function ChangePassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    const resp = await userServices.changePassword(values);
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      notification.success({
        message: 'Thay đổi mật khẩu thành công.',
        description: 'Vui lòng đăng nhập lại.',
      });
      LocalStorage.getInstance().save('loginSession', null);
      navigate('/');
    } else {
      message.error(resp?.data?.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="text-center txt-blue pdbt-20">Đổi mật khẩu</h1>
        <Form
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size={'large'}
        >
          <Form.Item
            name="username"
            validateTrigger={'onBlur'}
            rules={[{ required: true, message: 'Vui lòng nhập tài khoản!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon pdr-5" />}
              placeholder="Nhập tài khoản"
            />
          </Form.Item>
          <Form.Item
            name="oldPassword"
            validateTrigger={'onBlur'}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu cũ!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon pdr-5" />}
              placeholder="Nhập mật khẩu cũ"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            validateTrigger={'onBlur'}
            rules={[
              {
                required: true,
                pattern: new RegExp(REGEX_PASSWORD),
                message:
                  'Mật khẩu mới có ít nhất 8 ký tự bao gồm: tối thiểu 1 chữ cái, 1 số.',
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon pdr-5" />}
              placeholder="Nhập mật khẩu mới"
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            validateTrigger={'onBlur'}
            rules={[
              { required: true, message: 'Vui lòng nhập lại mật khẩu mới!' },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon pdr-5" />}
              placeholder="Nhập lại mật khẩu mới"
            />
          </Form.Item>

          <div className="text-center pdt-20">
            <Button
              type="primary"
              className="btn-custom mg-auto"
              htmlType="submit"
            >
              Tiếp tục
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ChangePassword;

import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Modal, Row, Tag } from 'antd';
import Typography from 'antd/lib/typography/Typography';
import moment from 'moment';
import React, { Ref, useImperativeHandle } from 'react';
import { useWindowSize } from 'src/utils/customHooks';

type Props = {
  handleEdit: (data?: PermissionInterface | undefined) => void;
};
const DetailsPermission = ({ handleEdit }: Props, ref: Ref<any>) => {
  const windowSize = useWindowSize();
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<PermissionInterface>();
  const [collapseActiveKey, setCollapseActiveKey] = React.useState([]);

  const handleOpen = (details: PermissionInterface) => {
    setDetails(details);
    form?.setFieldsValue({
      title: details?.title,
      description: details?.description,
      modules: details?.modules,
      subModules: details?.modules?.map((item) => ({
        subModules: item.subModules,
      })),
    });
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
    setCollapseActiveKey([]);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: handleOpen,
        close: handleClose,
      };
    },
    []
  );

  const handleCollapseChange = (active: any) => {
    setCollapseActiveKey(active);
  };

  const renderStatus = (enable: boolean) => {
    switch (enable) {
      case false:
        return (
          <Tag color="red" className="w-[50px] items-center text-center">
            No
          </Tag>
        );
      case true:
        return (
          <Tag color="blue" className="w-[50px] items-center text-center">
            Yes
          </Tag>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={handleClose}
      centered
      footer={
        <div className="mb-3">
          <Button
            form="formDetail"
            key="submit"
            htmlType="submit"
            type="primary"
            onClick={handleEdit?.bind(null, details)}
          >
            Sửa
          </Button>
          <Button className="mr-3" onClick={handleClose}>
            Đóng
          </Button>
        </div>
      }
      width={800}
      bodyStyle={{ height: windowSize?.height - 70, overflowY: 'hidden' }}
    >
      <Form form={form} layout="vertical" id="formDetail">
        <div className="flex items-center justify-center mb-5">
          <Typography className="font-semibold" style={{ fontSize: 20 }}>
            DANH SÁCH QUYỀN
          </Typography>
        </div>
        <Form.Item
          name={'title'}
          label={
            <Typography className="font-semibold">*Tên nhóm quyền:</Typography>
          }
        >
          <Input placeholder="Tên nhóm quyền" disabled />
        </Form.Item>
        <Form.Item
          label={<Typography className="font-semibold">Mô tả:</Typography>}
          name={'description'}
          style={{ marginBottom: 10 }}
        >
          <Input.TextArea
            placeholder="Mô tả nhóm quyền"
            style={{ height: 120 }}
            disabled
          />
        </Form.Item>
        <div className="mt-3" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <Form.List name="modules">
            {(modulesFields, {}) => (
              <>
                {modulesFields?.map(
                  ({ key: modulesKey, name: modulesName }) => (
                    <Collapse
                      key={modulesKey}
                      accordion
                      expandIcon={({ isActive }) => (
                        <div className="">
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        </div>
                      )}
                      bordered={false}
                      style={{
                        background: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        borderTop: modulesKey != 0 ? '1px solid #f2f2f2' : '',
                      }}
                      activeKey={collapseActiveKey}
                      onChange={handleCollapseChange}
                    >
                      <Collapse.Panel
                        header={
                          <Typography className="font-semibold">
                            {form.getFieldValue([
                              'modules',
                              modulesKey,
                              'name',
                            ])}
                          </Typography>
                        }
                        key={modulesKey}
                      >
                        <Form.List name={[modulesName, 'subModules']}>
                          {(subModulesFields, {}) => (
                            <>
                              {subModulesFields?.map(
                                ({ key: subModulesKey }) => (
                                  <div
                                    key={subModulesKey}
                                    className={`flex items-center justify-between ml-10 mr-10 ${
                                      subModulesKey != 0 && 'mt-3'
                                    }`}
                                  >
                                    <Typography>
                                      {form.getFieldValue([
                                        'subModules',
                                        modulesName,
                                        'subModules',
                                        subModulesKey,
                                        'name',
                                      ])}
                                    </Typography>
                                    {renderStatus(
                                      form.getFieldValue([
                                        'subModules',
                                        modulesName,
                                        'subModules',
                                        subModulesKey,
                                        'enable',
                                      ])
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          )}
                        </Form.List>
                      </Collapse.Panel>
                    </Collapse>
                  )
                )}
              </>
            )}
          </Form.List>
        </div>
        <Row gutter={20} className="mt-5">
          <Col span={24}>
            <Typography className="italic text-[12px]">
              * Chỉnh sửa gần nhất bởi {details?.modifiedBy} ngày{' '}
              {moment(details?.modifiedDate).format('DD/MM/YYYY | HH:mm:ss')}
            </Typography>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export const ModalDetailsPermission = React.forwardRef(DetailsPermission);

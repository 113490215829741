import { onLogout } from '.';
import { RESPONSE_CODE } from './constants';
import { LOCAL_STORAGE_VALUE } from './enum';
import LocalStorage from './localStorage';

const axios = require('axios').default;

const AccessTokenInterceptor = {
  addAccessToken: (config: any) => {
    const loginSession = LocalStorage.getInstance().read(
      LOCAL_STORAGE_VALUE.loginSession
    );
    const token = loginSession?.token;
    if (token) {
      const headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
      config.headers = headers;
    }
    return config;
  },
  onRejected: (error: any) => {
    console.log('onRejected ', error);
    return Promise.resolve(error);
  },
};

const RequestResponseInterceptor = {
  request: (response: any) => {
    return response;
  },
  requestErr: (error: any) => {
    if (
      error?.response?.status === RESPONSE_CODE.UNAUTHORIZED &&
      error?.response?.data?.errorCode === 'need_update_password'
    ) {
      onLogout();
      return Promise.reject(error?.response);
    }
    return error?.response;
  },
};

class APIService {
  instance: any;
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    this.instance.interceptors.request.use(
      AccessTokenInterceptor.addAccessToken,
      AccessTokenInterceptor.onRejected
    );
    this.instance.interceptors.response.use(
      RequestResponseInterceptor.request,
      RequestResponseInterceptor.requestErr
    );
  }

  async request(method: string, path: string, data = {}, config = {}) {
    return await this.instance.request({
      method,
      url: path,
      data,
      ...config,
    });
  }
}

export default APIService;

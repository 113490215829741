import React, { useContext } from 'react';
import { Button, Tooltip } from 'antd';

import { ReactComponent as IconCalling } from 'src/assets/dataDivided/calling.svg';
import { ReactComponent as PhoneWaves } from 'src/assets/dataDivided/phoneWaves.svg';
import { ReactComponent as IconMute } from 'src/assets/dataDivided/mute.svg';
import CountUp from '../CountUp';

import AppContext from 'src/context/AppContext';
import { CALL_OUT_ACTION } from 'src/hooks/useStringee';
import stringeeServices from 'src/services/stringee/stringee.service';
import './style.scss';

type Props = {
  onReloadData?: () => void;
  className?: string;
};

const BoxCallToCustomer: React.FC<Props> = ({ onReloadData, className }) => {
  const { calloutState, calloutDispatch } = useContext(AppContext);

  const onEndCall = () => {
    stringeeServices.endCall();
    onReloadData?.();
  };

  const onMute = () => {
    calloutDispatch({
      type: CALL_OUT_ACTION.updateMute,
      status: calloutState?.mute,
    });
  };

  const onHold = () => {
    if (calloutState?.holdCall) {
      calloutDispatch({
        type: CALL_OUT_ACTION.unHoldCall,
      });
    } else {
      calloutDispatch({
        type: CALL_OUT_ACTION.holdCall,
      });
    }
  };

  if (calloutState?.callStatus === 'holding') {
    return (
      <div id={'box-call-to-customer'} className={className}>
        <p className={'txt-normal'}>Cuộc gọi đi: </p>
        <p className={'txt-normal blue bold-500 pdl-10'}>Đang kết nối</p>
        <Button className="btn-turn-off" onClick={onEndCall}>
          Kết thúc
        </Button>
      </div>
    );
  }
  if (calloutState?.callStatus === 'calling') {
    return (
      <div id={'box-call-to-customer'} className={className}>
        <p className={'txt-normal'}>
          Cuộc gọi {calloutState?.isCallingFromCustomer ? 'đến' : 'đi'}:{' '}
        </p>
        <CountUp />
        <PhoneWaves style={{ marginLeft: 12 }} />
        <Tooltip title={calloutState?.mute ? 'Bật tiếng' : 'Tắt tiếng'}>
          <Button
            icon={
              <IconMute
                fill={calloutState?.mute ? '#277CBE' : '#000000'}
                stroke={calloutState?.mute ? '#277CBE' : '#000000'}
              />
            }
            onClick={onMute}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>

        <Tooltip
          title={calloutState?.holdCall ? 'Tiếp tục cuộc gọi' : 'Giữ cuộc gọi'}
        >
          <Button
            icon={
              <IconCalling
                fill={calloutState?.holdCall ? '#277CBE' : '#000000'}
                stroke={calloutState?.holdCall ? '#277CBE' : '#000000'}
              />
            }
            onClick={onHold}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>

        <Button className="btn-turn-off" onClick={onEndCall}>
          Kết thúc
        </Button>
      </div>
    );
  }
  return null;
};

export { BoxCallToCustomer };

/* eslint-disable react/prop-types */
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useState } from 'react';
import { DEFAULT, ERROR, STATUS_SUCCESS, SUCCESS } from 'src/constants';
import registerPackageServices from 'src/services/registerPackage.service';
import './style.scss';

type Props = {
  title?: string;
  isModalOpen?: boolean;
  phone?: string;
  dataId?: number;
  topUpValues?: any;
  handleOk?: () => void;
  handleCancel?: () => void;
  callBackUpdate?: () => void;
};

const ModalTopUp: React.FC<Props> = ({
  isModalOpen,
  phone,
  dataId,
  topUpValues,
  handleOk,
  handleCancel,
  callBackUpdate,
}) => {
  const [loadingTopUp, setLoadingTopUp] = useState<boolean>(false);
  const [viewType, setViewType] = useState<string>(DEFAULT);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  console.log('topUpValues', topUpValues);
  const handleConfirmTopUp = async () => {
    setLoadingTopUp(true);
    const response = await registerPackageServices.topUp(topUpValues, dataId);
    const { status } = response;
    if (status === STATUS_SUCCESS) {
      setViewType(SUCCESS);
    } else {
      setViewType(ERROR);
      setErrorMsg(response?.data?.message);
    }
    setLoadingTopUp(false);
  };

  const viewDefault = () => (
    <>
      <div>
        <Row>
          <Col xl={4} lg={4} md={4} sm={4} xs={4}></Col>
          <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <p style={{ fontSize: 16 }}>Số điện thoại:</p>
            <p style={{ fontSize: 16 }}>Số tiền nạp:</p>
          </Col>
          <Col xl={10} lg={10} md={10} sm={10} xs={10}>
            <Typography.Title level={5}>{phone}</Typography.Title>
            <Typography.Title level={5}>
              {Number(topUpValues?.amount).toLocaleString('de-DE')} VNĐ
            </Typography.Title>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loadingTopUp}
          onClick={handleConfirmTopUp}
        >
          Đồng ý
        </Button>
      </div>
    </>
  );

  const resetState = () => {
    setViewType(DEFAULT);
    setErrorMsg(null);
    setLoadingTopUp(false);
  };

  const viewSuccess = () => (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <p style={{ textAlign: 'center' }}>{'Nạp thẻ thành công'}</p>
      <Button
        onClick={() => {
          resetState();
          callBackUpdate?.();
          handleCancel?.();
        }}
      >
        Đóng
      </Button>
    </div>
  );

  const viewError = () => (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <p style={{ textAlign: 'center' }}>
        {errorMsg ? errorMsg : 'Đã xảy ra lỗi, Vui lòng thử lại sau.'}
      </p>
      <Button
        onClick={() => {
          resetState();
          handleCancel?.();
        }}
      >
        Đóng
      </Button>
    </div>
  );

  const views: any = {
    DEFAULT: { title: 'Xác nhận nạp thẻ', view: viewDefault() },
    SUCCESS: { title: 'Nạp thẻ thành công', view: viewSuccess() },
    ERROR: { title: 'Nạp thẻ thất bại', view: viewError() },
  };

  return (
    <Modal
      open={isModalOpen}
      width={420}
      title={<h3 className="title-cs">{views[viewType].title}</h3>}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={viewType === DEFAULT}
      maskClosable={false}
    >
      {views[viewType].view}
    </Modal>
  );
};

export default ModalTopUp;

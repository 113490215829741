import { scriptCallEvent } from './script';
import { StringeeClient, StringeeCall } from 'stringee';

class StringeeServices {
  addScript = (text: string) => {
    const script = document.createElement('script');
    script.text = text;
    document.body.appendChild(script);
  };

  addCaptureVideo = (id: string) => {
    const video = document.createElement('video');
    video.autoplay = true;
    video.id = id;
    video.style.display = 'none';
    document.body.appendChild(video);
  };

  async addScripts() {
    this.addScript(scriptCallEvent);
    this.addCaptureVideo('remoteVideo');
  }

  async init() {
    await this.addScripts();
    window?.newStringeeClient(new StringeeClient());
  }

  connect(accessToken: string) {
    console.log('connect ', accessToken);
    // const token =
    //   'eyJjdHkiOiJzdHJpbmdlZS1hcGk7dj0xIiwidHlwIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiJTSy4wLlNYeGk0QUd2aWsxRnBRV1dFQm1NeG9WTkozczVFMFQtMTcwNTkxMDk3MSIsImlzcyI6IlNLLjAuU1h4aTRBR3ZpazFGcFFXV0VCbU14b1ZOSjNzNUUwVCIsImV4cCI6MTcwNTk5NzM3MSwidXNlcklkIjoiMTExIiwiaWNjX2FwaSI6dHJ1ZX0.A3JMRJij9RwzcgbUcTk2qKgXqYW8gAHbbUPY5vD3aaY';
    window?.connectStringee(accessToken);
    window?.hangup();
  }

  makeACall(toPhone: string, fromPhone: string) {
    console.log(`makeACall ${toPhone} - ${fromPhone}`);
    window?.makeACall(toPhone.toString(), fromPhone.toString(), StringeeCall);
  }

  endCall() {
    window?.hangup();
  }

  rejectCall() {
    window?.rejectCall();
    window?.hangup();
  }

  onAcceptCall() {
    window?.answerCall();
  }
}

const stringeeServices = new StringeeServices();
export default stringeeServices;

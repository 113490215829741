import { Tag } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

const getColumnsConfig: (
  handleShowDetail: (data: any) => void,
  page: number,
  pageSize: number
) => any[] = (handleShowDetail, page, pageSize) => {
  const columns: ColumnType<any>[] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: '2%',
      render: (_, __, indx) => {
        return page * pageSize + indx + 1;
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: '5%',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      width: '5%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (cell: any) => (
        <Tag color={cell === 0 ? 'green' : 'volcano'}>
          {cell === 0 ? 'Đang hoạt động' : 'Ngừng hoạt động'}
        </Tag>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return (
          moment(record.createdDate).format('DD/MM/YYYY | HH:mm:ss') || '-'
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Tag
            onClick={handleShowDetail?.bind(null, record)}
            className="cursor-pointer"
          >
            Xem chi tiết
          </Tag>
        );
      },
      width: '5%',
    },
  ];
  return columns;
};

export default getColumnsConfig;

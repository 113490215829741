import { Row } from 'antd';

const header_data = [
  {
    name: 'TEAM',
    styles:
      'w-[calc(35%)] items-center justify-center text-center text-[12px] font-bold text-[#fff]',
  },
  {
    name: 'MEMBERS',
    styles:
      'w-[calc(17%)] items-center justify-center text-center text-[12px] font-bold text-[#fff]',
  },
  // {
  //   name: 'TK QUẢN LÝ',
  //   styles:
  // //     'w-[calc(15%)] items-center justify-center text-center text-[12px] font-bold',
  // // },
  // {
  //   name: 'SL ĐẠI LÝ',
  //   styles:
  //     'w-[calc(21%)] items-center justify-center text-center text-[12px] font-bold',
  // },
  // {
  //   name: 'SL CTV',
  //   styles:
  //     'w-[calc(8%)] items-center justify-center text-center text-[12px] font-bold',
  // },
  {
    name: 'CẬP NHẬT',
    styles:
      'w-[calc(26%)] items-center justify-center text-center text-[12px] font-bold text-[#fff]',
  },
  {
    name: 'THAO TÁC',
    styles:
      'w-[calc(19%)] items-center justify-center text-center text-[12px] font-bold text-[#fff]',
  },
];

export const Header = () => {
  return (
    <Row
      className={
        'bg-[#1677ff] pt-4 pb-4 border-solid border border-[#DDDDDD] rounded-t-md'
      }
    >
      {header_data?.map((item, index) => (
        <div key={index} className={item?.styles}>
          {item?.name}
        </div>
      ))}
    </Row>
  );
};

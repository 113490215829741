import { FC, useContext, useEffect, useState } from 'react';
import { BoxCallToCustomer } from '../DataManager/DataDividedDetail/components/Info/components';
import { Button, InputNumber, notification } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import PingColor from '../DataManager/DataDividedDetail/components/Info/components/PingColor';
import AppContext from 'src/context/AppContext';
import LocalStoreInstance from 'src/utils/localStorage';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import stringeeServices from 'src/services/stringee/stringee.service';
import { CALL_OUT_ACTION } from 'src/hooks/useStringee';
import callCenterService from 'src/services/callCenter.service';

const Callout: FC = () => {
  const { calloutState, calloutDispatch } = useContext(AppContext);

  const [phoneCenter, setPhoneCenter] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<number | null>(null);
  const [isPoorNetwork, setIsPoorNetwork] = useState<boolean>(true);

  const onChangePhoneNumber = (value: number | null) => {
    setPhoneNumber(value);
  };

  const handleCheckingNetwork = (value: boolean) => {
    setIsPoorNetwork(value);
  };

  const makeACall = (phone: number | null) => {
    if (phoneCenter) {
      stringeeServices.makeACall(phoneCenter, '84' + phone);
      calloutDispatch({ type: CALL_OUT_ACTION.makeACall, phone });
    } else {
      notification.error({ message: 'Không tìm thấy số tổng đài' });
    }
  };

  function isPhoneNumber(text: string) {
    return text.length > 8;
  }

  const onPressBtnCall = () => {
    const callStatus = LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.callStatus
    );
    if (callStatus === 'calling') {
      notification.warn({
        message:
          'Vui lòng kết thúc cuộc gọi hiện tại trước khi thực hiện cuộc gọi mới',
      });
    } else {
      makeACall(phoneNumber);
    }
  };

  const fetchCallCenter = async () => {
    const response = await callCenterService.getPhoneCallCenter();
    setPhoneCenter(response?.data);
  };

  useEffect(() => {
    fetchCallCenter();
    navigator?.mediaDevices
      ?.getUserMedia({ audio: true, video: false })
      .then(() => {})
      .catch((err) => {
        window.chrome?.tabs?.create({
          url: 'request-mic.html',
        });
      });
    const handleBeforeUnload = (event: any) => {
      stringeeServices.endCall();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      stringeeServices.endCall();
    };
  }, []);

  return (
    <div>
      <div className="box-register-package mgt-15">
        <InputNumber
          placeholder="Nhập số điện thoại"
          controls={false}
          className="border rounded-none"
          style={{ width: 300, marginRight: 12 }}
          value={phoneNumber}
          onChange={onChangePhoneNumber}
        />

        <Button
          key="btn-call"
          type="primary"
          icon={<PhoneOutlined style={{ transform: 'rotate(90deg)' }} />}
          onClick={onPressBtnCall}
          loading={calloutState?.isLoading}
          disabled={
            calloutState?.callStatus !== 'ending' ||
            calloutState?.isLoading ||
            !calloutState?.initSuccess ||
            !isPhoneNumber(phoneNumber + '')
          }
        >
          Gọi điện
          <PingColor handleCheckingNetwork={handleCheckingNetwork} />
        </Button>
      </div>
      <BoxCallToCustomer className="ml-4 flex-wrap" />
    </div>
  );
};

export default Callout;

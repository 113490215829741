import { Button, Drawer, Form, Input, Select } from 'antd';
import React, { Ref, forwardRef, useImperativeHandle } from 'react';

export type CreateMembersModalRef = {
  open: (data?: any) => void;
  close: () => void;
};

type Props = {
  createMembers: (params: { teamUuid: number; userId: number }) => void;
  isEdit?: boolean;
  editMembers: (values: any, id: number) => void;
  listTeams: OrganizationsInterface[];
  listMembers?: any;
};

const ModalCreateMembers = (
  { createMembers, isEdit, editMembers, listTeams, listMembers }: Props,
  ref: Ref<CreateMembersModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<any | undefined>();

  const handleFillData = (data: any | undefined) => {
    if (isEdit) {
      form.setFieldsValue({
        teamUuid: data?.agentTeamUuid,
        // userId: ,
      });
    } else {
      form.resetFields();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data) => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
        close: onclose,
      };
    },
    [isEdit]
  );

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  const onclose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = (values: any) => {
    if (isEdit) {
      editMembers(values, Number(dataDetails?.id));
    } else {
      createMembers(values);
    }
  };

  return (
    <Drawer
      open={open}
      title={isEdit ? 'Chỉnh sửa Members' : 'Thêm mới Members'}
      destroyOnClose
      onClose={onclose}
      footer={
        <div className="flex justify-end gap-[10px]">
          <Button type="default" onClick={onclose}>
            Huỷ
          </Button>
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            Đồng ý
          </Button>
        </div>
      }
    >
      <Form form={form} id="myForm" onFinish={onSubmit} layout="vertical">
        <Form.Item
          label={'Team'}
          name={'teamUuid'}
          rules={[{ required: true, message: 'Vui lòng chọn team' }]}
        >
          <Select
            showSearch
            placeholder={'Chọn team'}
            options={listTeams.map((item) => ({
              value: item?.uuid,
              label: item.name,
            }))}
            filterOption={(input, option) =>
              (option as unknown as { value: number; label: string }).label
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item
          label={'Members'}
          name={'userId'}
          rules={[{ required: true, message: 'Vui lòng chọn Members' }]}
        >
          <Select
            showSearch
            placeholder={'Chọn member'}
            options={listMembers.map((item: any) => ({
              value: item?.id,
              label: item.username,
            }))}
            filterOption={(input, option) =>
              (option as { value: number; label: string }).label
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item label={'Ghi chú'} name="note">
          <Input.TextArea placeholder="Nhập ghi chú" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export const CreateMembersModal = forwardRef(ModalCreateMembers);

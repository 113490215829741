import { message } from 'antd';
import React, { useState } from 'react';
import { CreateTenantModalRef } from 'src/containers/Tenant/ModalCreate';
import organizationsServices from 'src/services/organizations.service';
import { RESPONSE_CODE } from 'src/utils/constants';

type State = {
  listAccountTenant: AccountTenantInterface[];
  metadata:
    | {
        totalPages: number;
        total: number;
      }
    | undefined;
  filters: {
    page: number;
    size: number;
    code: string;
    name: string;
  };
  isRefresh: boolean;
  isEdit: boolean;
};

export const useTenant = () => {
  const [state, setState] = useState<State>({
    listAccountTenant: [],
    metadata: undefined,
    filters: {
      page: 0,
      size: 20,
      code: '',
      name: '',
    },
    isRefresh: false,
    isEdit: false,
  });
  const createModal = React.useRef<CreateTenantModalRef>(null);

  React.useEffect(() => {
    getListAccountTenant();
  }, [state?.isRefresh]);

  const getListAccountTenant = async () => {
    const res = await organizationsServices.getAgent(state?.filters);
    const { data, total, totalPages } = res?.data;
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        listAccountTenant: data,
      }));
      setState((prev) => ({
        ...prev,
        metadata: {
          ...prev.metadata,
          totalPages: totalPages,
          total: total,
        },
      }));
    }
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState) => ({
      ...prevState,
      isRefresh: !state?.isRefresh,
      filters: {
        ...prevState.filters,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const handleOpenModalCreate = () => {
    createModal?.current?.open();
    setState((prev) => ({
      ...prev,
      isEdit: false,
    }));
  };

  const createTenant = async (values: any) => {
    const res = await organizationsServices.createAgent(values);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        isRefresh: !state?.isRefresh,
      }));
      createModal?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const editTenant = async (values: any, id: number, uuid: number) => {
    const params = {
      name: values?.name,
      personInCharge: id,
      status: values?.status,
    };

    const res = await organizationsServices.updateAgent(params, uuid);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prev) => ({
        ...prev,
        isRefresh: !state?.isRefresh,
      }));
      createModal?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const handleShowDetail = (data: any) => {
    createModal?.current?.open(data);
    setState((prev) => ({
      ...prev,
      isEdit: true,
    }));
  };

  return {
    state,
    onPageChange,
    createModal,
    createTenant,
    editTenant,
    handleOpenModalCreate,
    handleShowDetail,
  };
};

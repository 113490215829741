import { useEffect, useState } from 'react';
import { Table, Tag, notification } from 'antd';
import registerPackageServices from '../../services/registerPackage.service';
import topUpServices from '../../services/topUp.service';

import {
  convertListSaleMan,
  deleteParamsNotUsing,
  getQueryString,
  modifyArrTreeSelect,
} from '../../utils/stringUtils';
import { useWindowSize } from '../../utils/customHooks';
import {
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
  RESPONSE_CODE,
  TOP_UP_STATUS,
} from '../../utils/constants';
import moment from 'moment';
import { saveAs } from 'file-saver';
import TopUpFilters from './TopUpFilters';
import { Helmet } from 'react-helmet';
import { projectId, projectName } from 'src/utils';

type TopUpFiltersInterface = {
  page: number;
  size: number;
  msisdn: string | null;
  status: string | null;
  staffs?: string[] | null;
  createdDateFrom: number | null;
  createdDateTo: number | null;
};

type State = {
  extendSearch: boolean;
  data: any[];
  staffs: any;
  metadata?: MetadataInterface;
  isLoading: boolean;
  isLoadingExport: boolean;
  isLoadingReset: boolean;
  filters: TopUpFiltersInterface;
};

function TopUp() {
  const windowSize = useWindowSize();
  const defaultFilters: TopUpFiltersInterface = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
    msisdn: null,
    status: null,
    staffs: [],
    createdDateFrom:
      Number(moment().startOf('month').startOf('day').format('X')) * 1000,
    createdDateTo: Number(moment().endOf('day').format('X')) * 1000,
  };

  const [state, setState] = useState<State>({
    extendSearch: false,
    data: [],
    staffs: [],
    metadata: undefined,
    isLoading: true,
    isLoadingExport: false,
    isLoadingReset: false,
    filters: defaultFilters,
  });

  useEffect(() => {
    getDataFilters();
  }, []);

  useEffect(() => {
    if (state.isLoading) {
      getListData();
    }
  }, [state.isLoading]);

  const getDataFilters = async () => {
    const response = await Promise.all([
      registerPackageServices.getListStaffTree(),
      registerPackageServices.getListPackage(),
    ]);
    const responseStaff = response[0];
    const responsePackage = response[1];
    if (responseStaff?.status === RESPONSE_CODE.SUCCESS) {
      let newArr = modifyArrTreeSelect(responseStaff?.data);
      setState((cur) => ({
        ...cur,
        staffs: newArr,
        packages: responsePackage?.data?.data,
        isLoading: true,
        isLoadingReset: false,
      }));
    } else {
      setState((cur) => ({
        ...cur,
        isLoading: false,
        isLoadingReset: false,
      }));
      // notification.error({
      //   message: 'Có lỗi xảy ra !',
      // });
    }
  };

  const getListData = async () => {
    const filterParams = state.filters;
    deleteParamsNotUsing(filterParams);
    const query = getQueryString('', filterParams);
    const response = await topUpServices.getListData(query);
    const { data, headers } = response;
    if (response?.status === 200) {
      setState((cur) => ({
        ...cur,
        data: data,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
        isLoading: false,
        isLoadingReset: false,
      }));
    } else {
      setState((cur) => ({
        ...cur,
        isLoading: false,
        isLoadingReset: false,
      }));
      // notification.error({
      //   message: response?.data,
      // });
    }
  };

  const onSearch = () => {
    setState((cur) => ({
      ...cur,
      isLoading: true,
    }));
  };

  const onReset = () => {
    setState((cur) => ({
      ...cur,
      isLoadingReset: true,
      isLoading: true,
      filters: defaultFilters,
    }));
  };

  const onExport = async () => {
    setState((cur) => ({
      ...cur,
      isLoadingExport: true,
    }));

    const query = getQueryString('', state.filters);
    const resp = await topUpServices.exportFile(query);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Export_Topup_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      notification.error({
        message: resp?.data?.message,
      });
    }
    setState((cur) => ({
      ...cur,
      isLoadingExport: false,
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        page: mPage - 1 ?? 0,
        size: mSize ?? 0,
      },
      isLoading: true,
    }));
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => index + 1,
    },
    {
      title: 'Ngày nạp',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (cell, record) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Người nạp thẻ',
      dataIndex: 'createdStaff',
      key: 'createdStaff',
      render: (cell, record) => {
        return cell || '--';
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Số tiền nạp',
      dataIndex: 'amount',
      key: 'amount',
      render: (cell, record) => {
        return cell ? cell.toLocaleString() : '--';
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (cell, record) => {
        return (
          <Tag color={getTagColor(cell)} style={{ fontSize: '0.75vw' }}>
            {TOP_UP_STATUS.find((x) => x.value === cell)?.label || '--'}
          </Tag>
        );
      },
    },
  ];

  const getTagColor = (cell: string) => {
    switch (cell) {
      case 'success': {
        return 'green';
      }
      case 'pending': {
        return 'blue';
      }
      default:
        return 'volcano';
    }
  };

  const onChangePhone = (phone: any) => {
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        msisdn: phone,
      },
    }));
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onChangeState = (value: any) => {
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        status: value,
      },
    }));
  };

  const onChangeCreater = (value: any) => {
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        staffs: convertListSaleMan(value, state.staffs),
      },
    }));
  };

  const onExtend = () => {
    setState((cur) => ({
      ...cur,
      extendSearch: !cur.extendSearch,
    }));
  };

  const onChangeDate = (values: any, formatString: [string, string]) => {
    const dateOne = values?.[0]
      ? Number(moment(values[0]).startOf('day').format('X')) * 1000
      : null;
    const dateTwo = values?.[1]
      ? Number(moment(values[1]).endOf('day').format('X')) * 1000
      : null;

    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        createdDateFrom: dateOne,
        createdDateTo: dateTwo,
      },
    }));
  };

  let tableHeight = state.extendSearch
    ? windowSize?.height * 0.55
    : windowSize?.height * 0.7;

  return (
    <div>
      <Helmet>
        <title>Lịch sử nạp thẻ</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <TopUpFilters
        extendSearch={state.extendSearch}
        isLoadingExport={state.isLoadingExport}
        isLoadingReset={state.isLoadingReset}
        status={state.filters.status}
        staffs={state.filters.staffs}
        listStaff={state.staffs}
        onChangePhone={onChangePhone}
        onChangeDate={onChangeDate}
        onChangeState={onChangeState}
        onChangeCreater={onChangeCreater}
        onKeyDown={onKeyDown}
        onExtend={onExtend}
        onExport={onExport}
        onSearch={onSearch}
        onReset={onReset}
      />
      <Table
        rowKey={'id'}
        dataSource={state.data}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: state.filters?.page + 1,
          defaultPageSize: state.filters?.size,
          total: state.metadata?.total,
          style: { marginRight: 12 },
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default TopUp;

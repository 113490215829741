import { Tag, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';

const getColumnsConfig = (
  handleDetails?: (id: number) => void,
  page: number = 0,
  pageSize: number = 0
) => {
  const columns: ColumnType<any>[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return (page - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Nhóm quyền',
      dataIndex: 'title',
      key: 'title',
      width: '7%',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      render: (cell) => {
        return <Typography className="whitespace-pre-line">{cell}</Typography>;
      },
      width: '35%',
    },
    {
      title: 'Đang áp dụng',
      dataIndex: 'applying',
      key: 'applying',
      width: '11%',
    },

    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Tag
            onClick={handleDetails?.bind(null, record?.id)}
            className="cursor-pointer"
          >
            Xem chi tiết
          </Tag>
        );
      },
      width: '11%',
    },
  ];
  return columns;
};

export default getColumnsConfig;

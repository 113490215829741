import { useState } from 'react';
import GetColumnsConfig from '../Columns';
import './style.css';
import { Table } from 'antd';

type Props = {
  data: any;
};

function CustomTable({ data }: Props) {
  const [page, setPage] = useState<number>(1);
  const columns = GetColumnsConfig();

  return (
    <Table
      className={'customTable'}
      rowKey="id"
      dataSource={data}
      columns={columns}
      pagination={{
        size: 'small',
        defaultPageSize: 10,
        current: page,
        total: data?.length,
        showTotal: (total) => <div>Tổng số: {total.toLocaleString()}</div>,
        onChange: setPage,
      }}
    />
  );
}

export default CustomTable;

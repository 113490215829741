import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import userServices from 'src/services/users.service';
import {
  REGEX_PASSWORD,
  REGEX_USERNAME,
  RESPONSE_CODE,
} from '../../utils/constants';

type Props = {
  managers: any;
  dataDetail: any;
  modalStatus: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  listPermission: PermissionInterface[];
};

function ModalCreate(props: Props) {
  const [form] = Form.useForm();
  const { modalStatus, handleOk, handleCancel, dataDetail, listPermission } =
    props;
  const ipPhoneData = useRef(dataDetail.ipPhone);
  const [valueSelect, setValueSelect] = useState(dataDetail.active);

  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue({
        ...dataDetail,
        active: valueSelect,
        ipPhone: valueSelect === 1 ? ipPhoneData.current : '',
        password: dataDetail?.id ? '1234123a@' : dataDetail?.password,
        groupPermissionId: dataDetail?.groupPermissionId,
      });
    }
  }, [dataDetail, valueSelect]);

  const onFinish = async (values: any) => {
    const convertValues = {
      ...values,
      roles: typeof values.roles === 'object' ? values.roles : [values.roles],
    };

    if (dataDetail?.id) {
      const body = { ...convertValues };
      delete body.password;
      delete body.username;
      if (dataDetail.ipPhone === values.ipPhone) {
        delete body.ipPhone;
      }

      if (values.manager === '') {
        delete body.manager;
      }

      const resp = await userServices.editAccount(dataDetail?.id, body);
      if (resp?.status === RESPONSE_CODE.SUCCESS) {
        notification.success({
          message: 'Chỉnh sửa tài khoản',
          description: 'Thành công',
        });
        if (handleOk) handleOk();
      } else {
        notification.error({
          message: resp?.data?.message,
        });
      }
    } else {
      if (convertValues.manager === '') {
        delete convertValues.manager;
      }
      const resp = await userServices.createAccount(convertValues);
      if (resp?.status === 200) {
        notification.success({
          message: 'Thêm mới tài khoản',
          description: 'Thành công',
        });
        if (handleOk) handleOk();
      } else {
        notification.error({
          message: resp?.data?.message,
        });
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={dataDetail?.id ? 'Chỉnh sửa tài khoản' : 'Thêm mới tài khoản'}
      visible={modalStatus}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Hủy bỏ
        </Button>,
        <Button form="myForm" key="submit" htmlType="submit" type="primary">
          {dataDetail?.id ? 'Lưu' : 'Thêm mới'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="myForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              validateTrigger={'onBlur'}
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                {
                  pattern: new RegExp(REGEX_USERNAME),
                  message: 'Tên đăng nhập không hợp lệ!',
                },
                { min: 4, message: 'Tên đăng nhập không thể ngắn hơn 4 ký tự' },
                {
                  max: 60,
                  message: 'Tên đăng nhập không thể dài hơn 60 ký tự',
                },
              ]}
            >
              <Input disabled={!!dataDetail?.id} placeholder="Nhập Username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Họ và tên"
              name="fullName"
              rules={[
                { required: true, message: 'Vui lòng nhập họ tên!' },
                { max: 30, message: 'Họ và tên không thể dài hơn 30 ký tự' },
              ]}
            >
              <Input placeholder="Nhập họ và tên" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Mật khẩu"
              name="password"
              validateTrigger={'onBlur'}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(REGEX_PASSWORD),
                  message:
                    'Mật khẩu có ít nhất 8 ký tự bao gồm: tối thiểu 1 chữ cái, 1 số.',
                },
              ]}
            >
              <Input.Password
                disabled={!!dataDetail?.id}
                placeholder="Nhập mật khẩu"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="IP phone CareSoft" name="ipPhone">
              <Input placeholder="Nhập IP phone" />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item
              label="Roles"
              name="roles"
              rules={[{ required: true, message: 'Vui lòng chọn Roles!' }]}
            >
              <Select allowClear onChange={handleOnChangeRoles}>
                {ROLES_LIST_PERMISSION.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={20}>
          {/* <Col span={12}>
            <Form.Item label="Người quản lý" name="manager">
              <Select
                disabled={isManagerDisabled}
                placeholder="Chọn người quản lý"
              >
                {Array.isArray(managers) &&
                  managers.map((x: any, y) => (
                    <Select.Option key={y} value={x.username}>
                      {x?.fullName} ({x?.username})
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Trạng thái tài khoản"
              name="active"
              rules={[{ required: true, message: 'Vui lòng chọn trạng thái!' }]}
            >
              <Select
                onChange={(value: any) => {
                  setValueSelect(value);
                }}
              >
                <Select.Option value={1}>Đang hoạt động</Select.Option>
                <Select.Option value={0}>Ngừng hoạt động</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item
              label="Phòng ban"
              name="department"
              // rules={[{ required: true, message: 'Vui lòng chọn phòng ban' }]}
            >
              <Select>
                {DEPARTMENT.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Nhóm quyền"
              name="groupPermissionId"
              rules={[
                { required: true, message: 'Vui lòng chọn nhóm quyền !' },
              ]}
            >
              <Select
                options={listPermission?.map((item) => ({
                  value: item?.id,
                  label: item?.title,
                }))}
                showSearch
                placeholder={'Chọn nhóm quyền'}
                filterOption={(input, option) =>
                  (option as { value: number; label: string }).label
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalCreate;

import { Tag } from 'antd';
import moment, { Moment } from 'moment';
import { formatPrice } from 'src/utils/formatter';
import AsignData from './AssignData/AssignData';
import { isHavePermissionNew } from 'src/utils/stringUtils';
import { PERMISSIONS } from 'src/utils/constants';

const getTagColor = (cell: string) => {
  switch (cell) {
    case 'Đã chia':
      return 'blue';
    case 'Chưa chia':
      return 'green';
    case 'Chờ xử lý':
      return 'volcano';
  }
};

export const getColumnDataManager = (
  onSearch: () => void,
  listStaff: any
): any => {
  const isDisable = (assignedStatus: string) => {
    if (assignedStatus !== 'Chưa chia') {
      if (isHavePermissionNew(PERMISSIONS.DATA_MANAGER_VIEW_ALL_TEAM)) {
        return true;
      } else if (isHavePermissionNew(PERMISSIONS.DATA_MANAGER_VIEW_TEAM)) {
        return false;
      }
    } else {
      return false;
    }
  };

  return [
    {
      title: 'Ngày chia data',
      dataIndex: 'assignedDate',
      key: 'assignedDate',
      width: '13%',
      fixed: 'left',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
      width: '10%',
      fixed: 'left',
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'sourceSys',
      key: 'sourceSys',
      width: '8%',
    },
    {
      title: 'Gói đang dùng',
      dataIndex: 'packActive',
      key: 'packActive',
      width: '10%',
    },
    {
      title: 'Gói đang dùng(cập nhật)',
      dataIndex: 'currentRegisteredPackage',
      key: 'currentRegisteredPackage',
      width: '14%',
    },
    {
      title: 'Gói cước phù hợp',
      dataIndex: 'suggestedPackage',
      key: 'suggestedPackage',
      width: '20%',
    },
    {
      title: 'Giá gói khách hàng',
      dataIndex: 'chargePrice',
      key: 'chargePrice',
      width: '13%',
      render: (cell: number) => {
        return cell ? `${formatPrice(cell)} VNĐ` : null;
      },
    },
    {
      title: 'Ngày cập nhật data',
      dataIndex: 'autoSearchedDate',
      key: 'autoSearchedDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Ngày gia hạn',
      dataIndex: 'packActivationDate',
      key: 'packActivationDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Nguồn data',
      dataIndex: 'source',
      key: 'source',
      width: '7%',
    },
    {
      title: 'Ngày Import',
      dataIndex: 'importDate',
      key: 'importDate',
      width: '14%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'assignedStatus',
      key: 'assignedStatus',
      width: '8%',
      fixed: 'right',
      hidden: !isHavePermissionNew(PERMISSIONS.DATA_MANAGER_VIEW_ALL_TEAM),
      render: (cell: string) => (
        <Tag color={getTagColor(cell)} style={{ fontSize: '0.75vw' }}>
          {cell}
        </Tag>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      fixed: 'right',
      className: 'text-center',
      hidden: !isHavePermissionNew(PERMISSIONS.DATA_NEW_ASSIGN),
      render: (cell: any, { id, assignedStatus }: any) => (
        <AsignData
          key={'asign' + id}
          dataId={id}
          onSuccess={onSearch}
          listStaff={listStaff}
          disabled={isDisable(assignedStatus)}
        />
      ),
    },
  ].filter((item) => !item.hidden);
};

import { Button, Drawer, Form, Input, Select } from 'antd';
import React, { Ref, forwardRef, useImperativeHandle } from 'react';

export type CreateTenantModalRef = {
  open: (data?: any) => void;
  close: () => void;
};

type Props = {
  createTenant: (values: any) => void;
  isEdit?: boolean;
  editTenant: (values: any, id: number, uuid: number) => void;
};

const STATUS_ACCOUNT = [
  { value: 0, label: 'Đang hoạt động' },
  { value: 1, label: 'Ngừng hoạt động' },
];

const ModalCreateTenant = (
  { createTenant, isEdit, editTenant }: Props,
  ref: Ref<CreateTenantModalRef>
) => {
  const [form] = Form.useForm();
  const [open, setOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<any | undefined>();

  const handleFillData = (data: any | undefined) => {
    if (isEdit) {
      form.setFieldsValue({
        name: data?.name,
        code: data?.code,
        status: data?.status,
      });
    } else {
      form.resetFields();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data) => {
          setDataDetails(data);
          handleFillData(data);
          setOpen(true);
        },
        close: onclose,
      };
    },
    [isEdit]
  );

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  const onclose = () => {
    setOpen(false);
    form.resetFields();
  };

  const onSubmit = (values: any) => {
    if (isEdit) {
      editTenant(values, dataDetails?.personInCharge, dataDetails?.uuid);
    } else {
      createTenant(values);
    }
  };

  return (
    <Drawer
      open={open}
      title={isEdit ? 'Chỉnh sửa Tenant' : 'Thêm mới Tenant'}
      destroyOnClose
      onClose={onclose}
      footer={
        <div className="flex justify-end gap-[10px]">
          <Button type="default" onClick={onclose}>
            Huỷ
          </Button>
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            Đồng ý
          </Button>
        </div>
      }
    >
      <Form form={form} id="myForm" onFinish={onSubmit} layout="vertical">
        <Form.Item
          label={'Code'}
          name="code"
          rules={[{ required: true, message: 'Vui lòng nhập code' }]}
        >
          <Input placeholder="Nhập code" disabled={isEdit} />
        </Form.Item>
        <Form.Item
          label={'Name'}
          name="name"
          rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
        >
          <Input placeholder="Nhập tên" />
        </Form.Item>
        {isEdit && (
          <Form.Item
            label="Trạng thái hoạt động"
            name="status"
            style={{ marginBottom: 15 }}
          >
            <Select
              options={STATUS_ACCOUNT}
              placeholder="Vui lòng chọn"
              allowClear={false}
            />
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

export const CreateTenantModal = forwardRef(ModalCreateTenant);

import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
  TreeSelect,
} from 'antd';
import moment from 'moment';
import { SHOW_PARENT } from 'rc-tree-select';
import React, { FC } from 'react';
import { ReactComponent as IconShareData } from 'src/assets/icons/icShareData.svg';
import FiltersButton from 'src/components/FiltersButton';
import SelectSaleMan from 'src/components/SelectSaleMan';
import {
  boxFilterStyle,
  contentBox,
  filterBoxStyle,
  inputStyle,
  txtTitle,
} from 'src/styles';
import {
  DATE_FORMAT,
  PERMISSIONS,
  SOURCES_SYS_LIST,
} from 'src/utils/constants';
import { isHavePermissionNew } from 'src/utils/stringUtils';

type Props = {
  defaultData: any;
  size: any;
  packages: any[];
  staffs: any;
  treeTakeCare: any;
  takeCareSelected: any;
  selectedStaffs: any;
  extendSearch: boolean;
  isLoadingExport: boolean;
  loadingAutoSearch: boolean;
  loading: boolean;
  autoSearchRunning: boolean;
  actionStaff: any;
  defaultTreeReason: string[];
  dataReason: TREE_REASON[];
  configPhonePackagesAutoSearch: any;
  onChangeExpiredDate: (values: any, formatString: [string, string]) => void;
  onChangePhone: (phone: any) => void;
  onChangePackage: (e: { target: { value: string } }) => void;
  onChangeStaff: (value: string[]) => void;
  onChangeSourceSys: (value: string) => void;
  onChangeTakeCare: (e: any) => void;
  onKeyDown: (e: any) => void;
  onSearch: () => void;
  onExport: () => void;
  onExtend: () => void;
  onReset: () => void;
  onChangeReason: (value: any) => void;
  onChangeAllocated: (value: any) => void;
  onShareData: () => void;
  onAutoSearch: () => void;
  onChangeCurrentRegisteredPackage: (e: any) => void;
  onChangeSuggestedPackage: (values: string[]) => void;
};

const DataLx3Filters: FC<Props> = ({
  defaultData,
  size,
  treeTakeCare,
  packages,
  staffs,
  selectedStaffs,
  extendSearch,
  isLoadingExport,
  loading,
  autoSearchRunning,
  actionStaff,
  takeCareSelected,
  defaultTreeReason,
  dataReason,
  configPhonePackagesAutoSearch,
  onAutoSearch,
  onShareData,
  onChangeExpiredDate,
  onChangePhone,
  onChangeTakeCare,
  onChangePackage,
  onChangeStaff,
  onKeyDown,
  onSearch,
  onExport,
  onExtend,
  onReset,
  onChangeReason,
  onChangeAllocated,
  onChangeCurrentRegisteredPackage,
  onChangeSuggestedPackage,
  onChangeSourceSys,
}) => {
  const filterTreeNode = (string: string, node: any) => {
    if (!node?.title) return false;
    const strSearch = string.toLowerCase();
    return (node?.title as string)?.toLowerCase()?.indexOf(strSearch) !== -1
      ? true
      : false;
  };

  if (loading) {
    return;
  }

  return (
    <div>
      <div
        className={`dpl-flex align-items-center pd-8 pdl-15 pdr-15 ${
          extendSearch ? 'box-showdow-bottom' : ''
        }`}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          {isHavePermissionNew(PERMISSIONS.DATA_RECALL) && (
            <InputNumber
              placeholder={'Nhập Số điện thoại...'}
              prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
              style={{ ...boxFilterStyle, width: '300px' }}
              value={defaultData.msisdn}
              onChange={onChangePhone}
              onKeyDown={onKeyDown}
              controls={false}
              size={size}
            />
          )}
          {isHavePermissionNew(PERMISSIONS.DATA_RECALL) && (
            <>
              {!extendSearch && (
                <Button type="primary" onClick={onExtend} size={size}>
                  Bộ lọc
                </Button>
              )}
            </>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          {isHavePermissionNew(PERMISSIONS.DATA_RECALL_AUTO_SEARCH) && (
            <Tooltip
              title={
                autoSearchRunning && actionStaff
                  ? `${actionStaff} đang thực hiện tra cứu tự động`
                  : ''
              }
            >
              <Button
                className="mgr-10"
                size={size}
                onClick={onAutoSearch}
                disabled={autoSearchRunning}
                style={{
                  color: autoSearchRunning
                    ? 'hsl(0deg 0% 0% / 50%)'
                    : 'hsl(215deg 100% 54%)',
                  border: '1px solid hsl(215deg 100% 54%)',
                }}
                loading={autoSearchRunning}
                icon={<SearchOutlined />}
              >
                Tra cứu tự động
              </Button>
            </Tooltip>
          )}
          {isHavePermissionNew(PERMISSIONS.DATA_RECALL_DIVIDE_DATA) && (
            <Button
              size={size}
              className="btn-filter mgr-10"
              onClick={onShareData}
              loading={isLoadingExport}
              icon={<IconShareData style={{ width: 25, height: 15 }} />}
              type="default"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Chia data
            </Button>
          )}
          {isHavePermissionNew(PERMISSIONS.DATA_RECALL_EXPORT) && (
            <div style={{ display: 'flex' }}>
              <Button
                className="btn-export"
                onClick={onExport}
                type="default"
                size={size}
                icon={<DownloadOutlined />}
                loading={isLoadingExport}
              >
                Export
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          extendSearch ? 'data-manager-filters box-showdow-bottom' : 'hide-box'
        }
      >
        <Row>
          <Col span={8}>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>Gói đang dùng</p>
              <Input
                size={size}
                placeholder="Gói đang sử dụng"
                allowClear
                style={{ width: '12vw', marginLeft: '0.32rem' }}
                onChange={onChangePackage}
              />
              {/* <Select
                allowClear
                showSearch
                key="currentPackages"
                mode="multiple"
                placeholder="Gói đang sử dụng"
                style={{ width: '12vw', marginLeft: '0.32rem' }}
                size={size}
                maxTagCount="responsive"
                onChange={onChangePackage}
                defaultValue={defaultData.packActive}
              >
                {packages?.map((item: any) => (
                  <Select.Option key={item.id} value={item.code}>
                    {item.code}
                  </Select.Option>
                ))}
              </Select> */}
            </div>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>
                Gói đang dùng (cập nhật)
              </p>
              <Input
                size={size}
                style={inputStyle}
                placeholder="Gói đang dùng(cập nhật)"
                onChange={onChangeCurrentRegisteredPackage}
                onKeyDown={onKeyDown}
              />
            </div>

            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>Gói cước phù hợp</p>
              <Select
                allowClear
                size={size}
                placeholder="Gói cước phù hợp"
                style={filterBoxStyle}
                mode="multiple"
                maxTagCount="responsive"
                onChange={onChangeSuggestedPackage}
                options={configPhonePackagesAutoSearch}
              />
            </div>

            <div className="label-input-base">
              <Button type="primary" onClick={onSearch}>
                Cập nhật
              </Button>
              <Button type="default" onClick={onExtend}>
                Thu gọn
              </Button>
              <Button
                type="text"
                onClick={() => {
                  onReset();
                  onExtend();
                }}
              >
                Reset
              </Button>
            </div>
          </Col>

          <Col span={8}>
            <div style={contentBox}>
              <p style={txtTitle}>Ngày phân bố data</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                onChange={onChangeAllocated}
                placeholder={['Từ', 'Đến']}
                format={DATE_FORMAT}
                style={inputW}
                defaultValue={
                  defaultData?.allocatedDateFrom
                    ? [
                        moment(defaultData?.allocatedDateFrom),
                        moment(defaultData?.allocatedDateTo),
                      ]
                    : undefined
                }
              />
            </div>

            <div style={contentBox}>
              <p style={txtTitle}>Ngày hết hạn</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={inputW}
                onChange={onChangeExpiredDate}
                placeholder={['Từ', 'Đến']}
                format={DATE_FORMAT}
                defaultValue={
                  defaultData?.expiredDateFrom
                    ? [
                        moment(defaultData.expiredDateFrom),
                        moment(defaultData.expiredDateTo),
                      ]
                    : undefined
                }
              />{' '}
            </div>
            <div style={contentBox}>
              <p style={{ ...txtTitle }}>Lý do</p>
              <TreeSelect
                treeData={dataReason}
                style={inputW}
                size={size}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                onChange={onChangeReason}
                defaultValue={defaultTreeReason}
                placeholder="Tất cả"
              />
            </div>
          </Col>

          <Col span={8}>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '8vw' }}>Trạng thái chăm sóc</p>
              <TreeSelect
                allowClear
                showArrow
                size={size}
                style={inputW}
                treeData={treeTakeCare}
                maxTagCount="responsive"
                placeholder={'Tất cả'}
                treeDefaultExpandAll
                onChange={onChangeTakeCare}
                filterTreeNode={filterTreeNode}
                treeCheckable={true}
                value={takeCareSelected}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
              />
            </div>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '8vw' }}>Saleman</p>
              <SelectSaleMan
                size={size}
                treeData={staffs}
                value={selectedStaffs}
                onChange={onChangeStaff}
                placeholder={'Sale'}
                style={inputW}
                defaultValue={defaultData.assignedStaffs}
              />
            </div>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '8vw' }}>Loại thuê bao</p>
              <Select
                style={inputW}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={SOURCES_SYS_LIST[0].value}
                onChange={onChangeSourceSys}
                options={SOURCES_SYS_LIST}
              />
            </div>
          </Col>
          <FiltersButton
            style={{ marginTop: 10 }}
            size={size}
            onSearch={onSearch}
            onExtend={onExtend}
            onReset={onReset}
          />
        </Row>
      </div>
    </div>
  );
};

const inputW: React.CSSProperties = {
  width: '18vw',
};

export default DataLx3Filters;

import {
  DownloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  TreeSelect,
  Upload,
} from 'antd';
import moment from 'moment';
import { FC } from 'react';
import FiltersButton from 'src/components/FiltersButton';
import {
  boxFilterStyle,
  contentBox,
  datePickerStyle,
  filterBoxStyle,
  txtTitle,
} from 'src/styles';
import {
  PERMISSIONS,
  REGISTER_PACKAGE_PAYMENT_CHANNEL,
  REGISTER_PACKAGE_STATUS,
  REGISTER_PACKAGE_SYSTEM,
} from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { isHavePermissionNew } from 'src/utils/stringUtils';

type Props = {
  defaultValue: any;
  style?: any;
  extendSearch: boolean;
  isLoadingExport: boolean;
  listStaff?: any;
  listPackage?: any;
  isLoadingReset: boolean;
  onChangePhone: (value: string | null) => void;
  onKeyDown: (e: any) => void;
  onSelectSaleMan: (e: any) => void;
  onChangePackage: (e: any) => void;
  onChangeChanel: (e: any) => void;
  onChangeStatus: (e: any) => void;
  onChangeSystem: (e: any) => void;
  onChangeAssignedDate: (e: any) => void;
  onExtend: () => void;
  onExport: () => void;
  onSearch: () => void;
  onReset: () => void;
  onImport: (options: any) => void;
};

const RegisterPackageFilters: FC<Props> = ({
  defaultValue,
  style,
  extendSearch,
  isLoadingExport,
  listStaff,
  listPackage,
  isLoadingReset,
  onChangePhone,
  onKeyDown,
  onSelectSaleMan,
  onChangePackage,
  onChangeChanel,
  onChangeStatus,
  onChangeSystem,
  onChangeAssignedDate,
  onExtend,
  onExport,
  onImport,
  onSearch,
  onReset,
}) => {
  const windowSize = useWindowSize();

  const size = windowSize?.width > 1700 ? 'middle' : 'small';
  // const isHavePermission = isUserHaveOneOfPermissions([
  //   ROLES.ROLE_ADMIN,
  //   ROLES.ROLE_SALE_MANAGER,
  //   ROLES.ROLE_ACCOUNTANT,
  //   ROLES.ROLE_SALE_LEADER,
  // ]);

  if (isLoadingReset) return <div style={{ height: 150 }} />;
  return (
    <div style={style}>
      <div
        className={`dpl-flex align-items-center pd-8 pdl-15 pdr-15 ${
          extendSearch ? 'box-showdow-bottom' : ''
        }`}
      >
        <InputNumber
          size={size}
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder={'Nhập Số điện thoại...'}
          controls={false}
          style={{ ...boxFilterStyle, width: '20vw' }}
          onChange={onChangePhone}
          onKeyDown={onKeyDown}
        />
        {isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY) && (
          <>
            {!extendSearch && (
              <>
                <Button
                  onClick={onExtend}
                  type="primary"
                  size={size}
                  style={{ marginLeft: 12 }}
                >
                  Bộ lọc
                </Button>
                <span
                  style={{
                    color: 'rgba(0, 0, 0, 0.5)',
                    marginLeft: 10,
                  }}
                >
                  Lọc theo trường: Ngày tạo:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {moment().startOf('month').format('DD/MM/YYYY')} -{' '}
                    {moment()
                      .subtract(0, 'days')
                      .endOf('day')
                      .format('DD/MM/YYYY')}
                  </span>
                </span>
              </>
            )}
          </>
        )}

        {isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY_EXPORT) && (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            <Button
              className="btn-export"
              onClick={onExport}
              type="default"
              size={size}
              icon={<UploadOutlined />}
              loading={isLoadingExport}
            >
              Export
            </Button>
          </div>
        )}
        {isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY_IMPORT) && (
          <Upload
            accept=".xlsx, .xls"
            customRequest={onImport}
            showUploadList={false}
          >
            <Button
              className="btn-export"
              style={{ marginLeft: 10 }}
              type="default"
              size={size}
              icon={<DownloadOutlined />}
            >
              Import
            </Button>
          </Upload>
        )}
      </div>

      {extendSearch && (
        <div className={'data-divided-filters box-showdow-bottom'}>
          <Row>
            <Col span={6}>
              <div style={contentBox}>
                <p style={{ ...txtTitle, width: 'auto', marginRight: '10px' }}>
                  Gói cước
                </p>
                <Select
                  style={filterBoxStyle}
                  size={size}
                  allowClear
                  showSearch
                  options={listPackage.map((item: any) => ({
                    value: item?.code,
                  }))}
                  showArrow={false}
                  optionFilterProp="children"
                  onChange={onChangePackage}
                  defaultValue={defaultValue.phonePackage}
                  filterOption={(input, option: any) =>
                    (option?.value?.toLowerCase() ?? '').includes(
                      input.toLowerCase()
                    )
                  }
                />
              </div>
            </Col>

            <Col span={6}>
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={{ ...txtTitle, width: '4vw' }}>Trạng thái</p>
                <Select
                  style={filterBoxStyle}
                  size={size}
                  allowClear
                  showSearch
                  options={REGISTER_PACKAGE_STATUS}
                  optionFilterProp="children"
                  placeholder={'Tất cả'}
                  defaultValue={defaultValue.status}
                  onChange={onChangeStatus}
                />
              </div>
              {/* {isHavePermission && ( */}
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={{ ...txtTitle, width: '4vw' }}>Saleman</p>
                <TreeSelect
                  showArrow
                  allowClear
                  style={filterBoxStyle}
                  size={size}
                  treeData={listStaff}
                  maxTagCount="responsive"
                  placeholder={'Tất cả'}
                  treeCheckable={true}
                  defaultValue={defaultValue.staffs}
                  onChange={onSelectSaleMan}
                />
              </div>
              {/* )} */}
            </Col>

            <Col span={6}>
              <div style={contentBox}>
                <p style={txtTitle}>Kênh</p>
                <Select
                  style={filterBoxStyle}
                  size={size}
                  allowClear
                  showSearch
                  options={REGISTER_PACKAGE_PAYMENT_CHANNEL}
                  optionFilterProp="children"
                  placeholder={'Tất cả'}
                  defaultValue={defaultValue.paymentChannel}
                  onChange={onChangeChanel}
                />
              </div>

              <div style={contentBox}>
                <p style={txtTitle}>Hệ thống</p>
                <Select
                  allowClear
                  style={filterBoxStyle}
                  size={size}
                  options={REGISTER_PACKAGE_SYSTEM}
                  maxTagCount="responsive"
                  placeholder={'Tất cả'}
                  defaultValue={defaultValue.sourceSys}
                  onChange={onChangeSystem}
                />
              </div>
            </Col>
            <Col span={6}>
              <div style={{ ...contentBox, justifyContent: 'flex-end' }}>
                <p style={{ ...txtTitle, width: '5vw' }}>Ngày đăng ký</p>
                <DatePicker.RangePicker
                  style={datePickerStyle}
                  size={size}
                  allowClear
                  placeholder={['Từ', 'Đến']}
                  format={'DD/MM/YYYY'}
                  defaultValue={[
                    moment(defaultValue.registeredDateFrom).startOf('day'),
                    moment(defaultValue.registeredDateTo).endOf('day'),
                  ]}
                  onChange={onChangeAssignedDate}
                />
              </div>
            </Col>
          </Row>
          <FiltersButton
            style={{ marginTop: 10 }}
            size={size}
            onSearch={onSearch}
            onExtend={onExtend}
            onReset={onReset}
          />
        </div>
      )}
    </div>
  );
};

export default RegisterPackageFilters;

import {
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  HomeOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Divider, Dropdown, Layout, Menu, Popover, Space } from 'antd';
import React, { Children, useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppContext from 'src/context/AppContext';
import { PERMISSIONS, RESPONSE_CODE, ROUTERS } from 'src/utils/constants';
import { isHavePermissionNew, isNullOrEmpty } from 'src/utils/stringUtils';
import Logo from '../../assets/logo.png';
import LogoVertical from '../../assets/logo_vertical.webp';
import BubbleCallIn from '../BubbleCallIn';
import { NotiMissedCall } from '../NotyMissedCall';

import { useLocation } from 'react-router-dom';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';
import registerPackageServices from 'src/services/registerPackage.service';
import stringeeServices from 'src/services/stringee/stringee.service';
import { getLinkAvatar, onLogout } from 'src/utils';
import './style.scss';
import callCenterService from 'src/services/callCenter.service';
import { CALL_OUT_ACTION } from 'src/hooks/useStringee';

const { Sider } = Layout;

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === '/') {
      BreadcrumbsRef.current?.updateKeyPath(['dashboard']);
    }
  }, [location?.pathname]);

  const { calloutState, appState, dispatch, calloutDispatch }: ContextType =
    React.useContext(AppContext);
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const [param, setParam] = useState('/');
  const BreadcrumbsRef = useRef<any>(null);

  const getPackages = async () => {
    if (appState?.phonePackages?.length > 0) {
      return;
    }
    const response = await registerPackageServices.getListPackage();
    const { data } = response?.data;
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      dispatch({
        type: APP_CONTEXT_ACTIONS.UPDATE_PACKAGES,
        payload: {
          phonePackages: data ?? [],
        },
      });
    }
  };

  const checkMissedCall = async () => {
    const res = await callCenterService.missedCalls();
    if (res?.status === RESPONSE_CODE.SUCCESS && res?.data?.length > 0) {
      calloutDispatch({
        type: CALL_OUT_ACTION.showMissedModal,
        isShowMissedModal: true,
        missedCalls: res?.data,
      });
    }
  };

  useEffect(() => {
    getPackages();
    checkMissedCall();
  }, []);

  const utilityMenu = [
    {
      label: 'Gọi đi',
      key: ROUTERS.Callout.key,
      onClick: () => {
        handleOnclick(ROUTERS.Callout.path);
      },
      isShow: true,
    },
    {
      label: 'Tra cứu thông tin',
      key: ROUTERS.Search.key,
      onClick: () => {
        handleOnclick(ROUTERS.Search.path);
      },
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_SEARCH),
    },
    {
      label: 'Tạo yêu cầu Đăng ký',
      key: ROUTERS.CreateRegisterPackage.key,
      onClick: () => {
        handleOnclick(ROUTERS.CreateRegisterPackage.path);
      },
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_REQUEST),
    },
    // {
    //   label: "Gia hạn gói cước",
    //   key: ROUTERS.ExtendPackages.key,
    //   onClick: () => {
    //     handleOnclick(ROUTERS.ExtendPackages.path);
    //   },
    // },
    {
      label: 'Lịch sử cuộc gọi',
      key: ROUTERS.CallCenter.key,
      onClick: () => {
        navigate(ROUTERS.CallCenter.path);
      },
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_CALL_HISTORY),
    },
    {
      label: 'Lịch sử nạp thẻ',
      key: ROUTERS.TopUp.key,
      onClick: () => {
        handleOnclick(ROUTERS.TopUp.path);
      },
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_TOP_UP_HISTORY),
    },
    {
      label: 'Lịch sử đăng ký',
      key: ROUTERS.RegisterPackage.key,
      onClick: () => {
        handleOnclick(ROUTERS.RegisterPackage.path);
      },
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_REGISTER_HISTORY),
    },
    // {
    //   label: 'Đăng ký ngoài CRM',
    //   key: ROUTERS.RegisterPackageOutside.key,
    //   onClick: () => {
    //     handleOnclick(ROUTERS.RegisterPackageOutside.path);
    //   },
    // },
  ];

  const handleOk = () => {
    navigate(param);
    setIsOpenModalWarning(false);
    stringeeServices.endCall();
  };

  const handleCancel = () => {
    setIsOpenModalWarning(false);
  };

  const handleOnclick = (param: string) => {
    if (calloutState.callStatus !== 'ending') {
      setIsOpenModalWarning(true);
      setParam(param);
    } else {
      setIsOpenModalWarning(false);
      setParam(param);
      navigate(param);
    }
  };

  const items: any = [
    {
      label: 'Trang chủ',
      key: ROUTERS.Home.key,
      icon: <HomeOutlined />,
      onClick: () => handleOnclick('/'),
      isShow: isHavePermissionNew(PERMISSIONS.HOME_READ),
    },
    {
      label: 'Dashboard',
      key: ROUTERS.Dashboard.key,
      icon: <DashboardOutlined />,
      onClick: () => handleOnclick(ROUTERS.Dashboard.path),
      isShow: isHavePermissionNew(PERMISSIONS.DASHBOARD_READ),
    },
    {
      label: 'Quản lý data',
      key: 'dataManager',
      isShow: isHavePermissionNew(PERMISSIONS.DATA_MENU),
      icon: <DatabaseOutlined />,
      children: [
        {
          label: 'Import Data',
          key: ROUTERS.DataImport.key,
          onClick: () => handleOnclick(ROUTERS.DataImport.path),
          isShow: isHavePermissionNew(PERMISSIONS.DATA_IMPORT),
        },
        {
          label: 'Thêm mới Data',
          key: ROUTERS.DataCreate.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataCreate.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.DATA_ADD_NEW),
        },
        {
          label: 'Data chưa chia',
          key: ROUTERS.DataManager.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataManager.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.DATA_NEW),
        },
        {
          label: 'Data đã chia',
          key: ROUTERS.DataDivided.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataDivided.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.DATA_DIVIDED),
        },
        {
          label: 'Data thu hồi',
          key: ROUTERS.DataLx3.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataLx3.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.DATA_RECALL),
        },
      ].filter((item) => item.isShow),
    },
    {
      label: 'Quản lý sản phẩm',
      key: ROUTERS?.ProductManager.key,
      icon: <FileDoneOutlined />,
      children: [
        {
          label: 'Danh sách sản phẩm',
          key: ROUTERS.ListProduct.key,
          onClick: () => {
            handleOnclick(ROUTERS.ListProduct.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER),
        },
      ].filter((item) => item.isShow),
      isShow: isHavePermissionNew(PERMISSIONS.PRODUCT_MANAGER_MENU),
    },
    {
      label: 'Tiện ích',
      key: 'ultis',
      icon: <FileSearchOutlined />,
      isShow: isHavePermissionNew(PERMISSIONS.ADD_ON_MENU),
      children: utilityMenu.filter((item) => item.isShow),
    },
    // {
    //   label: 'Báo cáo',
    //   children: utilityMenu.filter((item) => !item.hidden),
    // },
    {
      label: <div>Báo cáo</div>,
      key: 'reports',
      icon: <ContainerOutlined />,
      isShow: isHavePermissionNew(PERMISSIONS.REPORT_MENU),
      children: [
        {
          label: 'Hoa hồng theo ngày',
          key: ROUTERS.RevenueReportsDaily.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsDaily.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_DAY),
        },
        {
          label: 'Hoa hồng theo Sale',
          key: ROUTERS.RevenueReportsSaleman.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsSaleman.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.REPORT_REVENUE_BY_SALE),
        },
        {
          label: 'Hoa hồng theo Số điện thoại',
          key: ROUTERS.RevenueReportsPhone.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsPhone.path);
          },
        },
        // {
        //   label: 'Hoa hồng theo ngày (Cũ)',
        //   key: ROUTERS.RevenueReportsDailyOld.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.RevenueReportsDailyOld.path);
        //   },
        // },
        // {
        //   label: 'Hoa hồng theo Sale (Cũ)',
        //   key: ROUTERS.RevenueReportsSalemanOld.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.RevenueReportsSalemanOld.path);
        //   },
        // },
        // {
        //   label: 'Chăm sóc data theo ngày',
        //   key: ROUTERS.TakeCareByDay.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.TakeCareByDay.path);
        //   },
        // },
        // {
        //   label: 'Hoa hồng theo ngày (Cũ)',
        //   key: ROUTERS.RevenueReportsDailyOld.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.RevenueReportsDailyOld.path);
        //   },
        // },
        // {
        //   label: 'Hoa hồng theo Sale (Cũ)',
        //   key: ROUTERS.RevenueReportsSalemanOld.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.RevenueReportsSalemanOld.path);
        //   },
        // },
        // {
        //   label: 'Chăm sóc data theo ngày',
        //   key: ROUTERS.TakeCareByDay.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.TakeCareByDay.path);
        //   },
        // },
        {
          label: 'Bộ tỉ lệ tổng',
          key: ROUTERS.ReportsRatioTotal.key,
          onClick: () => {
            handleOnclick(ROUTERS.ReportsRatioTotal.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.REPORT_RATIO_SUM),
        },
        {
          label: 'Bộ tỉ lệ luồng',
          key: ROUTERS.ReportsRatioByDay.key,
          onClick: () => {
            handleOnclick(ROUTERS.ReportsRatioByDay.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.REPORT_RATIO_PROCESS),
        },
      ],
    },
    {
      label: 'Administration',
      key: 'adminis',
      icon: <SettingOutlined />,
      isShow: isHavePermissionNew(PERMISSIONS.ADMINISTRATION_MENU),
      children: [
        {
          label: 'Quản lý tài khoản',
          key: ROUTERS.Accounts.key,
          onClick: () => {
            handleOnclick(ROUTERS.Accounts.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.ADMINISTRATION_ACCOUNT),
        },
        {
          label: 'Cấu hình Level Sale',
          key: ROUTERS.ConfigLevelSale.key,
          onClick: () => {
            handleOnclick(ROUTERS.ConfigLevelSale.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.ADMINISTRATION_LEVEL_SALE),
        },
        {
          label: 'Cấu hình phân quyền',
          key: ROUTERS.ConfigPermissions.key,
          onClick: () => {
            handleOnclick(ROUTERS.ConfigPermissions.path);
          },
          isShow: isHavePermissionNew(PERMISSIONS.ADMINISTRATION_PERMISSION),
        },
        {
          label: 'Quản lý tenant',
          key: ROUTERS.Tenant.key,
          isShow: true,
          onClick: () => {
            handleOnclick(ROUTERS.Tenant.path);
          },
        },
      ].filter((item) => item.isShow),
    },
    {
      label: 'Quản lý tổ chức',
      icon: <TeamOutlined className="text-lg" />,
      key: ROUTERS.Collaborators.key,
      isShow: true,
      children: [
        {
          label: 'Danh sách tổ chức',
          key: ROUTERS.Organizations.key,
          isShow: true,
          onClick: () => {
            handleOnclick(ROUTERS.Organizations.path);
          },
        },
      ],
    },
  ].filter((item) => item.isShow);

  const goToProfilePage = () => {
    // navigate(ROUTERS.UserProfile.path);
  };

  const renderMenuProfile = () => {
    return (
      <div className="list-menu-profile">
        <div className="list-menu-profile-item" onClick={goToProfilePage}>
          Thông tin cá nhân
        </div>
        <Divider />
        <div onClick={onLogout} className="list-menu-profile-item">
          Đăng xuất
        </div>
      </div>
    );
  };

  const renderAvatar = (size: number) => {
    return isNullOrEmpty(appState?.profile?.avatar) ? (
      <Avatar size={size} icon={<UserOutlined />} />
    ) : (
      <Avatar src={getLinkAvatar(appState?.profile?.avatar)} size={size} />
    );
  };

  const renderAccountBox = () => (
    <Space style={accountBoxStyle}>
      <Dropdown overlay={renderMenuProfile()} trigger={['click']}>
        {collapsed ? (
          renderAvatar(35)
        ) : (
          <Space style={{ display: 'flex', width: 248 }}>
            {renderAvatar(50)}
            <div
              style={{
                color: 'white',
                justifyContent: 'center',
              }}
            >
              <div>{appState.profile?.fullName}</div>
              {/* <div>{getRoleName(appState.profile?.roles?.[0])}</div> */}
            </div>
          </Space>
        )}
      </Dropdown>
    </Space>
  );

  const splitPathname = window.location.pathname.split('/')[1];

  const selectedKeys = splitPathname;
  let openKeys = '';

  const vers = require('../../../package.json');

  const content = (
    <div style={{ fontWeight: 500, display: 'flex' }}>
      Bạn còn
      <div className="numberOfDataCaring">{appState?.customerCareRemain}</div>
      data cần thực hiện
    </div>
  );

  const getNumberOfData = (role?: string) => {
    switch (role) {
      case ROUTERS.DataDivided.key:
        return appState?.dataDividedRemain;
      case ROUTERS.DataManager.key:
        return appState?.dataNewRemain;
      default:
        return appState?.customerCareRemain;
    }
  };

  return (
    <Layout
      className={'fixed-sidebar layout-common'}
      style={{ minHeight: '100vh' }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={248}
      >
        {collapsed ? (
          <div className="box">
            <img src={Logo} className="aspect-square h-8" />
          </div>
        ) : (
          <div className="box">
            <h1 className="logo">
              <img src={LogoVertical} className="h-6 aspect-[3312/582]" />
            </h1>
            <p className="version">Version: {vers.version}</p>
          </div>
        )}
        <Menu
          onClick={(value) => {
            BreadcrumbsRef.current?.updateKeyPath(value?.keyPath);
          }}
          theme="dark"
          defaultSelectedKeys={[selectedKeys || ROUTERS.Home.key]}
          defaultOpenKeys={[openKeys]}
          mode="inline"
          items={items}
          subMenuCloseDelay={0.01}
          style={{ display: 'flex', flexDirection: 'column', height: '150%' }}
          itemIcon={(item: any) => {
            const isAllowedEvent = [
              ROUTERS.Home.key,
              ROUTERS.DataDivided.key,
              ROUTERS.DataManager.key,
            ].includes(item?.eventKey);
            if (isAllowedEvent) {
              const showContent = [ROUTERS.Home.key].includes(item?.eventKey)
                ? content
                : false;

              if (
                !isHavePermissionNew(PERMISSIONS.DATA_CARING_READ) &&
                [
                  ROUTERS.Home.key,
                  ROUTERS.DataDivided.key,
                  ROUTERS.DataManager.key,
                ].includes(item?.eventKey)
              )
                return null;
              return (
                <Popover
                  placement="bottom"
                  content={
                    isHavePermissionNew(PERMISSIONS.DATA_CARING_READ) &&
                    showContent
                  }
                  showArrow={false}
                  align={{
                    offset: [0, -12],
                  }}
                >
                  <div className="calls">{getNumberOfData(item?.eventKey)}</div>
                </Popover>
              );
            }
            return null;
          }}
        />
        {renderAccountBox()}
      </Sider>
      <Layout className="site-layout fixed-header">
        <Outlet />
      </Layout>
      {calloutState?.showModalCalling && <BubbleCallIn />}

      <NotiMissedCall />
      {/* <ModalWarning
        content="Bạn đang trong cuộc gọi với khách hàng. Nếu rời khỏi trang web, cuộc gọi của bạn sẽ bị kết thúc"
        isModalOpen={isOpenModalWarning}
        handleOk={handleOk}
        handleCancel={handleCancel}
      /> */}
    </Layout>
  );
};

export default MainLayout;

const accountBoxStyle: React.CSSProperties = {
  position: 'sticky',
  left: 0,
  bottom: -16,
  padding: 12,
  paddingBottom: 28,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgb(0,21,41)',
};

import { notification } from 'antd';
import React, { Reducer, useEffect, useReducer } from 'react';
import stringeeServices from 'src/services/stringee/stringee.service';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import LocalStoreInstance from 'src/utils/localStorage';
import { isNullOrEmpty, mapCallErrorCode } from 'src/utils/stringUtils';

export const CALL_OUT_ACTION = {
  initComplete: 'initComplete',
  makeACall: 'makeACall',
  incomingcall: 'incomingcall',
  holding: 'holding',
  calling: 'calling',
  endcall: 'endcall',
  callout: 'callout',
  calloutError: 'calloutError',
  updateMute: 'updateMute',
  hold: 'hold',
  holdCall: 'holdCall',
  unHoldCall: 'unHoldCall',
  callRinging: 'callRinging',
  onAcceptCall: 'onAcceptCall',
  onRejectCall: 'onRejectCall',
  showMissedModal: 'showMissedModal',
};

type Action = any;

export const initialState: any = {
  initSuccess: false,
  isLoading: false,
  callStatus: 'ending',
  callError: undefined,
  holdCall: false,
  mute: false,
  phoneCallRinging: undefined,
  isCallingFromCustomer: false,
  isShowMissedModal: false,
  missedCalls: [],
  showModalCalling: false,
};

export function useStringee(): [state: any, dispatch: React.Dispatch<any>] {
  const setLocalStorageIsCalling = () => {
    LocalStoreInstance.getInstance().save(
      LOCAL_STORAGE_VALUE.callStatus,
      'calling'
    );
  };
  const setLocalStorageIsEnding = () => {
    const callStatus = LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.callStatus
    );
    if (callStatus === 'calling') {
      LocalStoreInstance.getInstance().save(
        LOCAL_STORAGE_VALUE.callStatus,
        'ending'
      );
    }
  };

  const reducer: Reducer<any, Action> = (state: any, action: any) => {
    switch (action.type) {
      case CALL_OUT_ACTION.initComplete: {
        return {
          ...state,
          initSuccess: true,
        };
      }
      case CALL_OUT_ACTION.calling: {
        setLocalStorageIsCalling();
        return {
          ...state,
          callStatus: 'calling',
        };
      }
      case CALL_OUT_ACTION.incomingcall: {
        return {
          ...state,
          showModalCalling: true,
          phoneCallRinging: action?.fromNumber.slice(2),
          callStatus: 'holding',
        };
      }
      case CALL_OUT_ACTION.makeACall: {
        return {
          ...state,
          isLoading: true,
        };
      }
      case CALL_OUT_ACTION.callout: {
        return {
          ...state,
          isLoading: false,
          callStatus: 'holding',
        };
      }
      case CALL_OUT_ACTION.onRejectCall: {
        stringeeServices.rejectCall();
        return {
          ...state,
          phoneCallRinging: undefined,
          showModalCalling: false,
        };
      }
      case CALL_OUT_ACTION.callRinging: {
        return {
          ...state,
          callStatus: 'holding',
          phoneCallRinging: action.phone,
        };
      }
      case CALL_OUT_ACTION.onAcceptCall: {
        stringeeServices.onAcceptCall();
        return {
          ...state,
          callStatus: 'calling',
          showModalCalling: false,
        };
      }
      case CALL_OUT_ACTION.holding: {
        return {
          ...state,
          callStatus: 'holding',
          isCallingFromCustomer: false,
        };
      }
      case CALL_OUT_ACTION.endcall: {
        setLocalStorageIsEnding();
        return {
          ...state,
          phoneCallRinging: undefined,
          isCallingFromCustomer: false,
          showModalCalling: false,
          isLoading: false,
          holdCall: false,
          mute: false,
          callStatus: 'ending',
        };
      }
      case CALL_OUT_ACTION.calloutError: {
        const errMessage = mapCallErrorCode(action.errorCode.toString());
        notification.error({
          message: errMessage,
        });
        return {
          ...state,
          callStatus: 'ending',
          phoneCallRinging: undefined,
          isLoading: false,
          callError: {
            code: action.errorCode,
            errMessage,
          },
        };
      }
      case CALL_OUT_ACTION.updateMute: {
        window?.mute(!action.status);
        return {
          ...state,
          mute: !action.status,
        };
      }
      case CALL_OUT_ACTION.holdCall: {
        window?.hold();
        return {
          ...state,
          holdCall: true,
        };
      }
      case CALL_OUT_ACTION.unHoldCall: {
        window?.unhold();
        return {
          ...state,
          holdCall: false,
        };
      }
      case CALL_OUT_ACTION.showMissedModal: {
        return {
          ...state,
          isShowMissedModal: action.isShowMissedModal,
          missedCalls: action?.missedCalls ?? [],
        };
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const onConnected = () => {
    // connect success
  };

  const initComplete = (data: any) => {
    console.log('initComplete', data);
    if (!isNullOrEmpty(data?.detail?.userId)) {
      dispatch({ type: CALL_OUT_ACTION.initComplete });
    }
  };

  const onDisconnected = () => {
    // disconnected
  };

  const onRequestNewToken = () => {
    //
  };

  const onCallingStatus = (data: any) => {
    console.log('onCallingStatus', data?.detail);
    switch (data?.detail?.code) {
      case 1: {
        // calling
        dispatch({ type: CALL_OUT_ACTION.holding });
        break;
      }
      case 2: {
        // ringing
        dispatch({ type: CALL_OUT_ACTION.callRinging });
        break;
      }
      case 3: {
        // Answered
        dispatch({ type: CALL_OUT_ACTION.calling });
        break;
      }
      case 5: {
        // user busy
        stringeeServices.endCall();
        notification.warn({ message: 'Khách hàng không nghe máy!' });
        dispatch({ type: CALL_OUT_ACTION.endcall });
        break;
      }
      case 6: {
        // ended
        stringeeServices.endCall();
        notification.warn({ message: 'Cuộc gọi đã kết thúc!' });
        dispatch({ type: CALL_OUT_ACTION.endcall });
        break;
      }
      default:
    }
  };

  const onIncomingcall = (data: any) => {
    console.log('onIncomingcall', data);
    dispatch({
      type: CALL_OUT_ACTION.incomingcall,
      fromNumber: data?.detail?.fromNumber,
    });
  };

  const onEndcall = (data: any) => {
    dispatch({
      type: CALL_OUT_ACTION.endcall,
    });
    if (data?.detail?.r === 0) {
      stringeeServices.endCall();
    }
    if (data?.detail?.r === 1) {
      // notification.error({ message: 'Cuộc gọi không tồn tại' });
    }
    if (data?.detail?.r === 2) {
      notification.error({
        message: 'Cuộc gọi đã được trả lời trên một thiết bị khác',
      });
    }
  };

  const onCallbackCall = (data: any) => {
    if (data?.detail?.r === 0) {
      // connect success
    } else {
      setTimeout(() => {
        dispatch({
          type: CALL_OUT_ACTION.calloutError,
          errorCode: data?.detail?.r,
        });
      }, 500);
    }
  };

  useEffect(() => {
    stringeeServices?.init();
    window.addEventListener('connected', onConnected);
    window.addEventListener('authen', initComplete);
    window.addEventListener('disconnected', onDisconnected);
    window.addEventListener('requestnewtoken', onRequestNewToken);
    window.addEventListener('signalingstate', onCallingStatus);
    window.addEventListener('incomingcall', onIncomingcall);
    window.addEventListener('endcall', onEndcall);
    window.addEventListener('callbackCall', onCallbackCall);
  }, []);

  return [state, dispatch];
}

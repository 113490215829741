import React, { useReducer, createContext, useEffect } from 'react';

import { reducer } from './appReducer';
import LocalStore from '../utils/localStorage';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import { APP_CONTEXT_ACTIONS } from './appAction';
import userServices from 'src/services/users.service';
import reportsServices from 'src/services/reports.service';
import { RESPONSE_CODE } from 'src/utils/constants';
import LocalStoreInstance from '../utils/localStorage';
import { notification } from 'antd';
import { useStringee } from 'src/hooks/useStringee';
import stringeeServices from 'src/services/stringee/stringee.service';

export type Action = {
  type: APP_CONTEXT_ACTIONS;
  payload: any;
};

export const initialAppState: AppStorage = {
  profile: undefined,
  loadingState: true,
  phonePackages: [],
  dataDividedRemain: 0,
  customerCareRemain: 0,
  dataNewRemain: 0,
  currentCollapse: 0,
  listStaff: [],
};

// Create a new context
export const AppContext = createContext<ContextType>({
  appState: initialAppState,
  dispatch: () => null,
  calloutState: {} as any,
  calloutDispatch: () => null,
});

// Create a provider component that wraps your app
export const AppProvider = ({ children }: any) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const [calloutState, calloutDispatch] = useStringee();

  const getProfile = async () => {
    const loginSession = LocalStore.getInstance().read(
      LOCAL_STORAGE_VALUE.loginSession
    );

    if (loginSession) {
      const res = await userServices.getProfile();
      if (res?.status === 200) {
        dispatch({
          type: APP_CONTEXT_ACTIONS.UPDATE_PROFILE,
          payload: res?.data,
        });
        LocalStoreInstance.getInstance().save(
          LOCAL_STORAGE_VALUE.currentLoggedUser,
          res?.data
        );
        stringeeServices.connect(res?.data?.stringeeAccessToken);
      }
      // else {
      //   notification.error({
      //     message: res?.data,
      //   });
      // }
      getListStaff();
    }
  };

  const getListStaff = async () => {
    const resp = await reportsServices.getListStaff();
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      dispatch({
        type: APP_CONTEXT_ACTIONS.SET_LIST_STAFF,
        payload: resp?.data,
      });
    }
    // else {
    //   notification.error({
    //     message: resp?.data,
    //   });
    // }
  };

  const loadState = () => {
    const serializedState = LocalStore.getInstance().read(
      LOCAL_STORAGE_VALUE.appState
    );
    dispatch({
      type: APP_CONTEXT_ACTIONS.LOAD_STATE,
      payload: serializedState ?? initialAppState,
    });
  };

  // Load state from Local when the component mounts
  useEffect(() => {
    loadState();
    getProfile();
  }, []);

  // Save state to Local whenever it changes
  useEffect(() => {
    LocalStore.getInstance().save(
      LOCAL_STORAGE_VALUE.appState,
      JSON.stringify(appState)
    );
  }, [appState]);

  return (
    <AppContext.Provider
      value={{
        appState,
        dispatch,
        calloutState,
        calloutDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Export the context
export default AppContext;
